import {buttonProgress} from "./lib/buttonProgress";
import {createHtmlElement, toggleHidden} from "./lib/domFunctions";
import {fetchJson} from "./lib/fetch";
import {AppConfig} from "./AppConfig";
import {containerTypeChar} from "./lib/containerTypeChar";
import {Messages} from "./messages/Messages";
import {escapeHTML} from "./lib/escapeHTML";

const messages = new Messages();

interface ContainerQueueTableParams {
    totalElements: number,
    hasMore: boolean,
    sliceUrl: string,
    pageSize: number
    pageNumber: number
    showTargetPosition: boolean
    showCreateDate: boolean
    showFinishDate: boolean
}

export class ContainerQueueTable {
    private readonly loadMore: HTMLButtonElement;
    private readonly sliceUrl: string
    private readonly pageSize: number;
    private pageNumber: number;
    private hasMore: boolean;
    private showTargetPosition: boolean;
    private showCreateDate: boolean;
    private showFinishDate: boolean;

    constructor(params: ContainerQueueTableParams) {
        this.sliceUrl = params.sliceUrl
        this.pageSize = params.pageSize
        this.pageNumber = params.pageNumber
        this.hasMore = params.hasMore
        this.showTargetPosition = params.showTargetPosition
        this.showCreateDate = params.showCreateDate
        this.showFinishDate = params.showFinishDate

        this.loadMore = document.getElementById('loadMore') as HTMLButtonElement;
        this.loadMore.addEventListener('click', () => this.loadMoreRows())
        this.updateHasMore();
    }

    private updateHasMore() {
        toggleHidden(this.loadMore, !this.hasMore);
    }

    private loadMoreRows() {
        const progress = buttonProgress(this.loadMore);
        fetchJson(`${AppConfig.CP}${this.sliceUrl}?pageSize=${this.pageSize}&pageNumber=${this.pageNumber + 1}`)
            .then(json => this.appendSlice(json))
            .then(() => progress.stop())
    }

    private appendSlice(page: { content: any[], size: number, number: number, totalPages: number }) {
        this.pageNumber = page.number
        this.hasMore = page.number < page.totalPages - 1
        this.updateHasMore()

        if (page.size == 0) return

        const h = escapeHTML;

        const rowsHtml = page.content
            .map(row => {
                return `
<tr class="align-baseline" data-id="${row.id}">
    <td class="text-nowrap text-end">
        <span>${row.id}</span>
        <span>${containerTypeChar(row.type)}</span>
    </td>
    <td>
            <span class="badge text-bg-warning text-lowercase fw-normal ${row.state ? '' : ' hidden '}">${messages.get('container.state.' + row.state)}</span>
    </td>
    <td class="text-center">${row.position ? row.position : '-'}</td>
    <td class="text-center ${this.showTargetPosition ? '' : ' hidden '}">${row.targetPosition ? row.targetPosition : '-'}</td>
    <td>${h(row.coating)}</td>
    <td>
        <span class="title-highlight">${h(row.processCode)}</span>
        <span>${h(row.processName)}</span>
    </td>
    <td class="pre-line">${h(row.details)}</td>
    <td class="text-center ${this.showCreateDate ? '' : ' hidden '}">${row.createDate}</td>
    <td class="text-center ${this.showFinishDate ? '' : ' hidden '}">${row.finishDate}</td>
    <td class="text-nowrap w-0 d-print-none" data-bind="container-actions">
        <a class="btn btn-sm btn-secondary" href="${AppConfig.CP}/queue/${row.id}">${messages.get('button.view')}</a>
    </td>
</tr>`
            })
            .join("")

        const table = document.getElementById('table')!
        const lastTable = table.querySelectorAll('tbody');

        lastTable[lastTable.length - 1].after(createHtmlElement('tbody', {}, `<tbody>${rowsHtml}</tbody>`))
    }
}