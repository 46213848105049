import {bootstrapModal} from "../lib/bootstrapModal";
import {Messages} from "../messages/Messages";
import {apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";
import {buttonProgress} from "../lib/buttonProgress";
import {moment} from "../boot";

const messages = new Messages();

export class SendContainerToWork {
	private readonly containerId: number;

	constructor(containerId: number) {
		this.containerId = containerId;
	}

	execute(): Promise<void> {
		return new Promise((resolve, reject) => {
			apiPostForAjaxResponse(`${AppConfig.CP}/op/sendToWork`, {container: this.containerId})
				.then(res => {
					if (!res) return Promise.reject(500)

					if (res.generator) {
						const {pathVariations, checkedVariations} = res.generator;
						const checkedPercent = pathVariations > 0 ? Math.round(checkedVariations * 100 / pathVariations) : 0;
						showToastInfo(messages.get('SendContainerToWork.completeNoticeWithGenerator', checkedVariations, pathVariations, checkedPercent))
					} else {
						showToastInfo(messages.get('SendContainerToWork.completeNotice'))
					}

					resolve()
				})
				.catch(toastFetchError)
				.then(() => reject());
		})
	}

	showModal(completer?: Function) {
		const {modal, content, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
			title: messages.get('SendContainerToWork.confirmNotice', this.containerId),
			body: '<div class="hidden"></div>',
			footer: `
				<button type="button" class="btn btn-secondary" data-handler="calculate">${messages.get('SendContainerToWork.calculateButton')}</button>
				<div class="flex-fill"></div>
				<button type="button" class="btn btn-primary text-capitalize" data-handler="submit">${messages.get('button.send')}</button>
				<button type="button" class="btn btn-secondary text-capitalize" data-bs-dismiss="modal">${messages.get('button.cancel')}</button>
			`
		});
		modal.show();

		const modalBody = content.querySelector<HTMLDivElement>('div.modal-body')!!
		modalBody.classList.add('hidden');

		const ok = buttonOk!!;
		const calculateButton = content.querySelector<HTMLButtonElement>('button[data-handler="calculate"]')!!

		calculateButton.addEventListener('click', () => {
			const progress = buttonProgress(calculateButton);

			this.calculateWorkTime(content)
				.catch(toastFetchError)
				.finally(() => progress.stop());
		})

		ok.addEventListener('click', () => {
			buttonOkClickStart();

			this.execute()
				.then(() => {
					modal.hide()
					if (completer) completer()
				})
				.catch(buttonOkClickFinish)
		})
	}

	private calculateWorkTime(content: HTMLElement): Promise<void> {
		const modalBody = content.querySelector<HTMLDivElement>('div.modal-body')!!

		function formatDateTime(millis: number) {
			return moment(millis).format('DD.MM.YYYY HH:mm');
		}

		return apiPostForAjaxResponse(`${AppConfig.CP}/op/measureWork`, {container: this.containerId})
			.then(status => {
				const lastAddedContainer = status.lastAddedContainer;
				const lastAddedContainerFinish = status.lastAddedContainerFinish;
				const lastFinishedContainer = status.lastFinishedContainer;
				const lastCommandDate = status.lastCommandDate;

				if (lastFinishedContainer && lastFinishedContainer !== lastAddedContainer) {
					modalBody.innerHTML = `
<table class="mx-auto">
<tr>
<td class="px-3 text-nowrap text-start">Этот контейнер закончит работу:</td>
<td class="px-3 text-nowrap text-start"><span class="d-inline-block text-success fw-bolder">${formatDateTime(lastAddedContainerFinish)}</span></td>
</tr>
<tr>
<td class="px-3 text-nowrap text-start">Последний контейнер (${lastFinishedContainer}) закончит работу:</td>
<td class="px-3 text-nowrap text-start"><span class="d-inline-block text-success fw-bolder">${formatDateTime(lastCommandDate)}</span></td>
</tr>
</table>
					`;
				} else {
					modalBody.innerHTML = `
<table class="mx-auto">
<tr>
<td class="px-3 text-nowrap text-start">Этот контейнер закончит работу:</td>
<td class="px-3 text-nowrap text-start"><span class="d-inline-block text-success fw-bolder">${formatDateTime(lastAddedContainerFinish)}</span></td>
</tr>
</table>
					`;
				}

				modalBody.classList.remove('hidden');
				content.classList.add('modal-lg')
			});
	}
}