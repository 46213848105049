export enum UserRoles {
	OPERATOR = "operator",
	SERVICE = "service",
	ADMIN = "admin",
	TECH = "tech"
}

export enum UserAuthorities {
	POSITION_PLC_LOCK = "position:plcLock",
	CONTAINER_UNLOAD_ON_TROLLEY = "container:unload_on_trolley"
}
