import {Toast} from 'bootstrap';
import {escapeHTML} from "./escapeHTML";
import {Messages} from "../messages/Messages";
import {createHtmlElement} from "./domFunctions";

const messages = new Messages();

const AUTO_HIDE = {
    autohide: true,
    delay: 5000
}

function returnArgument<T>(a: T): T {
    return a;
}

function showToast(title: string, body: string, icon: string, toastClass: string, html: boolean) {
    const h = html ? returnArgument : escapeHTML;
    const el = createHtmlElement('div', {'class': `toast ${toastClass}`, 'role': 'alert', 'aria-live': 'assertive', 'aria-atomic': 'true'}, `
<div class="toast-header align-items-baseline">
    <i class="me-2 fa-light fa-${icon}"></i>
    <strong class="me-auto">${h(title)}</strong>
    <small></small>
    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
</div>
<div class="toast-body">${h(body)}</div>
    `)
    document.getElementById('toastContainer')!!.append(el);
    new Toast(el, Object.assign({}, AUTO_HIDE, {})).show()
}

export interface ToastOptions {
    header?: string
    html?: boolean
}

export function showToastError(body: string, options: ToastOptions = {}) {
    const header = options.header !== undefined ? options.header : messages.get('toast.error')
    showToast(header, body, 'circle-exclamation', 'text-bg-danger', !!options.html)
}

export function showToastInfo(body: string) {
    showToast(messages.get('toast.info'), body, 'info-circle', '', false)
}

export function showToastSuccess(body: string) {
    showToast(messages.get('toast.success'), body, 'star', 'text-bg-success', false)
}