import {line, plot} from "@observablehq/plot";
import {Messages} from "./messages/Messages";
import {createHtmlElement, toggleHidden} from "./lib/domFunctions";
import {ContainerType} from "./dto/com.rico.sb2.entity.detail";
import {hasDuplicateInSortedArray, sortNumberAsc} from "./lib/langExtensions";
import {bootstrapModal} from "./lib/bootstrapModal";

const messages = new Messages();

interface PositionTypeEditPageParams {
    verifyCurrentDensityTable?: boolean
}

interface CurrentDensityTable {
    table: HTMLElement
    seed: number
    field: string
}

export class PositionTypeEditPage {
    private readonly maxCurrentInput: HTMLInputElement;

    private readonly currentDensityByType = new Map<ContainerType, CurrentDensityTable>();

    constructor({verifyCurrentDensityTable}: PositionTypeEditPageParams) {
        const registerCurrentDensityTable = (type: ContainerType, table: HTMLElement, field: string) => {
            this.currentDensityByType.set(type, {table, field, seed: table.querySelectorAll('tbody> tr').length})
            table.addEventListener('change', () => this.updateCurrentDensityChart());
            table.addEventListener('input', () => this.updateCurrentDensityChart());
        }

        registerCurrentDensityTable(ContainerType.SUSPENSION, document.getElementById('suspensionCurrentDensity')!!, 'suspensionCurrentDensity');
        registerCurrentDensityTable(ContainerType.CYLINDER, document.getElementById('cylinderCurrentDensity')!!, 'cylinderCurrentDensity');
        this.updateCurrentDensityChart();

        this.maxCurrentInput = document.querySelector('input[name="maxCurrent"]')!
        this.maxCurrentInput.addEventListener('change', () => this.onMaxCurrentChange());
        this.maxCurrentInput.addEventListener('input', () => this.onMaxCurrentChange());
        this.onMaxCurrentChange();

        if (verifyCurrentDensityTable) {
            document.getElementById('form')?.addEventListener('submit', ev => {
                if (!this.verifyCurrentDensityTable()) {
                    ev.preventDefault();
                    return false;
                }
            })
        }
    }

    private get currentDensityChartsVisible() {
        return Number.isFinite(parseFloat(this.maxCurrentInput.value));
    }

    addSuspensionCurrentDensityRow() {
        this.addCurrentDensityRow(this.currentDensityByType.get(ContainerType.SUSPENSION)!);
    }

    addCylinderCurrentDensityRow() {
        this.addCurrentDensityRow(this.currentDensityByType.get(ContainerType.CYLINDER)!);
    }

    private addCurrentDensityRow(tableInfo: CurrentDensityTable) {
        const listIndex = tableInfo.seed++;
        const field = tableInfo.field;
        const body = tableInfo.table.getElementsByTagName('tbody')[0];

        const tr = createHtmlElement('tr', {}, `
            <td><input lang="en" type="number" step="any" class="form-control form-control-sm" required name="${field}[${listIndex}].value" ></td>
            <td><input lang="en" type="number" step="any" class="form-control form-control-sm" required name="${field}[${listIndex}].speed"></td>
            <td>
                <button class="btn btn-secondary btn-sm" type="button" onclick="controller.removeCurrentDensityRow(this)">
                    <i class="fal fa-minus me-2"></i>
                    ${messages.get('button.delete')}
                </button>
            </td>
        `)
        body.appendChild(tr)
    }

    removeCurrentDensityRow(button: HTMLButtonElement) {
        button.closest('tr')?.remove();
        this.updateCurrentDensityChart();
    }

    private updateCurrentDensityChart() {
        this.currentDensityByType.forEach(tableInfo => {
            this.updateCurrentDensityChartForType(tableInfo.table.querySelector('tbody')!, document.getElementById(`${tableInfo.field}Chart`) as HTMLElement)
        })
    }

    private updateCurrentDensityChartForType(tbody: HTMLTableSectionElement, placeholder: HTMLElement) {
        const data = [{value: 0, speed: 0}];

        for (const tr of Array.from(tbody.querySelectorAll('tr'))) {
            const inputs = tr.querySelectorAll<HTMLInputElement>('input[type=number]');
            const value = Number.parseFloat(inputs[0].value);
            const speed = Number.parseFloat(inputs[1].value);
            if (Number.isFinite(value) && Number.isFinite(speed)) {
                data.push({value, speed});
            }
        }
        data.sort((a, b) => a.value - b.value);

        const chart = plot({
            width: placeholder.clientWidth,
            height: 150,
            x: {label: messages.get('type.form.currentDensity.current')},
            y: {
                label: messages.get('type.form.currentDensity.layerGrowthSpeed'),
                grid: true
            },
            grid: true,
            marks: [line(data, {x: "value", y: "speed"})]
        });

        placeholder.replaceChildren(chart);
    }

    private onMaxCurrentChange() {
        const chartVisible = this.currentDensityChartsVisible
        this.currentDensityByType.forEach(tableInfo => {
            toggleHidden(document.querySelector(`[data-bind="${tableInfo.field}View"]`), !chartVisible)
        })
    }

    private verifyCurrentDensityTable() {
        if (!this.currentDensityChartsVisible) {
            return true;
        }

        let hasDuplicateInCurrentDensityValues = false

        Array.from(this.currentDensityByType.values()).map(tableInfo => {
            const currentDensityValues = Array.from(tableInfo.table.querySelectorAll<HTMLInputElement>('input[name$="].value"]'))
                .map(input => parseFloat(input.value))
                .filter(n => Number.isFinite(n))
                .sort(sortNumberAsc);
            hasDuplicateInCurrentDensityValues ||= hasDuplicateInSortedArray(currentDensityValues);
        })

        if (hasDuplicateInCurrentDensityValues) {
            const {modal} = bootstrapModal({
                contentClass: 'modal-content-alert modal-content-alert-danger',
                title: messages.get('type.form.correctForm'),
                body: messages.get('type.error.hasDuplicatesInCurrentDensityValues'),
                buttonOk: null,
                buttonCancel: messages.get('button.close')
            })
            modal.show();
            return false;
        }

        return true;
    }
}