import {jQuery} from "./boot";
import {tableRowRemoteSwitch} from "./lib/RemoteSwitch";
import {AppConfig} from "./AppConfig";

export class TransportListPage {
    private readonly canSwitch: boolean

    constructor({canSwitch}: { canSwitch?: boolean }) {
        this.canSwitch = canSwitch != undefined && canSwitch

        if (this.canSwitch) {
            tableRowRemoteSwitch(jQuery('div[data-position-switch]'), id => `${AppConfig.CP}/transports/${id}/switchEnabled`);
        }
    }
}