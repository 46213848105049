import {basicSetup, EditorView} from "codemirror"
import {ViewUpdate} from '@codemirror/view';
import {javascript} from "@codemirror/lang-javascript"
import {json} from "@codemirror/lang-json"
import {xml} from "@codemirror/lang-xml"
import {createHtmlElement} from "./lib/domFunctions";

function copyEditorToTextArea(view: EditorView, textarea: HTMLTextAreaElement) {
    textarea.value = view.state.doc.toString();
}

function toggleTextArea(textarea: HTMLTextAreaElement, visible: boolean) {
    textarea.style.display = visible ? '' : "none";
}

function editorFromTextArea(textarea: HTMLTextAreaElement, extensions: any[]) {
    let view = new EditorView({doc: textarea.value, extensions})
    textarea.parentNode!!.insertBefore(view.dom, textarea)
    toggleTextArea(textarea, false);
    if (textarea.form) {
        textarea.form.addEventListener("submit", () => copyEditorToTextArea(view, textarea));
    }
    return view
}

export class ContentBlockEditPage {
    private view: EditorView | null = null;
    private viewType: string = '';

    private previewMark: number = 0;
    private svgPreview: HTMLElement | null = null;

    constructor() {
        this.bodyTypeSelect.addEventListener('change', () => this.configureEditor());
        this.configureEditor();
    }

    private get bodyTextArea() {
        return document.querySelector<HTMLTextAreaElement>('textarea[name=body]')!!;
    }

    private get bodyTypeSelect() {
        return document.querySelector<HTMLSelectElement>('select[name=type]')!!;
    }

    configureEditor() {
        const type = this.bodyTypeSelect.value;
        if (this.viewType === type) {
            return;
        }

        if (this.view) {
            copyEditorToTextArea(this.view, this.bodyTextArea);
            this.view.destroy();
            this.view = null;
        }

        if (this.svgPreview) {
            this.svgPreview.remove();
            this.svgPreview = null;
        }

        switch (type) {
            case 'javascript': {
                this.view = editorFromTextArea(this.bodyTextArea, [
                    basicSetup,
                    javascript()
                ]);
                break;
            }
            case 'json': {
                this.view = editorFromTextArea(this.bodyTextArea, [
                    basicSetup,
                    json()
                ]);
                break;
            }
            case 'svg': {
                this.view = editorFromTextArea(this.bodyTextArea, [
                    basicSetup,
                    xml(),
                    EditorView.updateListener.of((update: ViewUpdate) => {
                        if (update.docChanged) {
                            this.previewSvg()
                        }
                    })
                ]);

                this.svgPreview = ContentBlockEditPage.createSvgPlaceholder(this.view.dom);
                this.previewSvg();
                break;
            }
            default: {
                toggleTextArea(this.bodyTextArea, true);
            }
        }
        this.viewType = type;
    }

    previewSvg() {
        const mark = ++this.previewMark
        setTimeout(() => {
            const view = this.view;
            const preview = this.svgPreview;
            if (mark == this.previewMark && view && preview) {
                ContentBlockEditPage.updateSvgPlaceholder(preview, view.state.doc.toString());
            }
        }, 400);
    }

    private static createSvgPlaceholder(insertBefore: HTMLElement) {
        const svgPreview = createHtmlElement('div', {style: 'height: 150px', class: 'hidden text-center cb-svg-preview mb-3'});
        insertBefore.before(svgPreview);
        return svgPreview;
    }

    private static updateSvgPlaceholder(preview: HTMLElement, svgText: string) {
        preview.innerHTML = svgText;
        preview.classList.remove('hidden');
    }

    static previewSvg(insertBefore: HTMLElement, code?: string) {
        if (!code) return;

        ContentBlockEditPage.updateSvgPlaceholder(
            ContentBlockEditPage.createSvgPlaceholder(insertBefore),
            code)
    }
}