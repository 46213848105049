import {jQuery} from "../boot";
import {apiPost} from "./SecuredAjax";
import {Messages} from "../messages/Messages";

const messages = new Messages();

type IdToUrl = (id: any) => string

export function tableRowRemoteSwitch(elementList: JQuery, urlById: IdToUrl) {
    function switchEnabled(id: string, on: boolean, container: JQuery, checkbox: JQuery) {
        container.addClass('placeholder-glow')
        checkbox.addClass('placeholder')
        checkbox.prop('disabled', true);

        apiPost(urlById(id), on)
            .then(res => {
                on = res.value;

                container.removeClass('placeholder-glow')
                checkbox.removeClass('placeholder')
                checkbox.prop('disabled', false);
                checkbox.prop('checked', res.value);
                container.find('.form-check-label').text(messages.get(on ? 'switch.on' : 'switch.off'))
            })
    }

    elementList.on('change', function () {
        const container = jQuery(this)
        const checkbox = container.find('input[type=checkbox]')
        const id = checkbox.closest('tr').data('id');
        const on = checkbox.is(':checked')
        switchEnabled(id, on, container, checkbox);
    });
}