export interface ICUPluralRule {
    pluralRule(n: number): string
}

/**
 * https://www.unicode.org/cldr/charts/34/supplemental/language_plural_rules.html
 */
export default {
    defaults: {
        pluralRule: function (n: number) {
            if (n === 1) {
                return 'one';
            }
            return 'many';
        }
    },

    'ru': {
        pluralRule: function (n: number) {
            const rem10 = n % 10;
            const rem100 = n % 100;
            if (rem10 === 1 && rem100 !== 11) {
                return 'one';
            }
            if ((rem10 === 2 || rem10 === 3 || rem10 === 4) && rem100 !== 12 && rem100 !== 13 && rem100 !== 14) {
                return 'few';
            }
            return 'many';
        }
    }
} as { [key: string]: ICUPluralRule };
