import {SchemaState} from "../SchemaState";
import {UpdateContainerMessage, UpdateSchemeMessage, visitUpdateSchemeMessage} from "../dto/com.rico.sb2.message";
import {SinglePopover} from "../lib/bootstrapPopover";
import {AliveListItem} from "./AliveListItem";
import {isAliveListWaiting, isAliveListWorking} from "./AliveListConstants";
import {PlayerDataAdapter, PlayerDataListener, UpdateSchemeMessageConsumer} from "../PlayerDataAdapter";
import {PlayerData_ContainerInfo} from "../dto/com.rico.sb2.service.positions";
import {createHtmlElement} from "../lib/domFunctions";
import {Messages} from "../messages/Messages";

const messages = new Messages();

export class AliveList implements PlayerDataListener, UpdateSchemeMessageConsumer {
    readonly element: HTMLElement
    private readonly listWaitingElement: HTMLElement
    private readonly listWaitingEmpty: HTMLElement
    private readonly listWorkingElement: HTMLElement
    private readonly listWorkingEmpty: HTMLElement

    readonly schemaState: SchemaState;
    readonly playerData: PlayerDataAdapter;

    private readonly items: Map<number, AliveListItem> = new Map()

    constructor(schemaState: SchemaState, playerData: PlayerDataAdapter) {
        this.schemaState = schemaState
        this.playerData = playerData

        this.element = document.getElementById('listAlive')!
        this.listWaitingElement = this.element.querySelector('[data-bind="listAliveWaiting"]')!
        this.listWaitingEmpty = createHtmlElement('div', {class: 'text-muted fst-italic text-center p-3'}, `${messages.get('AliveList.emptyListMessage')}`)
        this.listWorkingElement = this.element.querySelector('[data-bind="listAliveWorking"]')!
        this.listWorkingEmpty = createHtmlElement('div', {class: 'text-muted fst-italic text-center p-3'}, `${messages.get('AliveList.emptyListMessage')}`)
        this.updateListEmptyNotice();
    }

    clear() {
        this.playerData.playerDataListeners.remove(this)
        this.playerData.updateListeners.remove(this)

        Array.from(this.items.keys()).forEach(id => this.removeContainer(id));

        this.listWaitingElement.innerHTML = ``;
        this.listWorkingElement.innerHTML = ``;
        this.updateListEmptyNotice();
    }

    updateListEmptyNotice() {
        const items = Array.from(this.items.values())

        if (items.some(item => isAliveListWaiting(item.data))) {
            this.listWaitingEmpty.remove();
        } else {
            this.listWaitingElement.append(this.listWaitingEmpty)
        }
        if (items.some(item => isAliveListWorking(item.data))) {
            this.listWorkingEmpty.remove();
        } else {
            this.listWorkingElement.append(this.listWorkingEmpty)
        }
    }

    reset() {
        this.clear();

        this.playerData.containers.map(c => this.addContainer(c))
        this.updateListEmptyNotice();

        this.playerData.playerDataListeners.add(this)
        this.playerData.updateListeners.add(this)
    }

    onContainerAdded(m: PlayerData_ContainerInfo): void {
        this.addContainer(m.id)
        this.updateListEmptyNotice();
    }

    onContainerRemoved(m: PlayerData_ContainerInfo): void {
        this.removeContainer(m.id);
        this.updateListEmptyNotice();
    }

    onContainerModify(m: PlayerData_ContainerInfo): void {
        this.updateContainer(m.id)
    }

    onSchemeUpdate(update: UpdateSchemeMessage): void {
        visitUpdateSchemeMessage(update, {
            containerMessage: m => this.onSchemeContainerUpdate(m)
        })
    }

    private onSchemeContainerUpdate(update: UpdateContainerMessage): void {
        this.updateContainer(update.id)
    }

    private updateContainer(id: number) {
        const item = this.items.get(id);
        if (!item) return

        item.update();
        this.updateItemList(item);
        this.updateListEmptyNotice();
    }

    private getListForWidget(item: AliveListItem): HTMLElement | null {
        if (isAliveListWaiting(item.data)) return this.listWaitingElement
        if (isAliveListWorking(item.data)) return this.listWorkingElement
        return null
    }

    private addContainer(id: number) {
        const widget = new AliveListItem(this, this.playerData.getContainerData(id));

        this.items.set(id, widget)
        this.updateItemList(widget)
        return widget
    }

    private removeContainer(id: number) {
        const widget = this.items.get(id);
        if (!widget) return;

        this.items.delete(id)
        SinglePopover.hideForElement(widget.element)
        widget.dispose()
    }

    private updateItemList(item: AliveListItem) {
        const newList = this.getListForWidget(item)
        if (newList == item.list) return

        item.element.remove()
        if (newList != null) {
            newList.appendChild(item.element)
        }
        item.list = newList
    }

    getLastTransportUpdate(transport: number | null) {
        return this.playerData.getTransportUpdate(transport)
    }

    getLastPositionUpdate(position: number | null) {
        return this.playerData.getPositionUpdate(position)
    }
}
