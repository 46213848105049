import {ContainerState} from "../dto/com.rico.sb2.entity.detail";
import {ProcessControlService_Mode} from "../dto/com.rico.sb2.service";
import {PlayerDataAdapter} from "../PlayerDataAdapter";
import {AppConfig} from "../AppConfig";
import {UserAuthorities} from "../dto/com.rico.sb2.service.users";

function stateIsOneOf(state: ContainerState | null, allowedStates: Set<ContainerState>): boolean {
	if (state == null) return false

	return allowedStates.has(state)
}

export class AuthorityProvider {
	private readonly authoritiesCache = new Map<string, boolean>();

	hasAuthority(authority: string): boolean {
		let available = this.authoritiesCache.get(authority);
		if (typeof available !== 'boolean') {
			available = AppConfig.authorities.some(e => e == authority);
			this.authoritiesCache.set(authority, available);
		}
		return available;
	}
}

export interface ContainerActionEnvironment {
	get serviceMode(): ProcessControlService_Mode | null;

	get trolleyLine(): boolean;

	hasAuthority(authority: string): boolean;

	isUnloadingPosition(position: number | null): boolean;

	isLoadingPosition(position: number | null): boolean;
}

export class ContainerActionEnvironmentFromPlayerData implements ContainerActionEnvironment {
	private readonly playerData: PlayerDataAdapter;
	private readonly authorityProvider = new AuthorityProvider();
	readonly serviceMode: ProcessControlService_Mode | null;

	constructor(playerData: PlayerDataAdapter, serviceMode: ProcessControlService_Mode | null) {
		this.playerData = playerData;
		this.serviceMode = serviceMode;
	}

	hasAuthority(authority: string): boolean {
		return this.authorityProvider.hasAuthority(authority);
	}

	get trolleyLine(): boolean {
		return AppConfig.trolleyLine;
	}

	isUnloadingPosition(position: number | null): boolean {
		return position != null && this.playerData.isUnloadingPosition(position);
	}

	isLoadingPosition(position: number | null): boolean {
		return position != null && this.playerData.isLoadingPosition(position);
	}
}

/**
 * NB: есть такой же java класс (файл ContainerActionRules.java). Меняете здесь - меняйте там
 */
export class ContainerActionRules {
	private readonly env: ContainerActionEnvironment
	private readonly state: ContainerState | null
	private readonly position: number | null

	constructor(env: ContainerActionEnvironment, state: ContainerState | null, position: number | null) {
		this.env = env
		this.state = state
		this.position = position
	}

	get editable() {
		return this.state == null || stateIsOneOf(this.state, new Set([ContainerState.PLANNED, ContainerState.LOADING]));
	}

	/**
	 * Доступна ли операция "отменить подвеску (переход в EMPTY)"
	 */
	get cancelable() {
		return stateIsOneOf(this.state, new Set([ContainerState.PLANNED, ContainerState.LOADING]));
	}

	/**
	 * Доступна ли операция "передвинуть на свободную позицию (для подвески с позицией)"
	 */
	get canMove() {
		const hasPosition = this.position != null;
		if (this.env.serviceMode == ProcessControlService_Mode.SEMIAUTOMATIC) {
			return hasPosition;
		}
		return (this.env.serviceMode == ProcessControlService_Mode.AUTOMATIC)
			&& hasPosition
			&& stateIsOneOf(this.state, new Set([ContainerState.EMPTY, ContainerState.FINISHED, ContainerState.BLOCKED]));
	}

	/**
	 * Доступна ли операция "отправить на загрузку (в какую-то позицию)"
	 */
	get canToLoad() {
		return this.state == ContainerState.PLANNED;
	}

	/**
	 * Доступна ли операция "отправить в работу"
	 */
	get canToWork() {
		return this.state == ContainerState.LOADING;
	}

	/**
	 * Доступна ли операция "отправить в зону выгрузки"
	 */
	get canToUnload() {
		return this.state == ContainerState.WAITING;
	}

	/**
	 * Доступна ли операция "закончить" для контейнера.
	 * И эта операция доступна на линии выкатных тележек только для админа.
	 */
	get canFinish() {
		const ignoreFinishFor = new Set([ContainerState.EMPTY, ContainerState.LOADING])
		return this.state != null && !ignoreFinishFor.has(this.state) && this.env.isUnloadingPosition(this.position)
			&& (!this.env.trolleyLine || this.env.hasAuthority(UserAuthorities.CONTAINER_UNLOAD_ON_TROLLEY));
	}

	/**
	 * Доступна ли операция "расформировать" для контейнера
	 */
	get canDisband() {
		const ignoreDisbandFor = new Set([ContainerState.EMPTY, ContainerState.LOADING])
		return !this.canFinish
			&& this.state != null && !ignoreDisbandFor.has(this.state) && this.env.isLoadingPosition(this.position)
			&& this.env.serviceMode != ProcessControlService_Mode.AUTOMATIC;
	}

	/**
	 * В сервисном или заблокированном режиме можно удалить с линии вообще любую подвеску, она получает статус FINISHED, позицию null, ее программа получает статус TERMINATED.
	 */
	get canToTerminate() {
		return this.isModeServiceOrBlocked()
	}

	private isModeServiceOrBlocked(): boolean {
		return this.env.serviceMode == ProcessControlService_Mode.STOPPED
	}
}