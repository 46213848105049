import {jQuery} from './boot'
import {AppConfig} from './AppConfig';
import {tableRowRemoteSwitch} from "./lib/RemoteSwitch";

interface PositionListPageParams {
    canSwitch: boolean
}

export class PositionListPage {
    constructor({canSwitch}: PositionListPageParams) {
        if (canSwitch) {
            tableRowRemoteSwitch(jQuery('div[data-position-switch]'), id => `${AppConfig.CP}/positions/${id}/switchEnabled`);
        }
    }
}