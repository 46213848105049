import {PeriodicTask} from "../lib/PeriodicTask";
import {BindingList, createHtmlElement, setInnerTextAndTitle} from "../lib/domFunctions";
import {SinglePopover} from "../lib/bootstrapPopover";
import {ContainerStateValues} from "../dto/com.rico.sb2.entity.detail";
import {durationTextAround, msToDurationAround} from "../lib/timeFunctions";
import {AliveList} from "./AliveList";
import {Messages} from "../messages/Messages";
import {ContainerDataAdapter} from "./ContainerDataSupplier";
import {ContainerClickMenu} from "./ContainerClickMenu";
import {isAliveListWaiting} from "./AliveListConstants";
import {Program_State} from "../dto/com.rico.sb2.entity.program";

const messages = new Messages();

const TEMPLATE = `
<span class="d-block text-end" style="width: 1.5em;" data-bind="number"></span>
<img src="" alt="" style="height: 2em; width: 2em;" class="m-2" data-bind="icon">
<div class="text-nowrap" style="width: 8em;">
    <span class="d-block overflow-hidden overflow-ellipsis" data-bind="process"></span>
    <span class="d-block overflow-hidden overflow-ellipsis" data-bind="coating"></span>
</div>
<div class="vr my-1 text-bg-dark"></div>
<div class="flex-fill text-end pe-2">
    <div class="text-nowrap" data-bind="startInfo">${messages.get('ContainerListItem.startFrom')} <span data-bind="startPosition"></span></div>
    <div class="text-nowrap" data-bind="durationInfo"><span data-bind="duration"></span></div>
    <div class="text-nowrap" data-bind="finishTime"></div>
    <div class="text-nowrap" data-bind="positionInfo"></div>
</div>
`;


export class AliveListItem {
    readonly element: HTMLElement;
    private readonly owner: AliveList;
    private readonly tickTimer: PeriodicTask

    private clickMenu: ContainerClickMenu | null = null

    private readonly bindings = new BindingList()
    readonly data: ContainerDataAdapter

    list: HTMLElement | null = null

    constructor(owner: AliveList, data: ContainerDataAdapter) {
        this.owner = owner
        this.data = data
        this.tickTimer = new PeriodicTask(this.tick.bind(this), {periodMs: 1000, enabled: false})

        this.element = createHtmlElement('div', {
            role: 'button',
            tabindex: 0,
            class: 'border rounded-1 mb-2 mx-0 align-middle container-row-widget d-flex align-items-center container-theme'
        }, TEMPLATE)
        this.bindings.collect(this.element)

        this.element.addEventListener('click', e => {
            const menu = this.requireClickMenu().update()
            const popover = menu.createPopover(this.element);
            SinglePopover.show(this.element, popover, {dispose: true, hidden: () => menu.hidePopover()})
            e.preventDefault()
        })

        this.update()
    }

    private requireClickMenu() {
        if (this.clickMenu == null) {
            this.clickMenu = new ContainerClickMenu(this.owner.playerData, this.data.id);
        }
        return this.clickMenu;
    }

    dispose() {
        this.element.remove()
        this.clickMenu?.hidePopover()
        this.clickMenu = null
    }

    update() {
        const data = this.data

        ContainerStateValues.forEach(s => this.element.classList.remove(s))
        if (data.state != null) this.element.classList.add(data.state)

        this.bindings.update('number', node => node.innerText = `${data.number}`)
        this.bindings.update<HTMLImageElement>('icon', node => node.src = `/content/icon.${data.type.toLowerCase()}.svg`)

        this.bindings.update('number', node => node.innerText = data.number)
        this.tickTimer.enabled = data.programFinishTime != null

        this.bindings.update('process', node => setInnerTextAndTitle(node, data.programProcess))
        this.bindings.update('coating', node => setInnerTextAndTitle(node, data.programCoating))

        if (isAliveListWaiting(this.data)) {
            this.bindings.update('startPosition', node => node.innerText = data.programStartPositionString)
            this.bindings.toggle('startInfo', data.programStartPosition != null)

            const estimateDuration = data.programState == Program_State.NEW ? data.estimateDuration : ''
            this.bindings.update('duration', node => node.innerText = estimateDuration)
            this.bindings.toggle('durationInfo', estimateDuration.length > 0)
        } else {
            this.bindings.toggle('startInfo', false)
            this.bindings.toggle('durationInfo', false)
        }

        this.updateView(data)
    }

    private updateView(data: ContainerDataAdapter) {
        let positionInfo = '';
        let finishIn = ''
        let overexposure = false

        if (data.transport != null) {
            const transportUpdate = this.owner.getLastTransportUpdate(data.transport);
            if (transportUpdate && transportUpdate.targetPosition != null) {
                positionInfo = `${transportUpdate.position} 🢒 ${transportUpdate.targetPosition}`
            } else if (transportUpdate && transportUpdate.position != null) {
                positionInfo = `${transportUpdate.position}`
            }
        } else if (data.position != null) {
            positionInfo = `${data.position}`

            overexposure = data.overexposure
            if (overexposure && data.overexposureMs != null) {
                finishIn = durationTextAround(messages, msToDurationAround(new Date().getTime() - data.overexposureMs))
            }
        }
        this.bindings.update('positionInfo', node => node.innerText = positionInfo)
        this.bindings.toggle('positionInfo', positionInfo.length > 0)

        this.element.classList.toggle('OVERFLOW', overexposure);
        if (overexposure) {
            finishIn = messages.get('ContainerListItem.overexposureText') + ` ${finishIn}`
        }
        this.bindings.update('finishTime', node => node.textContent = finishIn);
        this.bindings.toggle('finishTime', finishIn.length > 0);
    }

    private tick() {
        this.updateView(this.data)
    }
}