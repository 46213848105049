import {escapeHTML} from "../lib/escapeHTML";
import {Messages} from "../messages/Messages";
import {Modal} from "bootstrap";
import {createHtmlElement, toggleHidden, toggleReloadingFade} from "../lib/domFunctions";
import {fetchJson, toastFetchError} from "../lib/fetch";
import {AppConfig} from "../AppConfig";
import {ProcessListItem} from "../dto/com.rico.sb2.service.process";
import {buttonProgress} from "../lib/buttonProgress";
import {emptyPage, Page} from "../dto/org.springframework.data.domain";
import {coalesce} from "../lib/coalesce";

const messages = new Messages();

export type ProcessInputFieldChange = (item: ProcessListItem | null) => void | Promise<boolean>
export type ProcessInputFieldSearch = (search: string) => Promise<Page<ProcessListItem>>

export class ProcessInputField {
    private readonly fireChange: ProcessInputFieldChange;
    private readonly request: ProcessInputFieldSearch;
    private readonly valueField: HTMLInputElement;
    private readonly textField: HTMLInputElement;

    constructor(params: { valueField: HTMLInputElement, textField: HTMLInputElement, change: ProcessInputFieldChange, request?: ProcessInputFieldSearch }) {
        this.valueField = params.valueField
        this.textField = params.textField
        this.request = coalesce(params.request, (search) => fetchJson(`/processes/listPage?search=${encodeURIComponent(search)}&offset=0&limit=10`))
        this.fireChange = (item) => {
            this.toggleProcessButtons()
            return params.change(item);
        }

        const group = this.uiGroup
        if (group) {
            group.querySelectorAll('[data-bind="clearProcess"]').forEach(node => node.addEventListener('click', this.clear.bind(this)))
            group.querySelectorAll('[data-bind="searchProcess"]').forEach(node => node.addEventListener('click', this.search.bind(this)))
        }

        this.textField.addEventListener('click', e => {
            if (this.textField.disabled) return;

            if (this.value == null) {
                this.search()
                e.preventDefault();
            }
        })
    }

    get value(): number | null {
        const valueParsed = parseInt(this.valueField.value);
        return Number.isFinite(valueParsed) ? valueParsed : null;
    }

    get valueText(): string {
        return this.textField.value.trim()
    }

    private get uiGroup() {
        return this.textField.closest('.input-group');
    }

    private toggleProcessButtons() {
        const selected = this.value != null && this.value > 0
        const group = this.uiGroup
        if (!group) return;

        group.querySelectorAll('[data-bind="clearProcess"]').forEach(node => toggleHidden(node, !selected))
        group.querySelectorAll('a[data-bind="openProcess"]').forEach(node => {
            toggleHidden(node, !selected)
            node.setAttribute('href', `${AppConfig.CP}/processes/${this.value}/edit`)
        })
    }

    clear() {
        this.valueField.value = '';
        this.textField.value = '';
        this.textField.setAttribute('title', '')
        this.fireChange(null)
    }

    search() {
        new ProcessInputModalSearch(this.request, (item) => {
            if (item == null) return
            if (item.id.toString() === this.valueField.value) return

            this.set(item.id, item.code, item.name)
            return this.fireChange(item)
        });
    }

    set(id: number, code: string, name: string) {
        this.valueField.value = id.toString()
        this.textField.value = `${code} ${name}`
        this.textField.setAttribute('title', this.textField.value)
        this.toggleProcessButtons()
    }

    enable(enabled: boolean) {
        const group = this.uiGroup

        function setDisabled(element: Element, disabled: boolean) {
            if (disabled) {
                element.setAttribute('disabled', 'disabled')
            } else {
                element.removeAttribute('disabled')
            }
        }

        setDisabled(this.valueField, !enabled)
        setDisabled(this.textField, !enabled)
        if (group) {
            group.querySelectorAll('[data-bind="clearProcess"]').forEach(node => setDisabled(node, !enabled))
            group.querySelectorAll('[data-bind="searchProcess"]').forEach(node => setDisabled(node, !enabled))
        }
    }
}

class ProcessInputModalSearch {
    private readonly receiver: ProcessInputFieldChange
    private readonly request: ProcessInputFieldSearch
    private readonly modal: Modal

    private readonly searchField: HTMLInputElement;
    private readonly searchEmpty: Element;
    private readonly searchTable: Element;
    private readonly searchNotice: HTMLElement;
    private readonly searchError: HTMLElement;

    private readonly queryTimeout = 300
    private readonly queryMinLetters = 1

    private lastQueryNumber = 0
    private lastQueryText = ''
    private lastQueryResult: any[] = []

    constructor(request: ProcessInputFieldSearch, receiver: ProcessInputFieldChange) {
        this.receiver = receiver
        this.request = request
        const content = createHtmlElement('div', {'tab-index': '-1', class: 'modal modal-lg'}, `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <h5 class="modal-title" id="exampleModalLabel">${escapeHTML(messages.get('ProcessInputField.searchTitle'))}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0">
        <input type="text" class="form-control" data-bind="searchField" placeholder="${escapeHTML(messages.get('ProcessInputField.searchPlaceholder'))}">
        
        <div class="text-muted py-5 text-center hidden" data-bind="searchEmpty">
            ${escapeHTML(messages.get('ProcessInputField.searchEmpty'))}
        </div>
        <div class="text-danger py-5 text-center hidden" data-bind="searchError">
            ${escapeHTML(messages.get('ProcessInputField.searchError'))}
        </div>
        
        <table class="table table-borderless table-hover mt-3 mb-0" data-bind="searchTable">
            <thead>
                <tr>
                    <th class="text-nowrap">${escapeHTML(messages.get('ProcessInputField.table.code'))}</th>
                    <th class="text-nowrap w-100">${escapeHTML(messages.get('ProcessInputField.table.name'))}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody></tbody>        
        </table>
        <div class="hidden mt-3 text-muted text-center" data-bind="searchNotice"></div>
      </div>
    </div>
  </div>
`)

        this.searchField = content.querySelector('input[data-bind=searchField]')!!
        this.searchEmpty = content.querySelector('div[data-bind=searchEmpty]')!!
        this.searchError = content.querySelector('div[data-bind=searchError]')!!
        this.searchTable = content.querySelector('table[data-bind=searchTable]')!!
        this.searchNotice = content.querySelector('div[data-bind=searchNotice]')!!

        this.searchField.addEventListener('input', () => this.scheduleQuery());
        this.searchTable.addEventListener('click', (e) => this.onTableClick(e));

        this.modal = new Modal(content)
        this.modal.show();

        this.scheduleQuery(true)
    }

    private scheduleQuery(force: boolean = false) {
        const searchText = this.searchField.value.trim();
        if (!force && (searchText.length < this.queryMinLetters || searchText === this.lastQueryText)) {
            return;
        }

        toggleReloadingFade(this.searchTable, true)
        toggleReloadingFade(this.searchEmpty, true)
        toggleReloadingFade(this.searchError, true)

        this.lastQueryText = searchText
        const queryNumber = ++this.lastQueryNumber
        setTimeout(() => this.executeQuery(queryNumber, searchText), this.queryTimeout);
    }

    private executeQuery(queryNumber: number, searchText: string) {
        if (queryNumber != this.lastQueryNumber) return;

        this.request(searchText)
            .then(json => this.setContent(json, this.searchEmpty))
            .catch(error => {
                toastFetchError(error)
                this.setContent(emptyPage(), this.searchError)
            });
    }

    private setContent(page: Page<ProcessListItem>, emptyNotice: Element) {
        toggleReloadingFade(this.searchTable, false)
        toggleReloadingFade(this.searchEmpty, false)
        toggleReloadingFade(this.searchError, false)

        this.lastQueryResult = page.content;

        const body = this.searchTable.querySelector('tbody')!!
        body.innerHTML = ``;
        toggleHidden(this.searchNotice, true);

        if (page.totalElements == 0) {
            toggleHidden(this.searchTable, true);
            toggleHidden(emptyNotice, false);
            return
        }

        const safe = escapeHTML;

        let bodyHtml = ''
        page.content.forEach(item => {
            const buttons = `<button class="btn btn-sm btn-secondary" data-bind="select">${messages.get('button.select')}</button>`;
            bodyHtml += `
            <tr class="align-baseline" data-item="${safe(JSON.stringify(item))}">
                <td class="text-nowrap">${safe(item.code)}</td>            
                <td>${safe(item.name)}</td>            
                <td class="text-nowrap">${buttons}</td>            
            </tr>`
        })

        body.innerHTML = bodyHtml

        toggleHidden(this.searchTable, false);
        toggleHidden(this.searchEmpty, true);
        toggleHidden(this.searchError, true);

        if (page.totalElements > page.numberOfElements) {
            this.searchNotice.innerText = messages.get('ProcessInputField.countNotice', page.numberOfElements, page.totalElements)
            toggleHidden(this.searchNotice, false);
        }
    }

    private onTableClick(e: Event) {
        const target = e.target as HTMLElement
        if (target.tagName === 'BUTTON' && target.getAttribute('data-bind') === 'select') {
            const row = target.closest('tr')!

            const item = JSON.parse(row.getAttribute('data-item')!)

            const detailItem = this.lastQueryResult.filter(a => a.id === item.id)[0]
            if (!detailItem) return

            const receiveProgress = buttonProgress(target)
            const receiveResult = this.receiver(item)
            let receivePromise: Promise<boolean>;
            if (typeof receiveResult === 'undefined') {
                receivePromise = Promise.resolve(true)
            } else {
                receivePromise = Promise.resolve(receiveResult)
            }

            receivePromise.then(success => {
                receiveProgress.stop()
                if (success) {
                    this.modal.hide()
                }
            })
        }
    }
}