import {tryCatch} from "./lib/tryCatch";

class AppBusImpl {
    private readonly listeners = new Map<string, Function[]>();

    private ensureListeners(type: string): Function[] {
        if (!this.listeners.has(type)) {
            this.listeners.set(type, []);
        }
        return this.listeners.get(type)!
    }

    addEventListener(type: string, callback: Function) {
        const list = this.ensureListeners(type);
        if (list.indexOf(callback) >= 0) {
            return;
        }
        list.push(callback)
    }

    removeEventListener(type: string, callback: Function) {
        const list = this.ensureListeners(type);
        this.listeners.set(type, list.filter(c => c != callback))
    }

    fire(type: string, ...args: any[]) {
        const list = this.ensureListeners(type);
        const returns = list
            .map(fn => tryCatch(() => fn.apply(this, args)))
        return returns[list.length - 1];
    }
}

export const AppBus = new AppBusImpl();

export enum AppEvents {
    VIEW_CONTAINER = 'VIEW_CONTAINER',
    EDIT_CONTAINER = 'EDIT_CONTAINER',
    PRINT_CONTAINER = 'PRINT_CONTAINER'
}