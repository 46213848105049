import {Messages} from "../messages/Messages";
import {BindingList, createHtmlElement} from "../lib/domFunctions";
import {ProcessControlService_Mode} from "../dto/com.rico.sb2.service";
import {SendTransportToPosition} from "../modals/SendTransportToPosition";
import {UpdateSchemeMessage, UpdateTransportMessage, visitUpdateSchemeMessage} from "../dto/com.rico.sb2.message";
import {TransportState} from "../dto/com.rico.sb2.entity.device";
import {SendTransportTake} from "../modals/SendTransportTake";
import {SendTransportPut} from "../modals/SendTransportPut";
import {PlayerDataAdapter, UpdateSchemeMessageConsumer} from "../PlayerDataAdapter";
import {Popover, PopoverInstance} from "../lib/bootstrapPopover";

const messages = new Messages();

export class TransportPopover implements UpdateSchemeMessageConsumer, PopoverInstance {
    private readonly playerData: PlayerDataAdapter
    private readonly id: number

    private body: HTMLElement | null = null
    private bindings = new BindingList();

    private popover: Popover | null = null

    constructor(playerData: PlayerDataAdapter, id: number) {
        this.playerData = playerData
        this.id = id
    }

    private get transport() {
        return this.playerData.getTransportData(this.id);
    }

    title(): string | Element {
        const transport = this.transport
        const textType = messages.get(`dashboard.modal.${transport.type.toLowerCase()}.title`, transport.number);
        const textState = transport.state == null ? `` : `<span class="badge text-bg-info text-lowercase ms-3 fw-normal">${messages.get(`transport.state.${transport.state}`)}</span>`;
        return createHtmlElement('div', {class: "d-flex"}, `<div>${textType}</div><div class="flex-fill"></div>${textState}`)
    }

    content(): string | Element {
        return this.createBody() || ''
    }

    onSchemeUpdate(update: UpdateSchemeMessage): void {
        visitUpdateSchemeMessage(update, {
            transportMessage: m => this.onSchemeTransportUpdate(m)
        })
    }

    private onSchemeTransportUpdate(update: UpdateTransportMessage) {
        if (update.id != this.id) return;

        this.updateBody();
        this.popover?.update();
    }

    private createBody(): Element | null {
        this.body = createHtmlElement('div', {}, `
            <div class="mb-n2">
                <table class="w-100">
                    <tr class="align-baseline" data-bind="positionInfo">
                        <td class="p-0 text-nowrap pb-2 pe-4">${messages.get('TransportPopover.label.position')}</td>
                        <td class="p-0 text-end pb-2" data-bind="position"></td>
                    </tr>
                </table>

                <div class="d-flex align-items-baseline justify-content-between" style="margin-left: -.25rem; margin-right: -.25rem; margin-bottom: -.5rem;" data-bind="operations">
                    <div class="flex-fill"></div>
                    <button type="button" class="btn btn-primary btn-sm mb-2 mx-1 text-nowrap" data-bind="actionTake">${messages.get('TransportPopover.action.take')}</button>
                    <button type="button" class="btn btn-primary btn-sm mb-2 mx-1 text-nowrap" data-bind="actionPut">${messages.get('TransportPopover.action.put')}</button>
                    <button type="button" class="btn btn-primary btn-sm mb-2 mx-1 text-nowrap" data-bind="actionMove">${messages.get('TransportPopover.action.move')}</button>
                </div>
            </div>
        `)

        this.bindings.collect(this.body);
        this.bindings.update('actionMove', button => button.addEventListener('click', () => this.move()))
        this.bindings.update('actionTake', button => button.addEventListener('click', () => this.take()))
        this.bindings.update('actionPut', button => button.addEventListener('click', () => this.put()))

        this.updateBody();

        return this.body;
    }

    private updateBody() {
        const body = this.body
        if (!body) return

        const transport = this.transport
        const position = this.transport.position;

        let positionInfo = false
        if (position != null) {
            positionInfo = true

            let text = `${position}`
            const positionName = transport.positionTitle;
            if (positionName) {
                text += ` (${positionName})`
            }
            this.bindings.update('position', node => node.textContent = text)
        }
        this.bindings.toggle('positionInfo', positionInfo);

        const serviceModeWithOperations = new Set([ProcessControlService_Mode.MANUAL, ProcessControlService_Mode.PAUSED])
        this.bindings.toggle('operations', transport.serviceMode != null && serviceModeWithOperations.has(transport.serviceMode));
        this.bindings.toggle('actionMove', transport.state == TransportState.STOPPED);
        this.bindings.toggle('actionTake', transport.type == 'AO' && transport.state == TransportState.STOPPED && transport.container == null && transport.containerBelow != null);
        this.bindings.toggle('actionPut', transport.type == 'AO' && transport.state == TransportState.STOPPED && transport.container != null && transport.containerBelow == null);
    }

    private move() {
        new SendTransportToPosition(this.id, null).showModal();
    }

    private take() {
        new SendTransportTake(this.id).showModal();
    }

    private put() {
        new SendTransportPut(this.id).showModal();
    }

    createPopover(target: Element, rootContainer: HTMLElement): Popover {
        this.playerData.updateListeners.add(this)

        const options: Partial<Popover.Options> = {
            animation: false, html: true, trigger: 'manual', sanitize: false,
            container: rootContainer,
            // да, такого свойства нет. но оно все равно работает
            // @ts-ignore
            customClass: () => function (templateFactory: any): string {
                if (templateFactory && templateFactory._config && templateFactory._config.content && templateFactory._config.content['.popover-body'] == '') {
                    return 'dashboard-popover popover-header-only'
                }
                return 'dashboard-popover'
            },
            content: () => this.content(),
            title: () => this.title(),
        };

        return this.popover = new Popover(target, options);
    }

    hidePopover(): void {
        this.playerData.updateListeners.remove(this)
    }
}