import {BindingList} from "./lib/domFunctions";
import {apiPostForAjaxResponse} from "./lib/SecuredAjax";
import {AppConfig} from "./AppConfig";
import {toastFetchError} from "./lib/fetch";
import {bootstrapModal} from "./lib/bootstrapModal";
import {PeriodicTask} from "./lib/PeriodicTask";
import {buttonProgress} from "./lib/buttonProgress";

export class RebootPage {
    constructor() {
        const binding = new BindingList();
        binding.collect(document.documentElement);

        binding.update('doSnapshot', node => node.addEventListener('click', this.doSnapshot.bind(this)));
        binding.update('doReboot', node => node.addEventListener('click', this.doReboot.bind(this)));
        binding.update('deleteSnapshot', node => node.addEventListener('click', this.deleteSnapshot.bind(this)));
    }

    doReboot() {
        const {content, modal} = bootstrapModal({
            title: 'Создаем архив c логами и перезагружаемся', body: `Если информации много, то создание архива может занять некоторое время`,
            closeable: false,
            buttonOk: null, buttonCancel: null
        });

        modal.show();

        function progressModalHide() {
            modal.hide();
        }

        apiPostForAjaxResponse(`${AppConfig.CP}/reboot/proceed`, {})
            .catch(error => {
                if (error instanceof TypeError) {
                    this.waitSiteStart(content);
                } else {
                    progressModalHide();
                    toastFetchError(error);
                }
            });
    }

    private waitSiteStart(content: HTMLElement) {
        const modalTitle = content.querySelector('.modal-title') as HTMLElement;
        modalTitle.innerText = 'Сайт перезапускается';
        const modalBody = content.querySelector('.modal-body') as HTMLElement;
        modalBody.innerText = 'Ждем старта сайта...';

        let waitPending = false;
        new PeriodicTask(function () {
            if (waitPending) return;

            waitPending = true;
            fetch('/')
                .then(() => document.location.href = '/')
                .finally(() => waitPending = false);
        }, {periodMs: 2000, enabled: true});
    }

    doSnapshot() {
        const {modal} = bootstrapModal({
            title: 'Создаем архив c логами', body: `Если информации много, то создание архива может занять некоторое время`,
            closeable: false,
            buttonOk: null, buttonCancel: null
        });

        modal.show();
        const progressModalHide = modal.hide.bind(modal);

        apiPostForAjaxResponse(`${AppConfig.CP}/reboot/snapshot`, {})
            .then(() => document.location.reload())
            .catch(error => {
                toastFetchError(error);
                progressModalHide();
            });
    }

    deleteSnapshot(e: MouseEvent) {
        const button = e.currentTarget as HTMLButtonElement;
        const progress = buttonProgress(button);

        const snapshotId = button.getAttribute('data-arg');

        apiPostForAjaxResponse(`${AppConfig.CP}/reboot/snapshot/${snapshotId}/delete`)
            .then(() => button.closest('tr')?.remove())
            .catch(toastFetchError)
            .finally(() => progress.stop());
    }
}