import {MessageProvider, MessageProviderWithCache} from "./MessageProvider";

declare var window: any;

/**
 * Expect messages configured at window level, like this:
 *<pre>
 *     window.MessagesSource = {
 *     locale: "<locale language, like en, ru, etc>",
 *     texts: {
 *         "searchResults_pageTitle":"Search for '{0}' is empty",
 *         ...
 *     }
 * }
 *</pre>
 */
export class Messages implements MessageProvider {
	private readonly delegate: MessageProvider;

	constructor() {
		const messageSource: any = (window.MessageSource || {});

		this.delegate = new MessageProviderWithCache(messageSource.locale || '', {
			get(code: string): string {
				return this.has(code) ? messageSource.texts[code] : code;
			},
			has(code: string): boolean {
				return 'texts' in messageSource && code in messageSource.texts;
			}
		});
	}

	get(code: string, ...params: any[]) {
		const provider = this.delegate;
		const nextArgs = [code, ...params];
		// @ts-ignore
		return provider.get.apply(provider, nextArgs);
	}

	has(code: string) {
		const provider = this.delegate;
		const nextArgs = [code];
		// @ts-ignore
		return provider.has.apply(provider, nextArgs);
	}
}
