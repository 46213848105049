import {AppConfig} from "./AppConfig";
import {ProcessInputField} from "./modals/ProcessInputField";
import {CoatingInputField} from "./modals/CoatingInputField";

export class NativeReportForm {
    static initIndex() {
        const reportFormSelect = document.querySelector<HTMLSelectElement>('select[name="report"]');
        if (!reportFormSelect) return;

        reportFormSelect.addEventListener('change', () => {
            const reportId = reportFormSelect.value;
            if (reportId) {
                document.location.href = `${AppConfig.CP}/reports/native/${encodeURIComponent(reportId)}`;
            } else {
                document.location.href = `${AppConfig.CP}/reports/native`;
            }
        });
    }

    static initForm() {
        const form = document.getElementById('form') as HTMLFormElement;
        if (!form) return;

        form.querySelectorAll('[data-parameter-type]').forEach(node => {
            const type = node.getAttribute('data-parameter-type')
            switch (type) {
                case 'Process': {
                    new ProcessInputField({
                        valueField: node.querySelector('[data-bind-ref="value"]')!,
                        textField: node.querySelector('[data-bind-ref="text"]')!,
                        change: () => {
                        }
                    });
                    break;
                }
                case 'Coating': {
                    new CoatingInputField(
                        node.querySelector('[data-bind-ref="value"]')!,
                        node.querySelector('[data-bind-ref="text"]')!,
                        () => {
                        }
                    );
                    break;
                }
            }
        });
    }
}