import './boot';

import 'bootstrap';

import {AppConfig} from "./AppConfig";
import {PositionTypeListPage} from "./PositionTypeListPage";
import {PositionTypeEditPage} from "./PositionTypeEditPage";
import {RectifierProfileEditPage} from "./RectifierProfileEditPage";
import {PositionListPage} from "./PositionListPage";
import {DetailListPage} from "./DetailListPage";
import {DetailEditPage} from "./DetailEditPage";
import {TransportListPage} from "./TransportListPage";
import {PositionEditPage} from "./PositionEditPage";
import {ProcessEditPage} from "./ProcessEditPage";
import {ProcessListPage} from "./ProcessListPage";
import {CoatingListPage} from "./CoatingListPage";
import {CoatingEditPage} from "./CoatingEditPage";
import {StatusAlerts} from "./StatusAlerts";
import {WebSocketClient} from "./WebSocketClient";
import {PropertiesEditPage} from "./PropertiesEditPage";
import {CoatingDetailsList} from "./CoatingDetailsList";
import {ContentBlockEditPage} from "./ContentBlockEditPage";
import {SchemaEditor} from "./SchemaEditor";
import {Dashboard} from "./Dashboard";
import {ContainerEditPage} from "./ContainerEditPage";
import {ContainerViewPage} from "./ContainerViewPage";
import {showToastSuccess} from "./lib/boostrapToast";
import {ContainerQueueTable} from "./ContainerQueueTable";
import {RebootPage} from "./RebootPage";
import {ReportListPage} from "./ReportListPage";
import {ReportOnePage} from "./ReportOnePage";
import {ImportPage} from "./ImportPage";
import {ImportDetailsPage} from "./ImportDetailsPage";
import {NativeReportForm} from "./NativeReportForm";

import 'bootstrap-table/dist/locale/bootstrap-table-ru-RU.min';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.ru.min';
import {BackupPage} from "./BackupPage";
import {SchemaState} from "./SchemaState";

declare var global: any;
global.App = {
	AppConfig,
	BackupPage,
	CoatingListPage,
	CoatingEditPage,
	CoatingDetailsList,
	ContainerEditPage,
	ContainerQueueTable,
	ContainerViewPage,
	ContentBlockEditPage,
	Dashboard,
	DetailEditPage,
	DetailListPage,
	ImportPage,
	ImportDetailsPage,
	NativeReportForm,
	PositionEditPage,
	PositionListPage,
	PositionTypeListPage,
	PositionTypeEditPage,
	ProcessEditPage,
	ProcessListPage,
	PropertiesEditPage,
	RebootPage,
	RectifierProfileEditPage,
	ReportListPage,
	ReportOnePage,
	StatusAlerts,
	SchemaState,
	SchemaEditor,
	TransportListPage,
	WebSocketClient,
	showToastSuccess
}
