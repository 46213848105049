import {tryCatch} from "./tryCatch";

export class ListenerList<T> {
    private consumers: T[] = []

    add(consumer: T) {
        this.remove(consumer)
        this.consumers.push(consumer);
    }

    remove(consumer: T) {
        this.consumers = this.consumers.filter(c => c != consumer)
    }

    protected visit(visitor: (listener: T) => any) {
        this.consumers.forEach(c => {
            tryCatch(() => visitor(c))
        })
    }
}
