import {PlayerData_Transport} from "../dto/com.rico.sb2.service.positions";
import {UpdateTransportMessage} from "../dto/com.rico.sb2.message";
import {ProcessControlService_Mode} from "../dto/com.rico.sb2.service";
import {TransportState} from "../dto/com.rico.sb2.entity.device";

export interface TransportDataSupplier {
    get data(): PlayerData_Transport

    get update(): UpdateTransportMessage | null

    get serviceMode(): ProcessControlService_Mode | null

    getPositionTitle(pos: number | null): string

    findContainerOnTransport(): number | null

    findContainerOnPosition(position: number): number | null
}

export class TransportDataAdapter {
    private readonly supplier: TransportDataSupplier;

    constructor(supplier: TransportDataSupplier) {
        this.supplier = supplier
    }

    get serviceMode() {
        return this.supplier.serviceMode;
    }

    get type() {
        return this.supplier.data.type;
    }

    get number() {
        return this.supplier.data.number;
    }

    get state() {
        const update = this.supplier.update;
        return update ? update.state : TransportState.STOPPED;
    }

    get position() {
        const update = this.supplier.update;
        if (update != null) {
            return update.position;
        }
        return this.supplier.data.position ?? this.supplier.data.min;
    }

    get positionTitle() {
        return this.supplier.getPositionTitle(this.position);
    }

    get container(): number | null {
        return this.supplier.findContainerOnTransport();
    }

    get containerBelow(): number | null {
        const position = this.position;
        if (position == null) return null;
        return this.supplier.findContainerOnPosition(position);
    }
}