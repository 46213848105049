import {ICUPluralRule} from "./ICUPlurals";

export type StringMap<T = any> = { [key: string]: T };

const END_FE = '}';
const START_FE = '{';
const QUOTE = "'";

class StringReader {
    private input: string;
    private index: number;

    constructor(input?: string) {
        this.input = input || '';
        this.index = 0;
    }

    advance() {
        if (this.hasMore()) {
            this.index++;
        }
    }

    expectNextR(test: RegExp): boolean {
        if (this.index >= this.input.length - 1) {
            return false;
        }
        return test.test(this.input.charAt(this.index + 1));
    }

    expectNext(test: string): boolean {
        if (this.index >= this.input.length - 1) {
            return false;
        }
        return test === this.input.charAt(this.index + 1);
    }

    hasMore(): boolean {
        return this.index < this.input.length;
    }

    getChar(): string {
        return this.input.charAt(this.index);
    }

    readWhileR(re: RegExp): string {
        let result = '';
        while (this.hasMore()) {
            if (!re.test(this.getChar())) {
                break;
            }
            result += this.getChar();
            this.advance();
        }
        return result;
    }

    readUnlessR(re: RegExp): string {
        let result = '';
        while (this.hasMore()) {
            const next = this.getChar();
            if (re.test(next)) {
                break;
            }
            result += next;
            this.advance();
        }
        return result;
    }

    readUnless(re: string): string {
        let result = '';
        while (this.hasMore()) {
            const next = this.getChar();
            if (re === next) {
                break;
            }
            result += next;
            this.advance();
        }
        return result;
    }

    skipWhileR(re: RegExp) {
        while (this.hasMore()) {
            if (!re.test(this.getChar())) {
                break;
            }
            this.advance();
        }
    }

    getRest(): string {
        return this.input.substring(this.index);
    }

    /**
     * @param {Function} contentTest
     * @param {Boolean} keepQuotedString set true, if your want original quoted string, without unescape
     * @returns {String}
     */
    readBraces(contentTest?: Function, keepQuotedString?: boolean) {
        let mark = this.index;

        if (typeof contentTest !== 'function') {
            contentTest = () => true;
        }
        if (typeof keepQuotedString === "undefined") {
            keepQuotedString = false;
        }


        let content = '';
        let deep = 1;
        this.advance();

        const fail = () => {
            this.index = mark;
            return null;
        };

        while (this.hasMore()) {
            switch (this.getChar()) {
                case QUOTE:
                    const quotedString = this.readQuoted();
                    content += keepQuotedString ? quotedString.raw : quotedString.value;
                    continue;
                case START_FE:
                    deep++;
                    break;
                case END_FE:
                    if (deep === 1) {
                        if (contentTest(content)) {
                            this.advance();
                            return content;
                        } else {
                            return fail();
                        }
                    } else {
                        deep--;
                    }
            }

            content += this.getChar();
            this.advance();
        }

        return fail();
    }

    readQuoted(): QuotedString {
        let raw = this.getChar();
        this.advance();

        if (this.hasMore() && this.getChar() === QUOTE) {
            raw += this.getChar();
            this.advance();
            return new QuotedString(QUOTE, raw);
        }

        let value = '';
        while (this.hasMore()) {
            raw += this.getChar();

            if (this.getChar() === QUOTE) {
                this.advance();

                if (this.getChar() === QUOTE) {
                    raw += this.getChar();
                } else {
                    return new QuotedString(value, raw);
                }
            }

            value += this.getChar();
            this.advance();
        }

        return new QuotedString(value, raw);
    }
}

class QuotedString {
    readonly value: string
    readonly raw: string

    constructor(value: string, raw: string) {
        this.value = value;
        this.raw = raw;
    }
}

type ICUMessageFormatFunction = (messages: ICUPluralRule, args: any[]) => any;

const ICUMessageFormats: StringMap = {
    'default': (ordinal: number): ICUMessageFormatFunction => {
        return (messages: ICUPluralRule, args: any[]): any => args[ordinal];
    },
    'plural': (ordinal: number, options?: any): ICUMessageFormatFunction => {
        const optionsReader = new StringReader(options);

        const model: StringMap<string | null> = {};
        while (optionsReader.hasMore()) {
            const state = optionsReader.readUnless(START_FE).trim();
            if (state === '') {
                break;
            }
            model[state] = optionsReader.readBraces();
        }

        return (plurals: ICUPluralRule, args: any[]): any => {
            const argsValue = args[ordinal];
            const number = typeof argsValue === "number" ? Math.floor(argsValue) : parseInt(argsValue);

            let state = null;
            if (!isNaN(number)) {
                state = plurals.pluralRule(number);
            }

            if (state && state in model) {
                return model[state];
            }
            return model['other'];
        };
    }
};

/**
 * Javascript port for com.rico.rubd.support.icu.ICUMessageFormat
 */
export class ICUMessageFormat {
    readonly formats: any[];

    constructor(pattern: string) {
        this.formats = [];

        this.parsePattern(pattern,
            (token: string) => this.formats.push(() => token),
            (ordinal: number, spec: string, options: string) => this.formats.push(this.createFormatter(ordinal, spec, options)));
    }

    /**
     * @param {String} pattern
     * @param {Function} textReceiver
     * @param {Function} formatReceiver
     * @private
     */
    parsePattern(pattern: string, textReceiver: Function, formatReceiver: Function) {
        const reader = new StringReader(pattern);
        let token = '';

        function panic() {
            throw new Error(`invalid pattern ${pattern}`);
        }

        const argumentComplete = (content: string) => {
            if (!content.length) {
                return;
            }

            const tokenReader = new StringReader(content);

            tokenReader.skipWhileR(/\s/);
            const ordinal = parseInt(tokenReader.readWhileR(/\d+/).trim());
            if (isNaN(ordinal)) panic();

            tokenReader.skipWhileR(/[\s,]/);
            const spec = tokenReader.readUnlessR(/,/).trim();

            tokenReader.skipWhileR(/[\s,]/);
            let options = tokenReader.getRest().trim();

            formatReceiver(ordinal, spec, options);
        };

        const textComplete = (token: string) => {
            if (token.length) {
                textReceiver(token);
            }
        };

        const FE_ORDINAL_TEST = /^\s*\d/;
        while (reader.hasMore()) {
            switch (reader.getChar()) {
                case START_FE:
                    textComplete(token);
                    token = '';

                    const braces = reader.readBraces(FE_ORDINAL_TEST.test.bind(FE_ORDINAL_TEST), true);
                    if (braces == null) {
                        break;
                    }

                    argumentComplete(braces);
                    continue;

                case QUOTE:
                    token += reader.readQuoted().value;
                    continue;
            }
            token += reader.getChar();
            reader.advance();
        }

        textComplete(token);
    }

    /**
     * @param {int} ordinal parameter index
     * @param {string} spec formatter class
     * @param {string} options other parameters
     * @private
     */
    createFormatter(ordinal: number, spec: string, options: string) {
        const factory = spec in ICUMessageFormats
            ? ICUMessageFormats[spec]
            : ICUMessageFormats.default;
        return factory(ordinal, options);
    }

    format(plurals: ICUPluralRule, args?: any[]) {
        switch (this.formats.length) {
            case 0:
                return '';
            case 1:
                return this.formats[0](plurals, args);
            default:
                return this.formats.map(f => f(plurals, args)).join('');
        }
    }
}
