import {apiPost} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {isAjaxResponseOk, toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";
import {bootstrapModal} from "../lib/bootstrapModal";
import {Messages} from "../messages/Messages";

const messages = new Messages();

export class DisbandContainer {
	private readonly containerId: number;

	constructor(containerId: number) {
		this.containerId = containerId;
	}

	execute(): Promise<void> {
		return new Promise((resolve, reject) => {
			apiPost(`${AppConfig.CP}/op/disbandContainer`, {container: this.containerId})
				.then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res.message))
				.then(success => {
					if (!success) return Promise.reject(500)

					showToastInfo(messages.get('FinishContainer.completeNotice'))
					resolve()
				})
				.catch(toastFetchError)
				.then(() => reject());
		})
	}

	showModal(completer?: Function) {
		const {modal, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
			title: messages.get('FinishContainer.confirmNotice', this.containerId),
			body: '',
			buttonOk: messages.get('boolean.yes'),
			buttonCancel: messages.get('boolean.no')
		});
		modal.show();

		const ok = buttonOk!!;

		ok.addEventListener('click', () => {
			buttonOkClickStart()

			this.execute()
				.then(() => {
					modal.hide()
					if (completer) completer()
				})
				.catch(buttonOkClickFinish)
		})
	}
}