import {fetchJsonForApiResponse, toastFetchError} from "./lib/fetch";
import {escapeHTML} from "./lib/escapeHTML";
import {ProcessInputField} from "./modals/ProcessInputField";
import {createHtmlElement, querySelectorAllWithName, querySelectorWithName, toggleVisibilityHidden} from "./lib/domFunctions";
import {coalesce} from "./lib/coalesce";
import {CoatingCodePattern} from "./dto/com.rico.sb2.support";

const h = escapeHTML;

const CODE_PATTERN_ATTRIBUTE = 'data-pattern';

export class CoatingEditPage {
    private readonly thicknessTableBody: HTMLTableSectionElement
    private readonly form: HTMLFormElement;
    private readonly processInput: ProcessInputField;
    private readonly codeOfferValue: HTMLElement | null

    constructor() {
        this.form = document.getElementById('form') as HTMLFormElement
        this.thicknessTableBody = document.getElementById('thickness')!!.querySelector('tbody') as HTMLTableSectionElement
        this.codeOfferValue = this.form.querySelector('[data-bind="codeOfferValue"]')

        this.processInput = new ProcessInputField({
                valueField: this.form.querySelector('input[name="process"]')!,
                textField: this.form.querySelector('input[name="processName"]')!,
                change: (item) => {
                    if (item == null) {
                        this.refreshProcessLayers("", []);
                    } else {
                        this.refreshProcess(item.id);
                    }
                }
            }
        )

        this.thicknessTableBody.addEventListener('input', () => this.offerCoatingCode())
        this.thicknessTableBody.addEventListener('change', () => this.offerCoatingCode())
        this.offerCoatingCode();

        this.form.querySelector('button[data-bind="codeOfferSelect"]')?.addEventListener('click', () => this.offerCoatingCodeApply())
    }

    private refreshProcess(processId: number) {
        const section = document.getElementById('thicknessSection')!!
        section.classList.add('reloading-fade')

        fetchJsonForApiResponse(`/coatings/op/dynamicLayerNames?process=${processId}`)
            .then(json => this.refreshProcessLayers(json.codePattern, json.dynamicLayers))
            .catch(error => {
                toastFetchError(error)
                this.refreshProcessLayers("", [])
            })
            .then(() => section.classList.remove('reloading-fade'));
    }

    private refreshProcessLayers(codePattern: string, dynamicLayers: { position: string, coatingCode: string }[]) {
        const count = dynamicLayers.length;

        const currentRows = Array.from(this.thicknessTableBody.querySelectorAll<HTMLInputElement>('input[name$="].value"]'))
        const currentValues = currentRows.map(input => input.value)
        currentRows
            .map(input => input.closest('tr')!!)
            .forEach(tr => tr.remove());

        for (let i = 0; i < count; ++i) {
            this.addThicknessRow(dynamicLayers[i].position, currentValues[i], dynamicLayers[i].coatingCode);
        }

        document.getElementById('thicknessSection')!!.classList.toggle('hidden', dynamicLayers.length == 0);

        this.codeOfferValue?.setAttribute(CODE_PATTERN_ATTRIBUTE, codePattern)
        this.offerCoatingCode();
    }

    private addThicknessRow(name?: string, value?: string, coatingCode?: string) {
        name = coalesce(name, '');
        value = coalesce(value, '');
        coatingCode = coalesce(coatingCode, '');

        this.thicknessTableBody.appendChild(createHtmlElement('tr', {class: 'align-baseline'}, `
<td class="w-100">${h(name)}</td>
<td class="text-muted px-3" data-bind="coatingCode">${h(coatingCode)}</td>
<td><input lang="en" type="number" step="1" min="0" class="form-control form-control-sm" required name="thickness[-1].value" value="${h(value)}"></td>
        `))

        this.enumerateThicknessTable();
    }

    private visitThicknessTable(rowReceiver: (row: HTMLTableRowElement, index: number) => void) {
        let seed = 0;
        this.thicknessTableBody.querySelectorAll('tr').forEach(tr => {
            rowReceiver(tr, seed);
            ++seed;
        })
    }

    private enumerateThicknessTable() {
        const namePattern = /^thickness\[[-\d]+]/
        this.visitThicknessTable((tr, index) => {
            querySelectorAllWithName(tr, 'input', namePattern).forEach(named => {
                const oldName = named.getAttribute('name') as string
                const newName = oldName.replace(namePattern, `thickness[${index}]`);
                if (oldName != newName) {
                    named.setAttribute('name', newName);
                }
            })
        });
    }

    private offerCoatingCode() {
        const codeOfferBlock = this.form.querySelector<HTMLElement>('[data-bind="codeOffer"]')
        if (!codeOfferBlock || !this.codeOfferValue) return

        const thicknessList: (number | null)[] = []
        this.visitThicknessTable((tr) => {
            const thicknessElement = querySelectorWithName<HTMLInputElement>(tr, 'input', /\.value$/)
            const thickness = thicknessElement ? Number.parseInt(thicknessElement.value) : null;
            thicknessList.push(Number.isFinite(thickness) ? thickness : null)
        })

        const codeText = new CoatingCodePattern(this.codeOfferValue.getAttribute(CODE_PATTERN_ATTRIBUTE)).format(thicknessList)
        const codeInput = this.form.querySelector<HTMLInputElement>('input[name="code"]')

        if (!codeInput || codeText.length == 0 || codeInput.value == codeText) {
            return toggleVisibilityHidden(codeOfferBlock, true)
        }

        this.codeOfferValue.innerText = codeText;
        toggleVisibilityHidden(codeOfferBlock, false)
    }

    private offerCoatingCodeApply() {
        const codeInput = this.form.querySelector<HTMLInputElement>('input[name="code"]')
        if (!codeInput || !this.codeOfferValue) return

        codeInput.value = this.codeOfferValue.innerText
        toggleVisibilityHidden(this.form.querySelector<HTMLElement>('[data-bind="codeOffer"]'), true)
    }
}