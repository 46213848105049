import {Messages} from "../messages/Messages";
import {padLeft} from "./stringFunctions";

interface DurationMS {
    minutes: number
    seconds: number
}

interface Duration extends DurationMS {
    hours: number
}

interface DurationDirectional extends Duration {
    forward: boolean
}

interface DurationMSDirectional extends DurationMS {
    forward: boolean
}

export function msToDuration(ms: number): DurationDirectional {
    const forward = ms >= 0;
    let n = Math.ceil(Math.abs(ms) / 1000);

    let hours, minutes = 0, seconds = 0;

    if (n > 0) seconds = n % 60
    n = (n - seconds) / 60;

    if (n > 0) minutes = n % 60
    n = (n - minutes) / 60;

    hours = n;

    return {hours, minutes, seconds, forward};
}

export function msToDurationAround(ms: number, keep: number = 1): DurationDirectional {
    const duration = msToDuration(ms);
    if (Math.abs(duration.hours) > 1) {
        duration.minutes = 0
        duration.seconds = 0
    }
    if (Math.abs(duration.minutes) > 1) {
        duration.seconds = 0
    }
    return duration;
}

export function durationTextAround(messages: Messages, duration: Duration): string {
    const timings = [];
    if (duration.hours > 0) timings.push(messages.get('duration.hours', duration.hours));
    if (duration.minutes > 0) timings.push(messages.get('duration.minutes', duration.minutes));
    if (duration.seconds > 0) timings.push(messages.get('duration.seconds', duration.seconds));
    return timings.join(' ')
}

export function durationClock(duration: DurationDirectional): string {
    const timings = [];
    timings.push(padLeft(duration.hours.toString(), 2, '0'))
    timings.push(padLeft(duration.minutes.toString(), 2, '0'));
    timings.push(padLeft(duration.seconds.toString(), 2, '0'));
    return `${duration.forward ? '' : '-'}${timings.join(':')}`
}

export function durationToMs(duration: Duration): number {
    return duration.seconds * 1000 + duration.minutes * 60_000 * duration.hours * 3600_000
}

export function msToDurationMS(ms: number): DurationMSDirectional {
    const forward = ms >= 0;
    ms = Math.abs(ms);
    ms = Math.round(ms / 1000);

    const minutes = Math.floor(ms / 60);
    const seconds = ms - minutes * 60
    return {forward, minutes, seconds};
}