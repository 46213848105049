import {apiGetForAjaxResponse, apiPost} from "./lib/SecuredAjax";
import {buttonProgress} from "./lib/buttonProgress";
import {SchemaPlayer} from "./player/SchemaPlayer";
import {Messages} from "./messages/Messages";
import {AppConfig} from "./AppConfig";
import {TransportState} from "./dto/com.rico.sb2.entity.device";
import {ContainerState} from "./dto/com.rico.sb2.entity.detail";
import {PlayerData} from "./dto/com.rico.sb2.service.positions";
import {createHtmlElement} from "./lib/domFunctions";
import {PlayerDataAdapter} from "./PlayerDataAdapter";
import {SchemaState} from "./SchemaState";
import {ProcessControlService_Mode} from "./dto/com.rico.sb2.service";
import {Popover} from "bootstrap";
import {PopoverInstance} from "./lib/bootstrapPopover";
import {ContainerTypeNamer} from "./dto/com.rico.sb2.support";
import {toastFetchError} from "./lib/fetch";

const messages = new Messages();
const containerTypeNamer = new ContainerTypeNamer();

interface SchemaEditorParams {
	svg?: string
	playerData: PlayerData
}

class MessageFormTool {
	public readonly container: HTMLElement;
	public readonly form: HTMLFormElement;

	constructor(idOrHtml: string, html: boolean = false) {
		if (html) {
			this.container = createHtmlElement('div', {}, idOrHtml);
			this.form = this.container.querySelector('form')!!
		} else {
			this.container = document.getElementById(idOrHtml) as HTMLElement
			this.form = this.container.querySelector('form') as HTMLFormElement
		}
	}

	toggle(visible: boolean) {
		this.container.classList.toggle('hidden', !visible)
	}

	getSelect(name: string): HTMLSelectElement {
		return this.form.querySelector(`select[name="${name}"]`) as HTMLSelectElement
	}

	getSelectValue(name: string): string {
		return this.getSelect(name).value
	}

	getInput(name: string): HTMLInputElement {
		return this.form.querySelector(`input[name="${name}"]`) as HTMLInputElement
	}

	getInputValue(name: string): string {
		return this.getInput(name).value
	}

	getCheckbox(name: string): HTMLInputElement {
		return this.form.querySelector(`input[name="${name}"]`) as HTMLInputElement
	}

	getCheckboxValue(name: string): boolean {
		return this.getCheckbox(name).checked
	}
}

function numberOrNull(text: string): number | null {
	if (text == null || text.trim() == '') return null
	return parseFloat(text)
}

export class SchemaEditor {
	private readonly preview: HTMLElement
	private readonly localFileInput: HTMLInputElement

	private readonly sendUpdatePositionMessageForm: MessageFormTool
	private readonly sendUpdateTransportMessageForm: MessageFormTool
	private readonly sendUpdateContainerMessageForm: MessageFormTool

	private hasChanges = false
	private readonly playerData: PlayerDataAdapter
	private player: SchemaPlayer | null = null

	private playerSvg: string = ''
	private containerSvg: string = ''
	private containerCss: string = ''

	private schemaState = new SchemaState(ProcessControlService_Mode.AUTOMATIC);

	constructor({svg, playerData}: SchemaEditorParams) {
		this.preview = document.getElementById('preview')!!
		this.playerData = new PlayerDataAdapter(this.schemaState)
		this.playerData.reset(playerData, []);

		const positionIds = playerData.positions.map(i => i.id)
		setInterval(() => {
			positionIds.forEach(id => {
				this.playerData.onSchemePositionTelemetry({id, temp: Math.random() * 100, current: Math.random() * 100, voltage: Math.random() * 100})
			})
		}, 2000);

		this.sendUpdateTransportMessageForm = new MessageFormTool(`
                    <form>
                        <select name="id" class="form-select hidden"></select>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">State</label><div class="col-sm-6"><select name="state" class="form-select"></select></div></div>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">Position</label><div class="col-sm-6"><input class="form-control" type="number" name="position"></div></div>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">Target position</label><div class="col-sm-6"><input class="form-control" type="number" name="targetPosition"></div></div>
                        <button type="submit" class="btn btn-primary w-100">${messages.get("schemaEditor.messages.send")}</button>
                    </form>
        `, true);
		this.sendUpdatePositionMessageForm = new MessageFormTool(`
                    <form>
                        <select name="id" class="form-select hidden"></select>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">Time Left</label><div class="col-sm-6"><input class="form-control" type="number" name="timeLeft"></div></div>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label"></label>
                            <div class="col-sm-6">
                                <div class="form-check"><input class="form-check-input" type="checkbox" name="active" id="updatePositionMessage.active"><label class="form-check-label" for="updatePositionMessage.active">active</label></div>
                                <div class="form-check"><input class="form-check-input" type="checkbox" name="enabled" id="updatePositionMessage.enabled"><label class="form-check-label" for="updatePositionMessage.enabled">enabled</label></div>
                                <div class="form-check"><input class="form-check-input" type="checkbox" name="locked" id="updatePositionMessage.locked"><label class="form-check-label" for="updatePositionMessage.locked">locked</label></div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary w-100">${messages.get("schemaEditor.messages.send")}</button>
                    </form>`, true)
		this.sendUpdateContainerMessageForm = new MessageFormTool(`
                    <form>
                        <select name="id" class="form-select hidden"></select>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">Number</label><div class="col-sm-6"><input class="form-control" type="number" step="1" name="number"></div></div>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">State</label><div class="col-sm-6"><select name="state" class="form-select"></select></div></div>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">Position</label><div class="col-sm-6"><select name="position" class="form-select"></select></div></div>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">Transport</label><div class="col-sm-6"><select name="transport" class="form-select"></select></div></div>
                        <div class="row mb-3"><label class="col-sm-6 col-form-label">Progress</label><div class="col-sm-6"><input class="form-control" type="number" step="1" name="progress"></div></div>
                        <button type="submit" class="btn btn-primary w-100">${messages.get("schemaEditor.messages.send")}</button>
                    </form>`, true);

		this.bindPlayerForms()
		this.bindPlayerFormsHandlers()

		if (typeof svg === 'string') {
			this.playerSvg = svg
			this.containerSvg = playerData.containerSvg
			this.containerCss = playerData.containerCss
			this.displaySvg()
			this.clearChanges()
		}

		this.localFileInput = createHtmlElement('input', {'type': 'file', 'accept': '.svg', class: 'hidden', style: 'display:none'})
		this.localFileInput.addEventListener('change', () => {
			this.uploadSvgFile(this.localFileInput.files!![0]);
		})
		document.body.append(this.localFileInput);
	}

	private fireChanges() {
		this.hasChanges = true
		disableButton(['downloadSvg', 'saveSvg'], false);
		document.getElementById('saveNotice')!!.classList.toggle('hidden', false);
	}

	private clearChanges() {
		this.hasChanges = false;
		document.getElementById('saveNotice')!!.classList.toggle('hidden', true);
	}

	uploadSvg(_: HTMLButtonElement) {
		this.localFileInput.click();
	}

	private uploadSvgFile(file: File) {
		this.preview.classList.add('reloading-fade');

		let reader = new FileReader();
		reader.addEventListener('load', (e) => {
			this.playerSvg = (e.target!!.result as string) || ""
			this.displaySvg()
		});
		reader.readAsText(file);
	}

	generateSvg(button: HTMLButtonElement) {
		this.preview.classList.add('reloading-fade');
		const progress = buttonProgress(button);
		const wrapLine = (document.getElementById('wrapLine') as HTMLInputElement).checked;

		apiGetForAjaxResponse(`${AppConfig.CP}/schema/generate?wrapLine=${wrapLine}`)
			.then(value => {
				const result = value as { svg: string, playerData: PlayerData }

				this.playerData.reset(result.playerData, []);
				this.playerSvg = result.svg
				this.containerSvg = result.playerData.containerSvg
				this.containerCss = result.playerData.containerCss
				this.displaySvg();
				this.bindPlayerForms();
				progress.stop()
			})
			.catch(toastFetchError);
	}

	private displaySvg() {
		this.player = new SchemaPlayer(this.preview, this.playerSvg, this.containerSvg, this.containerCss, this.playerData, [], {
			getTransportPopover: (player: SchemaPlayer, id: number) => new TransportDemoPopover(this.sendUpdateTransportMessageForm, player, id),
			getContainerPopover: (player: SchemaPlayer, id: number) => new ContainerDemoPopover(this.sendUpdateContainerMessageForm, player, id),
			getPositionPopover: (player: SchemaPlayer, id: number) => new PositionDemoPopover(this.sendUpdatePositionMessageForm, player, id)
		})
		this.preview.classList.remove('reloading-fade');

		const file = new Blob([this.playerSvg], {type: 'application/xml'});

		window.URL = window.URL || window.webkitURL;
		const dlBtn = document.getElementById("downloadSvg")!!;
		dlBtn.setAttribute("href", window.URL.createObjectURL(file));
		dlBtn.setAttribute("download", "schema.svg");

		this.fireChanges();
	}

	saveSvg(button: HTMLButtonElement) {
		const progress = buttonProgress(button);

		apiPost(`${AppConfig.CP}/schema/save`, this.playerSvg).then(response => {
			if (response.status === 'ok') {
				this.clearChanges();
				progress.stop();
			}
		})
	}

	bindPlayerForms() {
		this.sendUpdatePositionMessageForm.getSelect('id').innerHTML = this.playerData.positions
			.sort((a, b) => a.id - b.id)
			.map(p => `<option value="${p.id}">${p.id}</option>`)
			.join('');

		this.sendUpdateTransportMessageForm.getSelect('id').innerHTML = this.playerData.transports
			.sort((a, b) => a.id - b.id)
			.map(p => `<option value="${p.id}">${p.type} ${p.id}</option>`)
			.join('');
		this.sendUpdateTransportMessageForm.getSelect('state').innerHTML = `<option value=""></option>` + Object.keys(TransportState)
			.map(s => `<option value="${s}">${s}</option>`)
			.join('')

		this.sendUpdateContainerMessageForm.getSelect('id').innerHTML = this.playerData.containers
			.sort((a, b) => a - b)
			.map(id => `<option value="${id}">${id}</option>`)
			.join('');
		this.sendUpdateContainerMessageForm.getSelect('transport').innerHTML = `<option value=""></option>` + this.playerData.transports
			.sort((a, b) => a.id - b.id)
			.map(p => `<option value="${p.id}">${p.type} ${p.id}</option>`)
			.join('');
		this.sendUpdateContainerMessageForm.getSelect('position').innerHTML = `<option value=""></option>` + this.playerData.positions
			.sort((a, b) => a.id - b.id)
			.map(p => `<option value="${p.id}">${p.id}</option>`)
			.join('');
		this.sendUpdateContainerMessageForm.getSelect('state').innerHTML = `<option value=""></option>` + Object.keys(ContainerState)
			.map(s => `<option value="${s}">${s}</option>`)
			.join('')
	}

	bindPlayerFormsHandlers() {
		const respondToSubmit = (e: any, form: MessageFormTool, handler: (form: MessageFormTool, playerData: PlayerDataAdapter) => void): boolean => {
			try {
				handler(form, this.playerData);
			} catch (err) {
				console.error(err)
				alert('Error!')
			}
			e.preventDefault();
			return false;
		}

		this.sendUpdatePositionMessageForm.form.onsubmit = e => {
			return respondToSubmit(e, this.sendUpdatePositionMessageForm, (form, playerData) => {
				playerData.onSchemePositionUpdate({
					_type: "P",
					id: parseInt(form.getSelectValue('id')),
					active: form.getCheckboxValue('active'),
					enabled: form.getCheckboxValue('enabled'),
					locked: form.getCheckboxValue('locked'),
					timeNow: new Date().getTime(),
					timeLeft: numberOrNull(form.getInputValue('timeLeft'))
				});
			})
		}

		this.sendUpdateTransportMessageForm.form.onsubmit = e => {
			return respondToSubmit(e, this.sendUpdateTransportMessageForm, (form, playerData) => {
				playerData.onSchemeTransportUpdate({
					_type: "T",
					id: parseInt(form.getSelectValue('id')),
					state: form.getSelectValue('state') == '' ? null : form.getSelectValue('state') as TransportState,
					position: numberOrNull(form.getInputValue('position')),
					targetPosition: numberOrNull(form.getInputValue('targetPosition'))
				});
			})
		}

		this.sendUpdateContainerMessageForm.form.onsubmit = e => {
			return respondToSubmit(e, this.sendUpdateContainerMessageForm, (form, playerData) => {
				const progress = numberOrNull(form.getInputValue('progress'))
				const progressTime = new Date().getTime()
				let progressStart = null, progressEnd = null
				if (progress != null) {
					const period = 120 * 1000;
					progressStart = progressTime - (progress / 100) * period
					progressEnd = progressTime + (1 - progress / 100) * period
				}

				playerData.onSchemeContainerUpdate({
					_type: "C",
					id: parseInt(form.getSelectValue('id')),
					number: parseInt(form.getInputValue('number')),
					owner: '',
					state: form.getSelectValue('state') == '' ? null : form.getSelectValue('state') as ContainerState,
					position: numberOrNull(form.getSelectValue('position')),
					transport: numberOrNull(form.getSelectValue('transport')),
					startProgramTime: progressStart, lastCancelTime:null, currentTime: progressTime, finishProgramTime: progressEnd,
					finishProgramPosition: null,
					lastProgramId: null, lastProgramState: null, lastProgramType: null, pendingPath: null, paIndex: null, processActions: null, overexposureTime: null
				});
			})
		}
	}
}

function disableButton(idList: string[], disabled: boolean) {
	idList.forEach(id => {
		const element = document.getElementById(id);
		if (!element) {
			return
		}
		if (element.tagName === 'A') {
			element.classList.toggle('disabled', disabled);
		}
		if (element.tagName === 'BUTTON') {
			if (disabled) {
				element.setAttribute('disabled', 'disabled');
			} else {
				element.removeAttribute('disabled');
			}
		}
	})
}

class ContainerDemoPopover implements PopoverInstance {
	private readonly form: MessageFormTool
	private readonly player: SchemaPlayer
	private readonly id: number

	constructor(form: MessageFormTool, player: SchemaPlayer, id: number) {
		this.form = form
		this.player = player
		this.id = id
	}

	title() {
		const container = this.player.getContainerState(this.id)
		if (container == null) return `${this.id}`

		const containerType = containerTypeNamer.name(container.data.type);
		return `${containerType} ${container.data.number}`

	}

	content() {
		this.form.getSelect('id').value = this.id.toString()

		const container = this.player.getContainerState(this.id)
		this.form.getInput('number').value = container != null && container.data.number != null ? container.data.number : ''
		this.form.getInput('progress').value = container != null && container.percent != null ? Math.round(container.percent).toString() : ''
		this.form.getSelect('state').value = container != null && container.data.state != null ? container.data.state : ''
		this.form.getSelect('position').value = container != null && container.data.position != null ? container.data.position.toString() : ''
		this.form.getSelect('transport').value = container != null && container.data.transport != null ? container.data.transport.toString() : ''
		return this.form.container
	}

	createPopover(target: Element) {
		return new Popover(target, {animation: false, sanitize: false, trigger: 'manual', placement: 'top', html: true, content: this.content(), title: this.title()});
	}
}

class PositionDemoPopover implements PopoverInstance {
	private readonly form: MessageFormTool
	private readonly player: SchemaPlayer
	private readonly id: number

	constructor(form: MessageFormTool, player: SchemaPlayer, id: number) {
		this.form = form
		this.player = player
		this.id = id
	}

	title() {
		return `Position ${this.id}`
	}

	content() {
		this.form.getSelect('id').value = this.id.toString()
		const state = this.player.getPositionState(this.id)
		this.form.getCheckbox('active').checked = state != null ? state.active : false
		this.form.getCheckbox('enabled').checked = state != null ? state.enabled : false
		this.form.getCheckbox('locked').checked = state != null ? state.locked : false
		this.form.getInput('timeLeft').value = state != null && state.timeLeft != null ? state.timeLeft.toString() : ''
		return this.form.container
	}

	createPopover(target: Element) {
		return new Popover(target, {animation: false, sanitize: false, trigger: 'manual', placement: 'top', html: true, content: this.content(), title: this.title()});
	}
}

class TransportDemoPopover implements PopoverInstance {
	private readonly form: MessageFormTool
	private readonly player: SchemaPlayer
	private readonly id: number

	constructor(form: MessageFormTool, player: SchemaPlayer, id: number) {
		this.form = form
		this.player = player
		this.id = id
	}

	title() {
		return `Transport ${this.id}`
	}

	content() {
		this.form.getSelect('id').value = this.id.toString()
		const state = this.player.getTransportState(this.id)
		this.form.getSelect('state').value = state != null && state.state != null ? state.state : ''
		this.form.getInput('position').value = state != null && state.position != null ? state.position.toString() : ''
		this.form.getInput('targetPosition').value = state != null && state.targetPosition != null ? state.targetPosition.toString() : ''
		return this.form.container
	}

	createPopover(target: Element) {
		return new Popover(target, {animation: false, sanitize: false, trigger: 'manual', placement: 'top', html: true, content: this.content(), title: this.title()});
	}
}