import {ContainerTypeInfo} from "./dto/com.rico.sb2.service.queue";

export const AppConfig = {
	CP: "",
	locale: "",

	roles: [],
	authorities: [],

	ContainerTypes: [] as ContainerTypeInfo[],
	ContainerTypeNamerConfig: {
		defaultName: '',
		names: {}
	},

	hideDashboardForm: {
		minWindowWidth: null,
		minWindowHeight: null,
	},

	showSendToStorage: false,

	trolleyLine: false,
	
	suspensionTakeDelayFactor: 0,

	logged() {
		return this.roles.length > 0
	}
}