export class PeriodicTask {
    private readonly func: Function;
    private readonly periodMs: number;

    private enabled_ = true
    private nextTimeout: number = 0;

    constructor(func: Function, {periodMs = 1000, enabled = false}) {
        this.func = func;
        this.periodMs = periodMs;
        this.enabled_ = enabled
        this.schedule();
    }

    public get enabled() {
        return this.enabled_;
    }

    public set enabled(v: boolean) {
        if (v == this.enabled_) return

        this.enabled_ = v;
        if (v) {
            this.schedule();
        } else if (this.nextTimeout > 0) {
            clearTimeout(this.nextTimeout)
            this.nextTimeout = 0;
        }
    }

    private schedule() {
        if (!this.enabled || this.nextTimeout > 0) return
        const time = new Date().getTime();
        const gap = Math.max(0, this.periodMs - time % this.periodMs);
        this.nextTimeout = window.setTimeout(this.run.bind(this), gap);
    }

    private run() {
        if (!this.enabled) return

        this.nextTimeout = 0
        try {
            this.func();
        } finally {
            this.schedule();
        }
    }
}