import {ContainerState} from "../dto/com.rico.sb2.entity.detail";
import {ContainerDataAdapter} from "./ContainerDataSupplier";

const AliveListWaitingStates = new Set([ContainerState.PLANNED, ContainerState.LOADING])
const AliveListWorkingStates = new Set([ContainerState.PROCESSING, ContainerState.WAITING, ContainerState.UNLOADING, ContainerState.BLOCKED])

export function isAliveListWaiting(containerDataAdapter: ContainerDataAdapter) {
    const state = containerDataAdapter.state
    return state != null && AliveListWaitingStates.has(state)
}

export function isAliveListWorking(containerDataAdapter: ContainerDataAdapter) {
    const state = containerDataAdapter.state
    return state != null && AliveListWorkingStates.has(state)
}