import {bootstrapModal, bootstrapPrompt} from "./lib/bootstrapModal";
import {apiPostForAjaxResponse} from "./lib/SecuredAjax";
import {AppConfig} from "./AppConfig";
import {toastFetchError} from "./lib/fetch";
import {BindingList} from "./lib/domFunctions";
import {Messages} from "./messages/Messages";
import {buttonProgress} from "./lib/buttonProgress";
import {PeriodicTask} from "./lib/PeriodicTask";

const messages = new Messages();

function waitSiteStart(content: HTMLElement) {
	const modalTitle = content.querySelector('.modal-title') as HTMLElement;
	modalTitle.innerText = 'Сайт перезапускается';
	const modalBody = content.querySelector('.modal-body') as HTMLElement;
	modalBody.innerText = 'Ждем старта сайта...';

	let waitPending = false;
	new PeriodicTask(function () {
		if (waitPending) return;

		waitPending = true;
		fetch('/')
			.then(() => document.location.href = '/')
			.finally(() => waitPending = false);
	}, {periodMs: 2000, enabled: true});
}

export class BackupPage {
	constructor() {
		const binding = new BindingList();
		binding.collect(document.documentElement);

		binding.update('createBackup', node => node.addEventListener('click', this.createBackup.bind(this)));
		binding.update('deleteBackup', node => node.addEventListener('click', e => this.deleteBackup((e.target as HTMLButtonElement).getAttribute('data-arg') || "")));
		binding.update('restoreBackup', node => node.addEventListener('click', e => this.restoreBackup((e.target as HTMLButtonElement).getAttribute('data-arg') || "")));
	}

	private async doBackupPromptAsync(): Promise<string | null> {
		return new Promise((resolve, reject) => {
			const {content, modal, buttonOk} = bootstrapModal({
				title: 'Создаем резервную копию',
				body: `
<label class="col-form-label">Можно добавить комментарий</label>
<textarea class="form-control" autofocus rows="3"></textarea>
                  `,
				buttonOk: messages.get('button.create'),
			})

			const commentTextarea = content.querySelector('textarea') as HTMLTextAreaElement

			content.addEventListener('shown.bs.modal', () => {
				commentTextarea.focus()
			});

			buttonOk?.addEventListener('click', () => {
				const commentText = commentTextarea.value ?? '';
				modal.hide();
				resolve(commentText)
			})

			modal.show();
		});
	}

	async createBackup() {
		const commentText = await this.doBackupPromptAsync();
		if (commentText === null) {
			return;
		}

		const {modal} = bootstrapModal({
			title: 'Создаем резервную копию', body: `Если информации много, то создание файла может занять некоторое время`,
			closeable: false,
			buttonOk: null, buttonCancel: null
		});

		modal.show();

		function progressModalHide() {
			modal.hide();
		}

		apiPostForAjaxResponse(`${AppConfig.CP}/backup/create`, {"comment": commentText})
			.then(() => {
				document.location.reload()
			})
			.catch(error => {
				progressModalHide();
				toastFetchError(error);
			});
	}

	private async doRestorePromptAsync(fileName: string): Promise<boolean> {
		return bootstrapPrompt({
			title: 'Восстановить резервную копию?',
			body: `
<p>База данных будет полностью очищена. Для восстановления будет использован файл:</p>
<div class="text-center"><strong class="text-nowrap">${fileName}</strong></div>`,
			buttonOk: messages.get('boolean.yes'), buttonOkClass: 'btn-danger'
		});
	}

	async restoreBackup(fileName: string) {
		if (!await this.doRestorePromptAsync(fileName)) {
			return;
		}

		const {modal, content} = bootstrapModal({
			title: 'Планируем перезагрузку', body: `Восстановление базы данных может занять некоторое время. Сервер будет перезапущен.`,
			closeable: false,
			buttonOk: null, buttonCancel: null
		});

		modal.show();

		function progressModalHide() {
			modal.hide();
		}

		try {
			await apiPostForAjaxResponse(`${AppConfig.CP}/backup/restoreSchedule`, {fileName: fileName});
		} catch (error) {
			progressModalHide();
			toastFetchError(error);
			return;
		}

		try {
			await apiPostForAjaxResponse(`${AppConfig.CP}/backup/restoreReboot`);
		} catch (error) {
			if (error instanceof TypeError || error === 502) {
				waitSiteStart(content);
			} else {
				progressModalHide();
				toastFetchError(error);
			}
		}
	}

	deleteBackup(fileName: string) {
		const {modal, buttonOk} = bootstrapModal({
			title: messages.get('BackupService.deleteConfirm'),
			body: '',
			buttonOk: messages.get('boolean.yes'),
			buttonOkClass: 'btn-danger',
			buttonCancel: messages.get('boolean.no')
		});
		modal.show();

		const ok = buttonOk!!;

		ok.addEventListener('click', () => {
			const progress = buttonProgress(ok);

			apiPostForAjaxResponse(`${AppConfig.CP}/backup/delete`, {fileName: fileName})
				.then(() => document.location.reload())
				.catch(toastFetchError)
				.then(() => {
					progress.stop();
					modal.hide();
				});
		})
	}
}
