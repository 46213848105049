import {ListenerList} from "./lib/ListenerList";
import {TransportType} from "./dto/com.rico.sb2.entity.device";
import {ProcessControlService_Mode} from "./dto/com.rico.sb2.service";
import {WebSocketClient} from "./WebSocketClient";

export interface ServiceModeListener {
	onServiceModeChanged(m: ProcessControlService_Mode): void
}

class ServiceModeListeners extends ListenerList<ServiceModeListener> implements ServiceModeListener {
	onServiceModeChanged(m: ProcessControlService_Mode): void {
		this.visit(l => l.onServiceModeChanged(m))
	}
}

export class SchemaState {
	private readonly positionNames: { [key: number]: string } = {}
	private readonly positionShortNames: { [key: number]: string } = {}
	private readonly positionTypes = new Map<number, number>();
	private readonly positionForLoading = new Set<number>()
	private readonly positionForUnloading = new Set<number>()
	private readonly transportNames: { [key: number]: string } = {}

	public readonly modeListeners = new ServiceModeListeners()

	private mode_: ProcessControlService_Mode | null = null

	constructor(mode: ProcessControlService_Mode | null) {
		this.mode_ = mode
	}

	get mode(): ProcessControlService_Mode | null {
		return this.mode_
	}

	set mode(mode: ProcessControlService_Mode | null) {
		this.mode_ = mode;
		if (mode) {
			this.modeListeners.onServiceModeChanged(mode);
		}
	}

	setPositionNames(list: { id: number, type: number, line: number, label: string, shortTitle: string }[]) {
		if (!list) return
		list.forEach(position => this.positionNames[position.id] = position.label)
		list.forEach(position => {
			if (position.shortTitle != null) {
				const cleaned = position.shortTitle
					.replace(/[\r\n]+/, ' ')
					.replace(/\s+/, ' ')
					.trim()
				this.positionShortNames[position.id] = cleaned;
			}
		})

		this.positionTypes.clear();
		list.forEach(position => this.positionTypes.set(position.id, position.type));
	}

	setTransportNames(list: { id: number, type: TransportType | null, number: any }[]) {
		if (!list) return
		list.forEach(transport => {
			switch (transport.type) {
				case TransportType.AO:
					this.transportNames[transport.id] = `АО${transport.number}`;
					break
				case TransportType.TRANSFER:
					this.transportNames[transport.id] = `T${transport.number}`;
					break
				default:
					this.transportNames[transport.id] = `U${transport.number}`;
					break
			}
		})
	}

	setPtLoading(pt: number | null) {
		this.positionForLoading.clear();
		Array.from(this.positionTypes.entries())
			.filter(e => pt != null && e[1] == pt)
			.forEach(e => this.positionForLoading.add(e[0]));
	}

	setPtUnloading(pt: number | null) {
		this.positionForUnloading.clear();
		Array.from(this.positionTypes.entries())
			.filter(e => pt != null && e[1] == pt)
			.forEach(e => this.positionForUnloading.add(e[0]));
	}

	getPositionName(id: number | null): string | null {
		if (id && id in this.positionNames) {
			return this.positionNames[id] || null;
		}
		return null
	}

	getPositionShortName(id: number | null): string | null {
		if (id && id in this.positionShortNames) {
			return this.positionShortNames[id] || null;
		}
		return this.getPositionName(id);
	}

	getTransportName(id: number | null): string | null {
		if (id && id in this.transportNames) {
			return this.transportNames[id] || null;
		}
		return null
	}

	subscribeToUpdates(ws: WebSocketClient) {
		ws.subscribe('/topic/status', m => this.mode = m.mode)
	}

	isLoadingPosition(id: number): boolean {
		return this.positionForLoading.has(id);
	}

	isUnloadingPosition(id: number): boolean {
		return this.positionForUnloading.has(id);
	}

	hasMode(...modes: ProcessControlService_Mode[]): boolean {
		return modes && modes.some(e => e == this.mode)
	}
}