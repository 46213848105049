import {PopoverInstance} from "../lib/bootstrapPopover";
import {BindingList, createAnchorAndClick, createHtmlElementFromHtml} from "../lib/domFunctions";
import {Messages} from "../messages/Messages";
import {AppBus, AppEvents} from "../AppBus";
import {Popover} from "bootstrap";
import {AppConfig} from "../AppConfig";
import {ContainerState} from "../dto/com.rico.sb2.entity.detail";
import {ContainerListItem} from "../dto/com.rico.sb2.service.queue";
import {CloneContainer} from "../modals/CloneContainer";

const messages = new Messages();

export class FinishedClickMenu implements PopoverInstance {
    private readonly data: ContainerListItem

    private readonly element: HTMLElement
    private readonly bindings = new BindingList()

    constructor(data: ContainerListItem) {
        this.data = data

        this.element = createHtmlElementFromHtml(`
<div>
    <div class="text-center px-3 mt-3">
        <button type="button" class="text-nowrap d-block w-100 btn btn-secondary btn-sm mb-3 me-1"  data-bind="doView">${messages.get('ContainerListItem.clickMenu.doView')}</button>
        <button type="button" class="text-nowrap d-block w-100 btn btn-secondary btn-sm mb-3 me-1" data-bind="doPrint">${messages.get('ContainerListItem.contextMenu.doPrint')}</button>
        <button type="button" class="text-nowrap d-block w-100 btn btn-secondary btn-sm mb-3 me-1" data-bind="doReport">${messages.get('ContainerListItem.contextMenu.doReport')}</button>
        <button type="button" class="text-nowrap d-block w-100 btn btn-secondary btn-sm mb-3 me-1" data-bind="doClone">${messages.get('ContainerListItem.contextMenu.doClone')}</button>
    </div>
</div>
`)
        this.bindings.collect(this.element)

        this.bindings.update('doView', node => node.addEventListener('click', this.doView.bind(this)));
        this.bindings.update('doPrint', node => node.addEventListener('click', this.doPrint.bind(this)));
        this.bindings.update('doClone', node => node.addEventListener('click', this.doClone.bind(this)));
        this.bindings.toggle('doClone', this.data.state != ContainerState.EMPTY && AppConfig.logged())
        this.bindings.update('doReport', node => node.addEventListener('click', this.doReport.bind(this)));
        this.bindings.toggle('doReport', this.data.reportStatus != null);
    }

    createPopover(onElement: Element, popoverContainer: Element = document.body) {
        return new Popover(onElement, {
            animation: false, sanitize: false, trigger: 'manual', placement: 'top', html: true, content: this.element, container: popoverContainer,
            template: `<div class="popover dashboard-popover" role="popover"><div class="popover-arrow"></div><div class="popover-body p-0"></div></div>`
        });
    }

    hidePopover(): void {
    }

    doView() {
        AppBus.fire(AppEvents.VIEW_CONTAINER, this.data.id);
    }

    doPrint() {
        AppBus.fire(AppEvents.PRINT_CONTAINER, this.data.id);
    }

    doReport() {
        createAnchorAndClick(`${AppConfig.CP}/reports/${this.data.id}`, true)
    }

    doClone() {
        new CloneContainer(this.data.id).showModal()
    }
}