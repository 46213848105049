export interface Page<T> {
    number: number
    size: number,
    totalPages: number
    totalElements: number
    numberOfElements: number
    content: T[]
}

export function emptyPage<T>(): Page<T> {
    return {size: 10, number: 0, totalPages: 0, totalElements: 0, numberOfElements: 0, content: []}
}