export function lastArg(...args: any[]) {
    return args[args.length - 1];
}

export function hasDuplicateInSortedArray<T>(array: T[]): boolean {
    for (let i = 1; i < array.length; ++i) {
        if (array[i - 1] === array[i]) {
            return true;
        }
    }
    return false;
}

export function sortNumberAsc(a: number, b: number): number {
    return a - b;
}

export function sortNumberDesc(a: number, b: number): number {
    return b - a;
}