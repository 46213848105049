import {PlayerData_Position} from "../dto/com.rico.sb2.service.positions";
import {UpdatePositionMessage} from "../dto/com.rico.sb2.message";
import {ProcessControlService_Mode, TelemetryMessageProcessor_BathTelemetry} from "../dto/com.rico.sb2.service";
import {coalesce} from "../lib/coalesce";
import {currentToString, temperatureToString, voltageToString} from "../lib/formatTool";

export interface PositionDataSupplier {
    get data(): PlayerData_Position

    get update(): UpdatePositionMessage | null

    get telemetry(): TelemetryMessageProcessor_BathTelemetry | null

    get serviceMode(): ProcessControlService_Mode | null

    getPositionTitle(pos: number | null): string

    get loadingPosition(): boolean

    get unloadingPosition(): boolean
}


export class PositionDataAdapter {
    private readonly supplier: PositionDataSupplier

    constructor(supplier: PositionDataSupplier) {
        this.supplier = supplier
    }

    get serviceMode(): ProcessControlService_Mode | null {
        return this.supplier.serviceMode
    }

    get id() {
        return this.supplier.data.id
    }

    get line() {
        return this.supplier.data.line
    }

    get name() {
        return this.supplier.data.name
    }

    get enabled() {
        const update = this.supplier.update;
        if (update == null) {
            return this.supplier.data.enabled;
        }
        return coalesce(update.enabled, this.supplier.data.enabled);
    }

    get locked(): boolean {
        const update = this.supplier.update;
        if (update != null) {
            return update.locked || false;
        }
        return this.supplier.data.locked;
    }

    set enabled(v: boolean) {
        const update = this.supplier.update;
        if (update != null) {
            update.enabled = v;
        } else {
            this.supplier.data.enabled = v
        }
    }

    get maxCurrent(): number | null {
        return this.supplier.data.maxCurrent
    }

    get temperatureString(): string {
        const telemetry = this.supplier.telemetry
        if (!telemetry || telemetry.temp == null) return ''
        return temperatureToString(telemetry.temp);
    }

    get currentString(): string {
        const telemetry = this.supplier.telemetry
        if (!telemetry || telemetry.current == null) return ''
        return currentToString(telemetry.current);
    }

    get voltageString(): string {
        const telemetry = this.supplier.telemetry
        if (!telemetry || telemetry.voltage == null) return ''
        return voltageToString(telemetry.voltage);
    }

    get loadingPosition(): boolean {
        return this.supplier.loadingPosition;
    }

    get unloadingPosition(): boolean {
        return this.supplier.unloadingPosition;
    }
}