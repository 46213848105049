import {createHtmlElement} from "./lib/domFunctions";

function setDependentCheckboxes(parentName: string, dependentName: string) {
	const parent = document.querySelector(`input[name=${parentName}]`) as HTMLInputElement;
	const dependent = document.querySelector(`input[name=${dependentName}]`) as HTMLInputElement;
	const dependentLabel = document.querySelector(`label[for=${dependentName}]`) as HTMLElement;
	const dependentHidden = createHtmlElement('input', {type: 'hidden', name: dependentName})

	function disableHandler(disabled: boolean) {
		dependent.disabled = disabled;
		dependentLabel.classList.toggle('text-muted', disabled);

		if (disabled && dependent.checked) {
			dependentHidden.value = 'on';
			parent.parentElement?.append(dependentHidden)
		} else {
			dependentHidden.remove()
		}
	}

	parent.addEventListener('change', () => {
		disableHandler(!parent.checked);
	});

	disableHandler(!parent.checked)
}

export class PropertiesEditPage {
	constructor() {
		setDependentCheckboxes('emptyContainerReturn', 'automaticEmptyContainerReturn');
		setDependentCheckboxes('programGeneratorUsePreviousProgram', 'programGeneratorFixNonProcessPositionOnProgramRebuilding');
	}
}