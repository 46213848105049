import {bootstrapModal, BootstrapModalOptions} from "../lib/bootstrapModal";
import {Messages} from "../messages/Messages";
import {apiPost} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {isAjaxResponseOk, toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";
import {ContainerState} from "../dto/com.rico.sb2.entity.detail";

const messages = new Messages();
const undefinedString: string | undefined = undefined;

export class CancelContainer {
	private readonly containerId: number
	private readonly containerState: ContainerState
	private readonly titleCode: string
	private readonly buttonTextCode?: string
	private readonly buttonOkClass?: string

	constructor(containerId: number, containerState: ContainerState, {
					titleCode = 'CancelContainer.cancelTitle',
					buttonTextCode = undefinedString,
					buttonOkClass = undefinedString
				} = {}
	) {
		this.containerId = containerId
		this.containerState = containerState
		this.titleCode = titleCode
		this.buttonTextCode = buttonTextCode
		this.buttonOkClass = buttonOkClass;
	}

	private renderBody() {
		const messageCode = `CancelContainer.confirmBody.${this.containerState}`;
		return `<div class="text-center">${messages.get(messageCode)}</div>`;
	}

	execute(): Promise<ContainerState> {
		return new Promise((resolve, reject) => {
			apiPost(`${AppConfig.CP}/op/cancelContainer`, {container: this.containerId})
				.then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res.message))
				.then(newState => {
					showToastInfo(messages.get('CancelContainer.completeNotice'))
					resolve(newState)
				})
				.catch(error => {
					toastFetchError(error);
					reject();
				})
		})
	}

	showModal(completer?: Function) {
		const modalOptions: BootstrapModalOptions = {
			title: messages.get(this.titleCode, this.containerId),
			body: this.renderBody(),
			buttonOk: messages.get(`CancelContainer.confirmButton.${this.containerState}`),
		}
		if (this.buttonOkClass) modalOptions.buttonOkClass = this.buttonOkClass;
		if (this.buttonTextCode) modalOptions.buttonOk = messages.get(this.buttonTextCode);

		const {modal, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal(modalOptions);
		modal.show();

		const ok = buttonOk!!;

		ok.addEventListener('click', () => {
			buttonOkClickStart();

			this.execute()
				.then(newState => {
					modal.hide()
					if (completer) completer(newState)
				})
				.catch(buttonOkClickFinish);
		})

	}
}