import {apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";
import {bootstrapModal} from "../lib/bootstrapModal";
import {Messages} from "../messages/Messages";

const messages = new Messages();

export class CloneProcess {
    private readonly processId: number

    constructor(processId: number) {
        this.processId = processId
    }

    private execute(): Promise<number> {
        return new Promise((resolve, reject) => {
            apiPostForAjaxResponse(`${AppConfig.CP}/processes/clone`, {id: this.processId})
                .then(next => {
                    if (next) {
                        showToastInfo(messages.get('CloneProcess.completeNotice'))
                        resolve(next);
                    } else {
                        reject();
                    }
                })
                .catch(error => {
                    toastFetchError(error);
                    reject();
                })
        })
    }

    showModal(completer?: Function) {
        const {modal, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
            title: messages.get(`CloneProcess.confirmNotice`),
            body: `<div class="text-center">${messages.get('CloneProcess.confirmBody')}</div>`,
            buttonOk: messages.get('boolean.yes')
        });
        modal.show();

        const ok = buttonOk!!;

        ok.addEventListener('click', () => {
            buttonOkClickStart();

            this.execute()
                .then(newState => {
                    modal.hide()
                    if (completer) completer(newState)
                })
                .catch(buttonOkClickFinish)
        })
    }
}