import {noop} from "../lib/noop";
import {SinglePopover} from "../lib/bootstrapPopover";

export class SvgView {
    readonly view: SVGElement | null
    readonly width: number = 0
    readonly height: number = 0
    x: number = 0
    y: number = 0

    onClick: () => void = noop

    constructor(view: SVGElement | null) {
        this.view = view

        function getAttributeNumber(view: SVGElement, attr: string, remove: boolean = false) {
            const n = parseFloat(view.getAttribute(attr) as string)
            if (remove) view.removeAttribute(attr)
            return n;
        }

        if (view != null) {
            this.x = getAttributeNumber(view, 'x', true)
            this.y = getAttributeNumber(view, 'y', true)
            this.width = getAttributeNumber(view, 'width')
            this.height = getAttributeNumber(view, 'height')

            if (view.hasAttribute("transform")) {
                const match = /translate\((.+),(.+)\)/.exec(view.getAttribute("transform")!!);
                if (match) {
                    this.x = parseFloat(match[1]);
                    this.y = parseFloat(match[2]);
                }
            }

            view.addEventListener('click', () => this.onClick())
            view.style.cursor = 'pointer'
        }

        this.updateLocation();
    }

    get centerX() {
        return Math.round(this.x + this.width / 2);
    }

    get centerY() {
        return Math.round(this.y + this.height / 2);
    }

    toX(x: number) {
        this.x = x
        this.updateLocation()
    }

    toCenterX(centerX: number) {
        const offset = this.centerX - this.x;
        this.x = centerX - offset;
        this.updateLocation()
    }

    toY(y: number) {
        this.y = y
        this.updateLocation()
    }

    bind(key: string, value: string) {
        this.bindWith(key, svg => svg.textContent = value)
    }

    bindWith(key: string, consumer: (svg: SVGElement) => void) {
        this.view?.querySelectorAll<SVGElement>(`[data-bind=${key}]`).forEach(consumer);
    }

    updateLocation() {
        if (this.view != null) {
            this.view.setAttribute("transform", `translate(${this.x}, ${this.y})`)
        }
        SinglePopover.updateForElement(this.view)
    }
}