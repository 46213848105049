import {jQuery as $} from './boot';
import {escapeHTML} from "./lib/escapeHTML";
import {Messages} from "./messages/Messages";
import {fetchJson, toastFetchError} from "./lib/fetch";
import {AutoComplete} from "./lib/bootstrap-autocomplete/main";
import {AppConfig} from "./AppConfig";
import {createHtmlElement} from "./lib/domFunctions";
import {bootstrapModal} from "./lib/bootstrapModal";
import {buttonProgress} from "./lib/buttonProgress";
import {apiPostForAjaxResponse} from "./lib/SecuredAjax";

const messages = new Messages();

function coatingThicknessLabel(t: any) {
    if (typeof t.thickness === 'string' && t.thickness.length > 0) {
        return `${t.thickness}`
    }
    return ''
}

function coatingProcessLabel(t: any) {
    if (typeof t.process === 'string' && t.process.length > 0) {
        return `${t.process} ${t.processName}`
    }
    return ''
}

export class DetailEditPage {
    private readonly id: number | null

    private readonly coatingSearch: HTMLInputElement;
    private readonly coatingAdd: HTMLButtonElement;
    private readonly coatingTableBody: HTMLTableSectionElement;

    constructor(id?: number) {
        this.id = id === undefined ? null : id

        this.coatingTableBody = document.getElementById('coatingTable')!!.querySelector('tbody') as HTMLTableSectionElement

        this.coatingAdd = document.getElementById('coatingAdd') as HTMLButtonElement;
        this.coatingSearch = document.getElementById('coatingSearch') as HTMLInputElement;

        this.updateCoatingAdd();
        this.coatingSearch.addEventListener('input', () => this.updateCoatingAdd())

        $(this.coatingSearch)
            .autoComplete({
                bootstrapVersion: '4',
                autoSelect: true,
                preventEnter: true,
                minLength: 1,
                noResultsText: messages.get('detail.form.coatingTable.searchEmpty'),
                resolverSettings: {
                    requestThrottling: 500
                },
                events: {
                    search: (qry: string, callback: Function) => {
                        function itemFactory(t: any) {
                            return {
                                value: t.id,
                                data: t,
                                text: `${t.code} ${coatingThicknessLabel(t)} ${coatingProcessLabel(t)}`,
                                html: `${escapeHTML(t.code)} <span class="text-muted">${escapeHTML(coatingThicknessLabel(t))}</span> ${escapeHTML(coatingProcessLabel(t))}`
                            }
                        }

                        fetchJson(`/coatings/listPage?search=${encodeURIComponent(qry)}&offset=0&limit=10`)
                            .then(json => callback(json.content.map(itemFactory)))
                            .catch(() => callback([]));
                    }
                }
            })
            .on('autocomplete.select', (evt: Event, item: any) => {
                this.addCoatingRowData(item.value, item.data);
            })
            .on('autocomplete.freevalue', (evt: Event, value: any) => {
                value = value === undefined || value === null ? '' : value.trim();

                const autoComplete = $(evt.target!!).data(AutoComplete.NAME);
                const lastItems: any[] = autoComplete._dd && autoComplete._dd.items ? autoComplete._dd.items : [];
                const lastExact = lastItems.filter(item => item.data.code === value)[0];
                if (lastExact) {
                    this.addCoatingRowData(lastExact.value, lastExact.data);
                } else {
                    this.addCoatingRowData(-1, {code: value});
                }
            });
    }

    removeCoatingRow(button: HTMLButtonElement) {
        button.closest('tr')!!.remove();
        this.enumerateCoatingTable();
    }

    addCoatingRow() {
        this.addCoatingRowData('', {code: this.coatingSearch.value.trim()});
    }

    addCoatingRowData(id: any, data: { code: string, thickness?: string, process?: string }) {
        if (data.code.trim().length > 0) {
            id = id || -1;
            const code = data.code.trim();

            let exists = false;
            for (const coatingIdInput of Array.from(this.coatingTableBody.querySelectorAll<HTMLInputElement>('input[name$="].id"]'))) {
                if (id > 0 && id == parseInt(coatingIdInput.value)) {
                    exists = true;
                    alert(messages.get('detail.form.coatingTable.addDuplicate'));
                    break;
                }
            }

            if (!exists) {
                const thickness = escapeHTML(coatingThicknessLabel(data));
                const process = escapeHTML(coatingProcessLabel(data));
                const codeTitle = id == -1
                    ? `<span>${escapeHTML(code)}</span><span class="badge rounded-pill text-bg-primary ms-2">${messages.get('new')}</span>`
                    : `<a href="${AppConfig.CP}/coatings/${id}/edit" target="_blank">${escapeHTML(code)}</a>`;

                this.coatingTableBody.append(createHtmlElement('tr', {}, `
                            <td class="text-nowrap">
                                <input type="hidden" name="coatings[-1].id" value="${id}">
                                <input type="hidden" name="coatings[-1].code" value="${escapeHTML(code)}">
                                <input type="hidden" name="coatings[-1].thickness" value="${thickness}">
                                ${codeTitle}
                                <span class="text-muted">${thickness}</span>
                            </td>
                            <td class="">
                                <input type="hidden" name="coatings[-1].process" value="${process}">
                                <span>${process}</span>
                            </td>
                            <td class="text-nowrap w-0">
                                <button class="btn btn-secondary btn-sm" type="button" onclick="controller.removeCoatingRow(this)"><i class="fal fa-minus me-2"></i>${messages.get('button.delete')}</button>
                            </td>
                `));
                this.enumerateCoatingTable();
            }
        }
        this.clearCoatingSearch();
    }

    private clearCoatingSearch() {
        this.coatingSearch.value = '';
        $(this.coatingSearch).autoComplete('clear');
        this.updateCoatingAdd();
    }

    private updateCoatingAdd() {
        if (this.coatingSearch.value.trim().length > 0) {
            this.coatingAdd.removeAttribute('disabled');
        } else {
            this.coatingAdd.setAttribute('disabled', 'disabled');
        }
    }

    private enumerateCoatingTable() {
        let seed = 0;
        const namePattern = /^coatings\[[-\d]+]/
        this.coatingTableBody.querySelectorAll('tr').forEach(tr => {
            tr.querySelectorAll('[name]').forEach(named => {
                const oldName = named.getAttribute('name') as string
                const newName = oldName.replace(namePattern, `coatings[${seed}]`);
                if (oldName != newName) {
                    named.setAttribute('name', newName);
                }
            });
            ++seed;
        })
    }

    delete() {
        const {modal, buttonOk} = bootstrapModal({
            title: messages.get('detail.form.deleteConfirm'),
            body: '',
            buttonOk: messages.get('boolean.yes'),
            buttonOkClass: 'btn-danger',
            buttonCancel: messages.get('boolean.no')
        });
        modal.show();

        const ok = buttonOk!!;

        ok.addEventListener('click', () => {
            const progress = buttonProgress(ok);

            apiPostForAjaxResponse(`${AppConfig.CP}/details/${this.id}/delete`)
                .then(() => document.location.href = `${AppConfig.CP}/details`)
                .catch(toastFetchError)
                .then(() => {
                    progress.stop();
                    modal.hide();
                });
        })
    }
}