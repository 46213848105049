import {PlayerData_ContainerInfo, PlayerData_DetailInfo} from "../dto/com.rico.sb2.service.positions";
import {UpdateContainerMessage} from "../dto/com.rico.sb2.message";
import {rem100} from "../lib/rem100";
import {ContainerState, ContainerType} from "../dto/com.rico.sb2.entity.detail";
import {ProcessControlService_Mode, TelemetryMessageProcessor_BathTelemetry} from "../dto/com.rico.sb2.service";
import {coalesce} from "../lib/coalesce";
import {durationTextAround, msToDuration} from "../lib/timeFunctions";
import {Messages} from "../messages/Messages";
import {padLeft} from "../lib/stringFunctions";
import {Program_State} from "../dto/com.rico.sb2.entity.program";
import {currentToString, temperatureToString, voltageToString} from "../lib/formatTool";
import {moment} from '../boot'

const messages = new Messages();

export interface ContainerDataSupplier {
    get data(): PlayerData_ContainerInfo

    get update(): UpdateContainerMessage | null

    get serviceMode(): ProcessControlService_Mode | null

    getPositionTitle(pos: number | null): string

    getPositionShortTitle(pos: number | null): string

    getPositionTelemetry(pos: number | null): TelemetryMessageProcessor_BathTelemetry | null

    isLoadingPosition(pos: number | null): boolean
}

export class ContainerDataAdapter {
    private readonly supplier: ContainerDataSupplier

    constructor(supplier: ContainerDataSupplier) {
        this.supplier = supplier
    }

    get serviceMode(): ProcessControlService_Mode | null {
        return this.supplier.serviceMode
    }

    get atLoadingPosition(): boolean {
        return this.supplier.isLoadingPosition(this.position);
    }

    get id() {
        return this.supplier.data.id
    }

    get number() {
        const text = rem100(this.supplier.data.id)
        return text.length == 0 ? text : padLeft(text, 2, '0');
    }

    get type(): ContainerType {
        return coalesce(this.supplier.data.type, ContainerType.NOT_DEFINED)
    }

    get state(): ContainerState | null {
        if (this.supplier.update) return this.supplier.update.state
        return this.supplier.data.state
    }

    stateString(messages: Messages): string {
        return this.state == null ? `` : messages.get(`container.state.${this.state}`);
    }

    get position(): number | null {
        if (this.supplier.update) return this.supplier.update.position
        return this.supplier.data.position
    }

    get transport(): number | null {
        if (this.supplier.update) return this.supplier.update.transport
        return null
    }

    get programProcess(): string {
        return coalesce(this.supplier.data.programProcess, '')
    }

    get programCoating(): string {
        return coalesce(this.supplier.data.programCoating, '')
    }

    get programStep(): number | null {
        return null
    }

    get programActions(): string[] | null {
        return this.supplier.data.programActions
    }

    get programCurrentAction(): string | null {
        const actions = this.programActions
        const step = this.programStep
        if (actions == null || step == null) return null
        return actions[step] || null
    }

    get areaString(): string {
        return coalesce(this.supplier.data.area?.toFixed(0), '');
    }

    get owner(): string {
        const update = this.supplier.update
        if (update && update.owner) return update.owner
        return coalesce(this.supplier.data?.owner, '')
    }

    get createDate(): string {
        return coalesce(this.supplier.data?.createDate, '')
    }

    get programStartTime(): number | null {
        return this.supplier.update?.startProgramTime || null
    }

    get programStartString(): string {
        const time = this.programStartTime
        if (time == null) return '';
        return moment(time).format('DD.MM.YYYY HH:mm');
    }

    get programFinishTime(): number | null {
        return this.supplier.update?.finishProgramTime || null
    }

    get programFinishString(): string {
        const time = this.programFinishTime
        if (time == null) return '';
        return moment(time).format('DD.MM.YYYY HH:mm');
    }

    get programStartPosition(): number | null {
        return null
    }

    get programTargetPosition(): number | null {
        return this.supplier.update?.finishProgramPosition || null
    }

    get programPlannedIn(): string {
        return ''
    }

    get programStartPositionString(): string {
        return coalesce(this.programStartPosition?.toString(), '');
    }

    get currentString() {
        const telemetry = this.supplier.getPositionTelemetry(this.position)
        if (!telemetry || telemetry.current == null) return ''
        return currentToString(telemetry.current);
    }

    get voltageString() {
        const telemetry = this.supplier.getPositionTelemetry(this.position)
        if (!telemetry || telemetry.voltage == null) return ''
        return voltageToString(telemetry.voltage);
    }

    get temperatureString() {
        const telemetry = this.supplier.getPositionTelemetry(this.position)
        if (!telemetry || telemetry.temp == null) return ''
        return temperatureToString(telemetry.temp);
    }

    get details(): PlayerData_DetailInfo[] {
        return this.supplier.data.details || []
    }

    get programState(): Program_State | null {
        const update = this.supplier.update
        if (update) return update.lastProgramState
        return this.supplier.data.programState
    }

    get overexposure(): boolean {
        return this.overexposureMs != null;
    }

    /**
     * Вернем точку начала передержки (в миллисекундах), если она уже случилась
     */
    get overexposureMs(): number | null {
        const update = this.supplier.update;
        if (!update) return null;

        const overexposureTime = update.overexposureTime;
        return (overexposureTime != null && overexposureTime + 1000 < new Date().getTime()) ? overexposureTime : null;
    }

    get finishInDuration() {
        const finishTime = this.programState == Program_State.STARTED ? this.programFinishTime : null
        if (finishTime == null) return null;
        return msToDuration(finishTime - new Date().getTime());
    }

    get estimateDuration(): string {
        const durationMs = this.supplier.data.programEstimate
        if (!durationMs) return ''

        const duration = msToDuration(durationMs)
        duration.seconds = 0
        return `~` + durationTextAround(messages, duration)
    }

    get duration(): string {
        let finish = this.supplier.update?.finishProgramTime || this.supplier.data.programStart
        let start = this.supplier.update?.startProgramTime || this.supplier.data.programFinish
        if (finish == null || start == null) return ''

        const duration = msToDuration(finish - start)
        duration.seconds = 0
        return durationTextAround(messages, duration)
    }

    get positionNowString(): string {
        const position = this.position
        if (position == null) return ''

        let name = this.supplier.getPositionShortTitle(position)
        if (name) {
            name = ` (${name})`
        }
        return `${position}${name}`
    }

    /**
     * Возвращает текущие точки (позиции) пути до следующей точки назначения (до ванны или накопителя, например).
     * Текущая позиция - не добавляется
     */
    get positionNextPath(): number[] | null {
        const path = this.supplier.update?.pendingPath;
        if (!path || path.length == 0) {
            return null;
        }

        const currentPosition = this.position;
        // убираем из пути текущую позицию
        const pathWithoutCurrent = path.filter(p => currentPosition == null || p != currentPosition);
        return pathWithoutCurrent.length ? pathWithoutCurrent : null;
    }

    get positionNextString(): string {
        const path = this.supplier.update?.pendingPath;
        if (path == null || path.length == 0) return '';

        const next = path[path.length - 1];

        let name = this.supplier.getPositionShortTitle(next)
        if (name) {
            name = ` (${name})`
        }
        return `${next}${name}`
    }

    get positionEndString(): string {
        const finish = this.supplier.update?.finishProgramPosition
        if (!finish) return ''

        let name = this.supplier.getPositionShortTitle(finish)
        if (name) {
            name = ` (${name})`
        }
        return `${finish}${name}`
    }

    get stepDonePair(): { current: number | null, total: number } | null {
        if (this.programState != Program_State.STARTED && this.programState != Program_State.TERMINATED || this.supplier.data.program != this.supplier.update?.lastProgramId) return null;

        const processActions = this.supplier.update?.processActions
        if (processActions == null) return null

        return {current: coalesce(this.supplier.update?.paIndex, null), total: processActions};
    }

    get stepDoneString(): string {
        const donePair = this.stepDonePair;
        if (donePair == null) return '';

        const currentIndex = donePair.current == null ? '-' : donePair.current;
        return `${currentIndex} / ${donePair.total}`;
    }

    get cancelStartingAvailable(): boolean {
        const update = this.supplier.update
        if (update == null || update.lastCancelTime == null) return false;

        const lastCancelTime = coalesce(update.lastCancelTime, null);
        return lastCancelTime != null && new Date().getTime() < lastCancelTime;
    }
}