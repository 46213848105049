export class PositionEditPage {
    constructor() {
        this.positionTypeSelect.addEventListener('change', () => this.toggleRectifierNumberField());
        this.toggleRectifierNumberField()
    }

    private get positionTypeSelect() {
        return document.getElementById('type.id') as HTMLSelectElement
    }

    private toggleRectifierNumberField() {
        const rectifierNumber = document.getElementById('rectifierNumber') as HTMLInputElement
        const rectifierNumberDiv = rectifierNumber.closest('div')!!

        const option = this.positionTypeSelect.selectedOptions.item(0)
        if (option != null && option.getAttribute('data-max-current')) {
            rectifierNumberDiv.classList.toggle('hidden', false);
            return;
        }

        rectifierNumber.value = '';
        rectifierNumberDiv.classList.toggle('hidden', true);
    }
}