import {escapeHTML} from "./escapeHTML";
import {Messages} from "../messages/Messages";

const esc = escapeHTML;
const messages = new Messages();

interface RenderBaseParams {
    name: string
    labelCode: string
    readonly?: boolean
    required?: boolean
}

interface RenderSelectParams extends RenderBaseParams {
    optionContent: string
}

interface RenderInputParams extends RenderBaseParams {
    value?: string
}

interface RenderNumberParams extends RenderBaseParams {
    value?: string | number
    step?: string | number
}

export function springSelect(params: RenderSelectParams) {
    return `
<label for="${params.name}" class="form-label">${esc(messages.get(params.labelCode))}</label>
<select class="form-select" id="${params.name}" aria-describedby="${params.name}Help"
        name="${params.name}" ${params.readonly ? 'readonly ' : ' '} ${params.required ? 'required ' : ' '}>
    ${params.optionContent}
</select>
    `
}


export function springNumber(params: RenderNumberParams) {
    const value = typeof params.value === 'undefined' || params.value === null ? '' : params.value;
    const step = typeof params.step === 'undefined' || params.step === null ? '' : `step="${params.step}"`;

    return `
<label for="${params.name}" class="form-label">${esc(messages.get(params.labelCode))}</label>
<input type="number" lang="en" class="form-control" id="${params.name}" aria-describedby="${params.name}Help"
        name="${params.name}" ${params.readonly ? 'readonly ' : ' '} ${params.required ? 'required ' : ' '} value="${esc(value)}" ${step}>
    `
}