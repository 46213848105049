import {escapeHTML} from "../lib/escapeHTML";
import {Modal} from "bootstrap";
import {createHtmlElement, hiddenClassIf, toggleHidden, toggleReloadingFade} from "../lib/domFunctions";
import {fetchJsonForApiResponse} from "../lib/fetch";
import {Messages} from "../messages/Messages";
import {buttonProgress} from "../lib/buttonProgress";
import {emptyPage, Page} from "../dto/org.springframework.data.domain";
import {coalesce} from "../lib/coalesce";
import {DetailListItem} from "../dto/com.rico.sb2.service.details";

const messages = new Messages();

export type DetailSearchCallback = (item: DetailListItem, dialog: DetailSearch) => boolean | Promise<boolean>
export type DetailSearchRequest = (search: string) => Promise<Page<DetailListItem>>
export type DetailListItemPredicate = (item: DetailListItem) => boolean

export type DetailSearchBackendCallback = (backend: DetailSearchBackend) => void;

export class DetailSearchBackend {
    private readonly request: DetailSearchRequest

    private lastQueryNumber = 0

    queryTimeout = 500
    lastQueryText = ''
    lastQueryResult: Page<DetailListItem> = emptyPage()
    lastQueryError: any = null

    private readonly success: DetailSearchBackendCallback
    private readonly error: DetailSearchBackendCallback

    constructor(success: DetailSearchBackendCallback, error: DetailSearchBackendCallback, request?: DetailSearchRequest) {
        this.request = coalesce(request, (search) => fetchJsonForApiResponse(`/op/searchDetails?searchText=${encodeURIComponent(search)}`))
        this.success = success;
        this.error = error;
    }

    search(text: string) {
        this.lastQueryText = text
        const queryNumber = ++this.lastQueryNumber
        setTimeout(() => this.executeQuery(queryNumber, text), this.queryTimeout);
    }

    private executeQuery(queryNumber: number, searchText: string) {
        if (queryNumber != this.lastQueryNumber) return;

        this.request(searchText)
            .then(json => {
                if (queryNumber === this.lastQueryNumber) {
                    this.lastQueryResult = json
                    this.lastQueryError = null
                    this.success(this)
                }
            })
            .catch(error => {
                if (queryNumber === this.lastQueryNumber) {
                    this.lastQueryResult = emptyPage()
                    this.lastQueryError = error
                    this.error(this)
                }
            });
    }
}

export class DetailSearch {
    private readonly searchField: HTMLInputElement
    private readonly searchEmpty: HTMLElement
    private readonly searchError: HTMLElement
    private readonly searchTable: HTMLTableElement
    private readonly searchNotice: HTMLElement

    private readonly selectHandler: DetailSearchCallback
    private readonly selectPredicate: DetailListItemPredicate

    private readonly queryMinLetters = 0

    private readonly backend: DetailSearchBackend

    constructor(params: { selectHandler: DetailSearchCallback, selectPredicate?: DetailListItemPredicate, request?: DetailSearchRequest }) {
        this.backend = new DetailSearchBackend(this.backendSuccess.bind(this), this.backendError.bind(this), params.request)
        this.selectHandler = params.selectHandler
        this.selectPredicate = coalesce(params.selectPredicate, () => true)

        const content = createHtmlElement('div', {'tab-index': '-1', class: 'modal'}, `
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">${escapeHTML(messages.get('DetailSearch.title'))}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input type="text" class="form-control" data-ref="searchField" placeholder="${escapeHTML(messages.get('DetailSearch.searchPlaceholder'))}">
        
        <div class="text-muted py-5 text-center" data-ref="searchEmpty">${escapeHTML(messages.get('DetailSearch.searchEmpty'))}</div>
        <div class="text-danger py-5 text-center hidden" data-ref="searchError">${escapeHTML(messages.get('DetailSearch.searchError'))}</div>
        
        <table class="table mt-3 mb-0 hidden" data-ref="searchTable">
            <thead>
                <tr>
                    <th class="text-nowrap w-100" colspan="2">${escapeHTML(messages.get('DetailSearch.table.code'))}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody></tbody>        
        </table>
        
        <div class="hidden mt-3 text-muted text-center" data-ref="searchNotice"></div>
      </div>
    </div>
  </div>
`)

        this.searchField = content.querySelector('[data-ref=searchField]')!!
        this.searchEmpty = content.querySelector('[data-ref=searchEmpty]')!!
        this.searchError = content.querySelector('[data-ref=searchError]')!!
        this.searchTable = content.querySelector('[data-ref=searchTable]')!!
        this.searchNotice = content.querySelector('[data-ref=searchNotice]')!!

        this.searchField.addEventListener('input', () => this.scheduleQuery());
        this.searchTable.addEventListener('click', (e) => this.onTableClick(e));

        new Modal(content).show();

        this.scheduleQuery(true);
    }

    public search() {
        this.scheduleQuery(true);
    }

    private scheduleQuery(force: boolean = false) {
        const searchText = this.searchField.value.trim();
        const searchNeeded = searchText.length >= this.queryMinLetters && searchText !== this.backend.lastQueryText
        if (!force && !searchNeeded) {
            return;
        }

        toggleReloadingFade(this.searchTable, true)
        toggleReloadingFade(this.searchEmpty, true)
        toggleReloadingFade(this.searchError, true)

        this.backend.search(searchText)
    }

    private backendSuccess(backend: DetailSearchBackend) {
        this.setContent(backend.lastQueryResult, this.searchEmpty)
    }

    private backendError() {
        this.setContent(emptyPage(), this.searchError)
    }

    private setContent(page: Page<DetailListItem>, emptyNotice: HTMLElement) {
        toggleReloadingFade(this.searchTable, false)
        toggleReloadingFade(this.searchEmpty, false)
        toggleReloadingFade(this.searchError, false)

        const body = this.searchTable.querySelector('tbody')!!
        body.innerHTML = ``;
        toggleHidden(this.searchNotice, true);

        if (page.totalElements == 0) {
            toggleHidden(this.searchTable, true);
            toggleHidden(emptyNotice, false);
            return
        }

        body.innerHTML = page.content
            .map(item => `
            <tr class="align-baseline">
                <td class="title-highlight text-nowrap">${escapeHTML(item.code)}</td>            
                <td class="text-nowrap w-100">${escapeHTML(item.name)}</td>            
                <td class="text-nowrap"><button class="btn btn-sm btn-secondary ${hiddenClassIf(!this.selectPredicate(item))}" data-bind="add" data-id="${item.id}">${messages.get('button.add')}</button></td>            
            </tr>`)
            .join("")


        toggleHidden(this.searchTable, false);
        toggleHidden(this.searchEmpty, true);
        toggleHidden(this.searchError, true);

        this.searchNotice.innerText = messages.get('DetailSearch.countNotice', page.numberOfElements, page.totalElements)
        toggleHidden(this.searchNotice, false);
    }

    private onTableClick(e: MouseEvent) {
        const target = e.target as HTMLElement
        if (target.tagName === 'BUTTON' && target.getAttribute('data-bind') === 'add') {
            const detailId = parseInt(target.getAttribute('data-id') || '0');
            const detailItem = this.backend.lastQueryResult.content.filter(item => item.id === detailId)[0]
            if (detailItem) {

                const selectProgress = buttonProgress(target);
                Promise
                    .resolve(this.selectHandler(detailItem, this))
                    .then(success => {
                        if (success) {
                            selectProgress.stop()
                            target.closest('tr')?.remove();
                        }
                    })
            }
        }
    }
}