import {BindingList} from "./lib/domFunctions";
import {jQuery} from "./boot";
import {AppConfig} from "./AppConfig";
import just from "./lib/just";
import {Messages} from "./messages/Messages";
import {ImportDetailRow} from "./dto/com.rico.sb2.service.importer";

const messages = new Messages();
const highlightCell = {css: {color: 'green'}};

function ensureUpdateSet(row: ImportDetailRow): Set<String> {
	const rowAny = row as any;
	if (!('updateFieldsSet' in rowAny)) {
		rowAny.updateFieldsSet = new Set<String>(row.updateFields ? row.updateFields : []);
	}
	return rowAny.updateFieldsSet;
}

function getUpdateStyle(field: string, row: ImportDetailRow): any {
	return ensureUpdateSet(row).has(field) ? highlightCell : {};
}

export class ImportDetailsPage {
	private readonly bindings: BindingList;
	private readonly uuid: string;

	constructor(uuid: string) {
		this.uuid = uuid

		this.bindings = new BindingList()
		this.bindings.collect(document.body);

		this.bindings.update('showAll', node => node.addEventListener('click', () => this.showList('showAll')));
		this.bindings.update('showCreate', node => node.addEventListener('click', () => this.showList('showCreate', 'CREATE')));
		this.bindings.update('showUpdate', node => node.addEventListener('click', () => this.showList('showUpdate', 'UPDATE')));
		this.bindings.update('showIgnored', node => node.addEventListener('click', () => this.showList('showIgnored', 'IGNORE')));
		this.bindings.update('showIncomplete', node => node.addEventListener('click', () => this.showList('showIncomplete', 'INCOMPLETE')));

		const table = document.getElementById('table') as HTMLTableElement;
		if (!table) return;

		jQuery(table).bootstrapTable({
			url: `${AppConfig.CP}/import/details/${this.uuid}/list`,
			sidePagination: 'server',
			responseHandler: (res) => just({total: res.totalElements, rows: res.content}),
			rowStyle: (row) => just({classes: `row-${row.decision}`}),
			onPostBody: () => table.classList.remove('hidden'),
			columns: [
				{},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('code', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('name', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('material', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('length', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('width', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('height', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('diameter', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('area', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('process', row)},
				{cellStyle: (value: any, row: ImportDetailRow) => getUpdateStyle('coating', row)},
				{formatter: (value: any) => messages.get(`ImportDecision.${value}`)}
			]
		})

		this.showList('showAll');
	}

	private showList(activeButton: string, filter?: string) {
		const table = jQuery('#table');
		const options = table.bootstrapTable('getOptions');
		options.url = `${AppConfig.CP}/import/details/${this.uuid}/list` + (filter ? `?filter=${filter}` : '')
		options.pageNumber = 1
		table.bootstrapTable('refreshOptions', options);
		table.bootstrapTable('refresh');

		this.bindings.update('listButtons', div => div.querySelectorAll('[data-bind]').forEach(button => button.classList.toggle('active', activeButton == button.getAttribute('data-bind'))));
	}
}