import {noop} from "./lib/noop";
import {IFrame, Client} from '@stomp/stompjs';
import {tryCatch} from "./lib/tryCatch";
import {ListenerList} from "./lib/ListenerList";

let wsProtocol = /s:$/.test(document.location.protocol) ? 'wss:' : 'ws:'
let wsOrigin = document.location.origin.replace(document.location.protocol, wsProtocol)
let webSocketUrl = wsOrigin + '/websockets';
let instance: WebSocketClient | null = null;

export type SubscriptionCallback = (body: any) => void;
export type WebSocketClientCallback = (client: WebSocketClient) => void;

export interface WebSocketClientListener {
    onConnected?(client: WebSocketClient): any

    onDisconnected?(client: WebSocketClient): any
}

class ServiceModeListeners extends ListenerList<WebSocketClientListener> implements WebSocketClientListener {
    onConnected(client: WebSocketClient) {
        this.visit(listener => listener.onConnected?.(client))
    }

    onDisconnected(client: WebSocketClient) {
        this.visit(listener => listener.onDisconnected?.(client))
    }
}

/**
 * https://stomp-js.github.io/guide/stompjs/using-stompjs-v5.html
 */
export class WebSocketClient {
    private readonly stomp: any;
    private subscriptions: Subscription[] = [];

    public readonly listeners = new ServiceModeListeners()

    constructor() {
        console.info('ws constructor')

        this.stomp = new Client({
            brokerURL: webSocketUrl,
            // debug: function (str) {
            //      console.info(str);
            // },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
        });

        this.stomp.onConnect = () => {
            console.info('stomp connect');
            // Do something, all subscribes must be done is this callback
            // This is needed because this will be executed after a (re)connect
            this.subscriptions = this.subscriptions.filter(s => s.enabled);
            this.subscriptions.forEach(s => s.restore(this.stomp));
            this.subscriptions
                .filter(s => s.subscribed)
                .forEach(s => tryCatch(() => s.onSubscribe(this)));
            this.listeners.onConnected(this);
        };

        this.stomp.onDisconnect = () => {
            console.info('stomp disconnect');
            this.subscriptions.forEach(s => s.disconnected());
            this.listeners.onDisconnected(this);
        }

        this.stomp.onWebSocketClose = () => {
            console.info('websocket closed');
            this.listeners.onDisconnected(this);
        }

        this.stomp.onStompError = function (frame: IFrame) {
            console.info('stomp error');
            // Will be invoked in case of error encountered at Broker
            // Bad login/passcode typically will cause an error
            // Complaint brokers will set `message` header with a brief message. Body may contain details.
            // Compliant brokers will terminate the connection after any error
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
        };
    }

    get connected() {
        return this.stomp.connected
    }

    private connect() {
        console.info('ws connect')
        this.stomp.activate()
        console.info('ws connect initiated')
    }

    subscribe(topic: string, receiver: SubscriptionCallback, onSubscribed?: WebSocketClientCallback) {
        const subscription = new Subscription(topic, receiver, onSubscribed);
        subscription.restore(this.stomp);
        this.subscriptions.push(subscription);
        return subscription;
    }

    sendText(topic: string, body?: any) {
        if (this.stomp == null || !this.stomp.connected) {
            throw new Error('websockets are not ready');
        }
        this.stomp.publish({destination: topic, body: typeof body === 'undefined' ? undefined : JSON.stringify(body)});
    }

    static instance() {
        if (instance == null) {
            instance = new WebSocketClient();
            instance.connect();
        }
        return instance;
    }

    static configure(url: string) {
        webSocketUrl = url;
    }
}


class Subscription {
    private ref: any;
    private topic: string | null;
    private readonly onMessage: (any: any) => any;

    onSubscribe: WebSocketClientCallback;

    constructor(topic: string, callback: SubscriptionCallback, onSubscribe?: WebSocketClientCallback) {
        this.ref = null;
        this.topic = topic;
        this.onMessage = callback;
        this.onSubscribe = onSubscribe || noop;
    }

    get enabled() {
        return this.topic != null;
    }

    get subscribed() {
        return this.enabled && this.ref != null;
    }

    restore(stomp: Client) {
        if (this.topic == null || !stomp.connected) return;

        console.info(`restore subscription to ${this.topic}`);
        this.ref = stomp.subscribe(this.topic, (message) => {
            // called when the client receives a STOMP message from the server
            if (message.body) {
                this.onMessage(JSON.parse(message.body))
            } else {
                this.onMessage(null)
            }
        });
    }

    disconnected() {
        this.ref = null;
    }

    unsubscribe() {
        if (this.ref != null) {
            this.ref.unsubscribe();
            this.disconnected();
        }
        this.topic = null;
    }
}
