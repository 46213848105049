export enum Program_State {
    NEW = "NEW",
    CREATED = "CREATED",
    STARTED = "STARTED",
    FINISHED = "FINISHED",
    TERMINATED = "TERMINATED"
}

export enum Program_Type {
    OUTER_COMMANDS = "OUTER_COMMANDS",
    BY_PROCESS = "BY_PROCESS",
    SIMPLE_RELOCATION = "SIMPLE_RELOCATION"
}