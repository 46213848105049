import {createSvgElement} from "../lib/domFunctions";
import {ContainerView} from "./Containers";
import {TransportType} from "../dto/com.rico.sb2.entity.device";
import {PositionView} from "./Positions";
import {sortNumberAsc, sortNumberDesc} from "../lib/langExtensions";
import {TransferView, TransportView} from "./Transports";

const ColorCount = 4;
const PixelPerfect = .5;
const TrackContainerDistance = 7;
const TrackWrapMarginX = 9;
const TrackGap = 4;

export const ContainerPathTrackCustomStyles: { [key: string]: string } = {
	'svg-c-track-bracket': 'fill:none;stroke:#a5a0a0',
	'svg-c-track': 'stroke-width: 1;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray: 6,3;stroke-opacity: 1',
	'svg-c-track-0': 'fill:#77e36a;stroke:#77e36a',
	'svg-c-track-0-end': 'marker-end:url(#CTCircle0)',
	'svg-c-track-0-opusk': 'marker-end:url(#CTCircle0FillWhite)',
	'svg-c-track-1': 'fill:#6a95e3;stroke:#6a95e3',
	'svg-c-track-1-end': 'marker-end:url(#CTCircle1)',
	'svg-c-track-1-opusk': 'marker-end:url(#CTCircle1FillWhite)',
	'svg-c-track-2': 'fill:#b553c7;stroke:#b553c7',
	'svg-c-track-2-end': 'marker-end:url(#CTCircle2)',
	'svg-c-track-2-opusk': 'marker-end:url(#CTCircle2FillWhite)',
	'svg-c-track-3': 'fill:#f9ca32;stroke:#f9ca32',
	'svg-c-track-3-end': 'marker-end:url(#CTCircle3)',
	'svg-c-track-3-opusk': 'marker-end:url(#CTCircle3FillWhite)',
	'svg-c-track-sw': 'marker-start:url(#CTWave)',
	'svg-c-track-ew': 'marker-end:url(#CTWave)',
}

export function ContainerPathTrackCustomDefs() {
	const coloredMarkers = Array.from(Array(ColorCount).keys())
		.flatMap(k => {
			const circle = createSvgElement('marker', {id: `CTCircle${k}`, markerWidth: "10", markerHeight: "10", viewBox: "0,0,10,10", refY: "5", refX: "5"});
			circle.append(createSvgElement('circle', {cx: "5", cy: "5", r: "3", class: `svg-c-track-${k}`, style: 'fill-rule:evenodd'}));
			const opusk = createSvgElement('marker', {id: `CTCircle${k}FillWhite`, markerWidth: "10", markerHeight: "10", viewBox: "0,0,10,10", refY: "5", refX: "5"});
			opusk.append(createSvgElement('circle', {cx: "5", cy: "5", r: "3", class: `svg-c-track-${k}`, style: 'fill-rule:evenodd;fill: white'}));
			return [circle, opusk];
		})

	const CTWave = createSvgElement('marker', {id: 'CTWave', markerWidth: "10", markerHeight: "10", viewBox: "0,0,10,10", refY: "5", refX: "5"});
	CTWave.append(createSvgElement('path', {d: "M 5,0 C 0,5 10,5 5,10", class: 'svg-c-track-bracket'}))
	return coloredMarkers.concat(CTWave);
}

class TrackLine {
	readonly vertical: boolean // True, если линия вертикальная. False - если горизонтальная
	main: number  // Координата X линии, если линия вертикальная. Иначе Y
	sec1: number  // Координата y1 линии, если линия вертикальная. Иначе x1
	sec2: number // Координата y2 линии, если линия вертикальная. Иначе x2
	markerStart?: string
	markerEnd?: string

	constructor(vertical: boolean, main: number, sec1: number, sec2: number, opts: { markerStart?: string, markerEnd?: string } = {}) {
		this.vertical = vertical;
		this.main = main;
		this.sec1 = sec1;
		this.sec2 = sec2;
		this.markerStart = opts.markerStart;
		this.markerEnd = opts.markerEnd;
	}

	get start() {
		return {x: this.vertical ? this.main : this.sec1, y: this.vertical ? this.sec1 : this.main}
	}

	get end() {
		return {x: this.vertical ? this.main : this.sec2, y: this.vertical ? this.sec2 : this.main}
	}

	isSameSpan(line: TrackLine) {
		if (this.vertical != line.vertical) return false;
		const a = [this.sec1, this.sec2].sort(sortNumberAsc)
		const b = [line.sec1, line.sec2].sort(sortNumberAsc)
		return a[0] <= b[1] && b[0] <= a[1];
	}

	append(other: TrackLine) {
		if (this.vertical == other.vertical && TrackLine.equalPoints(this.end, other.start) && !this.markerEnd && !other.markerStart) {
			this.sec2 = other.sec2;
			this.markerEnd = other.markerEnd;
			return true;
		}
		return false;
	}

	toString() {
		return `${this.vertical ? 'V' : 'H'}: ${JSON.stringify(this.start)} -> ${JSON.stringify(this.end)}`;
	}

	static equalPoints(a: { x: number, y: number }, b: { x: number, y: number }): boolean {
		return a.x == b.x && a.y == b.y;
	}

	static vertical(x: number, y1: number, y2: number, opts: { markerStart?: string, markerEnd?: string } = {}) {
		return new TrackLine(true, x, y1, y2, opts);
	}

	static horizontal(y: number, x1: number, x2: number, opts: { markerStart?: string, markerEnd?: string } = {}) {
		return new TrackLine(false, y, x1, x2, opts);
	}
}

class ContainerPathTrack {
	readonly id: number;
	path: number[]

	svgLines: SVGElement[] = [];
	trackLines: TrackLine[] = [];

	constructor(id: number, path: number[]) {
		this.id = id;
		this.path = path;
	}

	clear() {
		if (this.svgLines.length) {
			this.svgLines.forEach(l => l.remove());
		}
		this.trackLines = [];
		this.svgLines = [];
	}

	reset(trackLines: TrackLine[], parent: SVGElement, colorIndex: number) {
		this.clear();
		if (trackLines.length == 0) {
			return;
		}

		interface LineSegment {
			d: string,
			class: string,
			markerStart?: string
			markerEnd?: string
		}

		function addPathSegment(out: LineSegment[], item: LineSegment) {
			const needNew = out.length == 0 || out[out.length - 1].markerEnd || item.markerStart;
			if (needNew) {
				out.push(item);
			} else {
				const last = out[out.length - 1];
				last.d += ' ' + item.d;
				last.markerEnd = item.markerEnd;
				last.class += ' ' + item.class;
			}
		}

		const svgList: LineSegment[] = [];

		// рисуем прямые линии
		for (let i = 0; i < trackLines.length; ++i) {
			const line = trackLines[i];
			if (line.vertical) {
				addPathSegment(svgList, {class: '', d: `M ${line.start.x + PixelPerfect},${line.start.y + PixelPerfect} V ${line.end.y + PixelPerfect}`, markerStart: line.markerStart, markerEnd: line.markerEnd})
			} else {
				addPathSegment(svgList, {class: '', d: `M ${line.start.x + PixelPerfect},${line.start.y + PixelPerfect} H ${line.end.x + PixelPerfect}`, markerStart: line.markerStart, markerEnd: line.markerEnd})
			}
		}

		const svgLines = svgList.map((l, index, array) => {
			let lineClass = l.class.replace('#', `${colorIndex}`) + (l.markerStart || ' ') + (l.markerEnd || ' ');
			lineClass += ` svg-c-track svg-c-track-${colorIndex}`;
			if (index == array.length - 1) {
				lineClass += ` svg-c-track-${colorIndex}-end`;
			}
			const line = createSvgElement('path', {class: lineClass, d: l.d});
			parent.insertBefore(line, parent.firstElementChild)
			return line;
		});

		this.trackLines = trackLines;
		this.svgLines = svgLines;
	}
}

export interface SchemaContainerTracksPlayerAdapter {
	readonly root: SVGElement;

	getContainerView(id: number): ContainerView | null;

	getPositionView(position: number): PositionView;

	getAllPositions(): PositionView[];

	getTransportView(transport: number): TransportView;

	findTransferByMove(startPosition: number, endPosition: number): TransferView | null;

	findTransferByPosition(startOrEndPosition: number): TransferView | null;

	getAllPositionLineY(): number[];
}

export class SchemaContainerTracks {
	private readonly player: SchemaContainerTracksPlayerAdapter;

	private readonly containerTracks = new Map<number, ContainerPathTrack>()

	private colorSeed = 0;
	private colorAssigned = new Map<number, number>();

	constructor(player: SchemaContainerTracksPlayerAdapter) {
		this.player = player;
	}

	private getColor(containerId: number): number {
		let color = this.colorAssigned.get(containerId);
		if (color == undefined) {
			color = this.colorSeed % ColorCount;
			this.colorSeed += 1;
			this.colorAssigned.set(containerId, color);
		}
		return color;
	}

	public getContainerTrack(containerId: number) {
		return this.containerTracks.get(containerId) || null;
	}

	public setContainerTrack(containerId: number, path: number[] | null) {
		let track = this.containerTracks.get(containerId) || null;
		if (path == null && track == null) {
			return false;
		}

		// рисуем трек только до первой реальной позиции (не трансфер)
		if (path != null) {
			let trackEndIndex = path.findIndex((position) => {
				const transfer = this.player.findTransferByPosition(position)
				if (transfer != null) return false;
				return this.player.getPositionView(position) != null;
			});

			if (trackEndIndex != -1) {
				path = path.slice(0, trackEndIndex + 1);
			}

			if (path.length == 0) {
				path = null;
			}
		}

		// нет пути - убираем трек
		if (path == null) {
			if (track != null) track.clear();
			this.containerTracks.delete(containerId);
			this.colorAssigned.delete(containerId);
			return false;
		}


		if (track == null) {
			track = new ContainerPathTrack(containerId, path);
			this.containerTracks.set(containerId, track);
		}

		// Значит трек все-таки есть. Очищаем текущий и назначаем новый
		track.path = path;
		return this.renderContainerTrack(containerId)
	}

	public removeContainerTrack(containerId: number) {
		const track = this.containerTracks.get(containerId) || null;
		if (!track) return;

		track.clear();
		this.containerTracks.delete(containerId);
		this.colorAssigned.delete(containerId);
	}

	private getPositionUnderContainer(containerView: ContainerView): number {
		if (containerView.onTransport) {
			const transportView = this.player.getTransportView(containerView.onTransport);
			if (transportView == null) throw new Error(`transfer #${containerView.onTransport} has no transport view instance`);
			if (transportView.position == null) throw new Error(`transfer #${containerView.onTransport} is not over a position`);
			return transportView.position;
		}
		if (containerView.onPosition) {
			return containerView.onPosition;
		}
		throw new Error(`cannot resolve container #${containerView.data.id} position - it's not on position or transport`);
	}

	private renderContainerTrack(id: number) {
		const track = this.containerTracks.get(id) || null;
		if (!track) return;
		if (track.path.length == 0) {
			track.clear();
			return false;
		}

		let trackLines: TrackLine[] = [];
		try {
			trackLines = this.makeTrackLines(track);
			this.avoidIntersections(id, trackLines);
		} catch (e) {
			console.error(e);
		}

		// теперь делаем SVG из того, что получилось
		track.reset(trackLines, this.player.root, this.getColor(id));

		return !!trackLines;
	}

	/**
     Переменная anchor - это наш старт. Теперь надо определиться, куда мы едем. В большинстве случаев (при переезде из ванны в ванну) надо просто трек
     подняться из ванны вверх и ехать вправо-влево. Но в некоторых случая - подниматься не надо, ведем линию прямо из контейнера. Это случаи такие:
     1) мы находимся на АО
     2) мы находимся на трансфере (и следующая точка - конец этого трансфера).

     Введем обозначение "якорная точка" для позиции - там, где трек останавливается и поворачивает вниз к позиции, чтобы закончить путь.

     Алгоритм такой:
     1) выбираем начальную точку (это центр контейнера)
     2) если надо подняться, то поднимаемся в якорную точку позиции контейнера.
     3) направляемся в якорную точку следующей позиции.
     4) повторяем шаг 3, пока не достигнем последней якорной точки
     5) опускаем трек к позиции.

     Теперь надо подумать как не пересечься с остальными треками.
     Сделаем так: каждый сегмент трека это тип линии (горизонтальная или вертикальная), основная координата линии (x - для вертикальной, y - для горизонтальной) и
     пара дополнительных координат для начала и конца ([y1, y2] для вертикальной, и [x1, x2] для горизонтальной). Координаты целые - integer.
     Далее - все отображаемые треки запоминаются (даже от других контейнеров). И дальше идем по сегментам и смотрим - пересекаются они с кем-то или нет. Если пересекаются -
     сдвигаем основную координату и меняем дополнительные координаты у сегмента до и после.

     Все это считается из расчета линии без переноса. Перенос делается вторым проходом, отдельно.
	 */
	private makeTrackLines(track: ContainerPathTrack): TrackLine[] {
		const containerView = this.player.getContainerView(track.id);
		if (!containerView) return [];

		const containerLocation = containerView.onPosition ? `P${containerView.onPosition}` : `T${containerView.onTransport}`;
		console.info(`#${track.id} path: ${containerLocation} -> ${JSON.stringify(track.path)}`);

		const isOnAO = (containerView: ContainerView) => {
			const transport = containerView.onTransport;
			return transport != null && this.player.getTransportView(transport).data.type == TransportType.AO;
		}

		const isOnTransferStart = (currentPosition: number, nextPosition: number) => this.player.findTransferByMove(currentPosition, nextPosition) != null;

		const positions = this.player.getAllPositions();
		const positionsOnLineY = (lineY: number) => {
			return positions.filter(p => p.lineY == lineY).sort((a, b) => a.x - b.x);
		}
		const prevLineY = (lineY: number): number => {
			return this.player.getAllPositionLineY().filter(y => y < lineY).sort(sortNumberDesc)[0]
		}
		const nextLineY = (lineY: number): number => {
			return this.player.getAllPositionLineY().filter(y => y > lineY).sort(sortNumberAsc)[0]
		}

		function* createSameLineSegment(trackTail: { position: number, x: number, y: number }, nextPV: PositionView, prevPV: PositionView) {
			// Переноса нет, просто рисуем линию
			if (nextPV.lineY == prevPV.lineY) {
				yield TrackLine.horizontal(trackTail.y, trackTail.x, nextPV.centerX);
			}
			// А в этом случае делаем перенос, поскольку это однорядная линия с переносом.
			else {
				let start = prevPV, finish = nextPV;
				let startX = trackTail.x, startY = trackTail.y;
				let offsetY = startY - prevPV.y;

				// Переносов может быть много (в текущих условиях хватает одного, но все-таки)
				while (start.lineY != finish.lineY) {
					const startLinePositions = positionsOnLineY(start.lineY);

					// если для перехода на другую линию надо ехать влево ...
					if (start.lineY > finish.lineY) {
						const segmentEnd = startLinePositions[0];
						const segmentEndX = Math.round(segmentEnd.x - TrackWrapMarginX);
						yield TrackLine.horizontal(startY, startX, segmentEndX, {markerEnd: 'svg-c-track-ew'})

						const nextLine = prevLineY(start.lineY);
						if (!nextLine) break;
						start = positionsOnLineY(nextLine).reverse()[0];
						startX = start.x + start.width + TrackWrapMarginX;
					} else {
						const segmentEnd = startLinePositions[startLinePositions.length - 1];
						const segmentEndX = Math.round(segmentEnd.x + segmentEnd.width + TrackWrapMarginX);
						yield TrackLine.horizontal(startY, startX, segmentEndX, {markerEnd: 'svg-c-track-ew'})

						const nextLine = nextLineY(start.lineY);
						if (!nextLine) break;
						start = positionsOnLineY(nextLine)[0];
						startX = start.x - TrackWrapMarginX;
					}

					startY = start.y + offsetY;
				}

				// И вот мы уже на нужной Y. Добавим последний проход
				yield TrackLine.horizontal(startY, startX, finish.centerX, {markerStart: 'svg-c-track-sw'})
			}
		}

		const trackLines: TrackLine[] = []
		const trackLineLast = () => trackLines[trackLines.length - 1];

		const trackLinesAppend = (...lines: TrackLine[]) => {
			for (const l of lines) {
				const lastLine = trackLines.length == 0 ? null : trackLines[trackLines.length - 1];
				if (lastLine == null || !lastLine.append(l)) {
					trackLines.push(l);
				}
			}
		}

		let trackTail = {position: this.getPositionUnderContainer(containerView), x: containerView.centerX, y: containerView.centerY};

		if (isOnAO(containerView) || isOnTransferStart(trackTail.position, track.path[0])) {
			// поскольку мы никуда не будет подниматься из этой точки - едем как ехали
		} else {
			const pv = this.player.getPositionView(trackTail.position);
			trackLines.push(TrackLine.vertical(containerView.centerX, containerView.centerY, pv.y - TrackContainerDistance));
			trackTail = {position: trackTail.position, ...trackLineLast().end}
		}

		let pathIndex = -1
		while (++pathIndex < track.path.length) {
			const prevPV = this.player.getPositionView(trackTail.position);
			const nextP = track.path[pathIndex];
			const nextPV = this.player.getPositionView(nextP);

			// Если мы на одной линии - линия будет горизонтальной. Ну или с разрывом, если там перенос линии включен
			if (prevPV.data.line == nextPV.data.line) {
				trackLinesAppend(...Array.from(createSameLineSegment(trackTail, nextPV, prevPV)));
				trackTail = {position: nextP, ...trackLineLast().end}
			}
			// Иначе это трансфер. И тут линия будет вертикальной
			else {
				trackLinesAppend(TrackLine.vertical(trackTail.x, trackTail.y, nextPV.y - TrackContainerDistance));
				trackTail = {position: nextP, ...trackLineLast().end}
			}
		}

		// и добавляем последний опуск
		const lastPV = this.player.getPositionView(trackTail.position);
		trackLines.push(TrackLine.vertical(trackLineLast().end.x, trackLineLast().end.y, lastPV.y))

		return trackLines;
	}

	/**
	 * Теперь смотрим где что пересекается и убираем пересечения.
	 * Надо вытащить все линии (не текущего трека) и посмотреть с чем есть пересечения.
	 *
	 * Бежим, ищем совпадения. Сначала вертикальные, потом горизонтальные.
	 * Вертикальные линии можно смещать и влево и вправо. Горизонтальные только вверх.
	 * @private
	 */
	private avoidIntersections(id: number, trackLines: TrackLine[]) {
		const otherLines = Array.from(this.containerTracks.values()).filter(t => t.id != id).flatMap(t => t.trackLines)
		const otherVerticals = otherLines.filter(l => l.vertical);
		const otherHorizontals = otherLines.filter(l => !l.vertical);
		if (otherHorizontals.length == 0 && otherVerticals.length == 0) {
			return;
		}

		function sameSpanNoOverlap(line: TrackLine, sameSpan: TrackLine[]) {
			if (sameSpan.length == 0) {
				return true;
			}
			// Так, есть пересечение по второстепенной координате. Если нет по основной - то работаем как есть.
			return !sameSpan.some(ev => ev.main == line.main);
		}

		function alignEnds(line: TrackLine, index: number, array: TrackLine[]) {
			if (index >= 1 && !line.markerStart && !array[index - 1].markerEnd) {
				array[index - 1].sec2 = line.main;
			}
			if (index < array.length - 1 && !line.markerEnd && !array[index + 1].markerStart) {
				array[index + 1].sec1 = line.main;
			}
		}

		trackLines.forEach((line, index, array) => {
			if (!line.vertical) return;

			// Проверяем пересечение по второстепенной координате
			const sameSpan = otherVerticals.filter(ev => ev.isSameSpan(line));
			if (sameSpanNoOverlap(line, sameSpan)) {
				return;
			}
			// И тут пересечение. Тогда пробуем наш main менять вправо-влево, пока не попадем в пустое место.
			const lineMain = line.main;
			let step = 0, newMain = lineMain;
			do {
				step += 1;
				newMain = lineMain + TrackGap * (step % 2 == 0 ? (step / 2) : ((step + 1) / -2))
			} while (sameSpan.some(ev => ev.main == newMain));

			// Нашли. Есть новая координата. Теперь надо сместить конец предыдущего сегмента и начало следующего, если наша линия не обрывается.
			line.main = newMain;
			alignEnds(line, index, array);
		});

		trackLines.forEach((line, index, array) => {
			if (line.vertical) return;

			// Проверяем пересечение по второстепенной координате
			const sameSpan = otherHorizontals.filter(ev => ev.isSameSpan(line));
			if (sameSpanNoOverlap(line, sameSpan)) {
				return;
			}

			// И тут пересечение. Тогда пробуем наш main менять вверх, пока не попадем в пустое место.
			const lineMain = line.main;
			let step = 0, newMain = lineMain;
			do {
				step += 1;
				newMain = lineMain - TrackGap * step;
			} while (sameSpan.some(ev => ev.main == newMain));

			// Нашли. Есть новая координата. Теперь надо сместить конец предыдущего сегмента и начало следующего, если наша линия не обрывается.
			line.main = newMain;
			alignEnds(line, index, array);
		});
	}
}