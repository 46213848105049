const DISABLED_ATTR = 'disabled';
const DISABLED_VALUE = 'disabled';

export function buttonProgress(button?: HTMLElement, text?: string): { stop: () => void } {
    if (!button) {
        return {
            stop() {
            }
        }
    }

    const disabled = button.getAttribute(DISABLED_ATTR);
    button.setAttribute(DISABLED_ATTR, DISABLED_VALUE);
    button.classList.add('progress-button-back')

    let oldHtml: string | null = null;
    if (typeof text !== 'undefined' && text != null) {
        oldHtml = button.innerHTML
        button.innerText = text
    }

    return {
        stop() {
            button.classList.remove('progress-button-back')

            if (!disabled) {
                button.removeAttribute(DISABLED_ATTR);
            }
            if (oldHtml != null) {
                button.innerHTML = oldHtml
            }
        }
    }
}

export function buttonEnable(button: HTMLButtonElement | null | undefined, enabled: boolean) {
    if (!button) return;

    if (enabled) {
        button.removeAttribute(DISABLED_ATTR);
    } else {
        button.setAttribute(DISABLED_ATTR, DISABLED_VALUE);
    }
}