import {Popover} from 'bootstrap';
import {WebSocketClient} from "./WebSocketClient";
import {Messages} from "./messages/Messages";
import {AppConfig} from "./AppConfig";
import {escapeHTML} from "./lib/escapeHTML";
import {ProcessControlService_Mode, ProcessControlService_Status} from "./dto/com.rico.sb2.service";
import {apiGetForAjaxResponse} from "./lib/SecuredAjax";
import {PeriodicTask} from "./lib/PeriodicTask";
import {createHtmlElement} from "./lib/domFunctions";
import {coalesce} from "./lib/coalesce";

const messages = new Messages();
const BAD_SEVERITY_CLASSES: { [key: string]: string } = {
	'warning': 'text-warning',
	'warn': 'text-warning',
	'critical': 'text-danger',
}

const NORMAL_SEVERITY_CLASSES: { [key: string]: string } = {
	'normal': ''
}

export class StatusAlerts {
	private readonly ws: WebSocketClient;
	private readonly tick: PeriodicTask;

	constructor(processStatus: ProcessControlService_Status | null) {
		this.tick = new PeriodicTask(this.requestPrometheus.bind(this), {periodMs: 15000, enabled: false})
		this.requestPrometheus();

		this.ws = WebSocketClient.instance();
		this.ws.subscribe('/topic/alerts/prometheus', body => this.updatePrometheus(body))
		this.ws.subscribe('/topic/status', body => this.updateProcessStatus(body))
		this.ws.listeners.add({
			onConnected: () => {
				apiGetForAjaxResponse(`${AppConfig.CP}/op/status`).then((m: ProcessControlService_Status) => this.updateProcessStatus(m))
			},
			onDisconnected: () => this.updateProcessStatus(null)
		})

		this.updateProcessStatus(processStatus);
	}

	private requestPrometheus() {
		apiGetForAjaxResponse(`${AppConfig.CP}/op/prometheus`)
			.then(m => this.updatePrometheus(m))
			.catch(() => this.failPrometheus())
	}

	private updateProcessStatus(body: ProcessControlService_Status | null) {
		const container = document.querySelectorAll<HTMLSpanElement>('span[data-bind="processStatusString"]');

		if (!body) {
			container.forEach((node: HTMLElement) => {
				node.innerHTML = `<span class='text-warning mx-2'>${messages.get('webSocket.closedNotice')}</span>`
			})
			return;
		}

		const blocks: string[] = [];

		const labelCode = `service.status.${body.mode}`;
		const label = messages.get(labelCode)
		const labelClass = body.mode == ProcessControlService_Mode.STOPPED ? 'blink-opacity text-warning fw-bold' : '';
		blocks.push(`<span class="me-5 ${labelClass}">${escapeHTML(label)}</span>`)

		blocks.push(`<span class="me-5">${messages.get('service.status.programs', body.programs)}</span>`)
		if (body.commands > 0) {
			blocks.push(`<span class="me-5">${messages.get('service.status.commands', body.commands)}</span>`)
		}

		if (body.lastFinishTime != null) {
			const message = messages.get('service.status.finishTime', new Date(body.lastFinishTime).toLocaleTimeString(AppConfig.locale))
			blocks.push(`<span class="me-5">${message}</span>`)
		}

		container.forEach((node: HTMLElement) => node.innerHTML = blocks.join(""))
	}

	private updatePrometheus(body: any) {
		const container = document.getElementById('prometheusAlerts') as HTMLElement;
		if (!container) return;

		container.innerHTML = '';
		new Popover(container).dispose();

		let allAlerts = !body || !body.data || !body.data.alerts
			? []
			: body.data.alerts
				.filter((a: any) => a.labels.severity in BAD_SEVERITY_CLASSES || a.labels.severity in NORMAL_SEVERITY_CLASSES)
				.filter((a: any) => coalesce(a.annotations.summary, '').length)

		if (allAlerts.length == 0) {
			this.failPrometheus();
			// toggleHidden(container, true);
			return;
		}

		const alerts = allAlerts.filter((a: any) => a.labels.severity in BAD_SEVERITY_CLASSES);
		const red = alerts.some((a: any) => BAD_SEVERITY_CLASSES[a.labels.severity] == 'text-danger');
		const icon = red
			? `<span class="fa-stack small"><i class="fa-stack-2x fa-solid fa-triangle text-white"></i><i class="fa-stack-1x fa-solid fa-exclamation text-danger"></i></span>`
			: `<span class="fa-stack small"><i class="fa-stack-2x fa-solid fa-circle text-white"></i><i class="fa-stack-1x fa-solid fa-check text-success"></i></span>`;
		container.innerHTML = `<a target="_blank" href="${AppConfig.CP}/maintenance">${icon}</a>`;

		if (alerts.length) {
			const nowString = new Date().toLocaleString();
			new Popover(container, {
				trigger: 'click hover',
				html: true,
				sanitize: false,
				customClass: 'popover-auto popover-dark',
				content: () => {
					const nowRendered = new Set()
					return createHtmlElement('ul', {class: 'm-0 p-0'}, alerts
						.map((alert: any) => {
							const text = `${alert.annotations.summary} Обновлено: ` + nowString;
							const textClass = BAD_SEVERITY_CLASSES[alert.labels.severity] || 'text-light';
							const html = `<div class="my-1 ${textClass}">${escapeHTML(text)}</div>`;
							if (nowRendered.has(html)) return '';

							nowRendered.add(html);
							return html
						})
						.sort()
						.join(""));
				}
			})
		}
	}

	private failPrometheus() {
		const container = document.getElementById('prometheusAlerts') as HTMLElement;
		if (!container) return;

		container.innerHTML = `<span class="text-warning">${messages.get('prometheus.emptyResponse')}</span>`;
	}
}