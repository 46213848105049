import {line, plot} from "@observablehq/plot";
import {ContainerReportView_Chart} from "./dto/com.rico.sb2.service.reports";
import {coalesce} from "./lib/coalesce";
import {sortNumberAsc} from "./lib/langExtensions";

export class ReportOnePage {
    constructor() {
        document.querySelectorAll('div[data-chart]').forEach(this.renderChartQuietly.bind(this));
    }

    private renderChartQuietly(node: Element) {
        try {
            this.renderChart(node);
        } catch (e) {
            console.error(e);
        }
    }

    private parseChartLine(placeholder: Element, attr: string): ContainerReportView_Chart | null {
        const source = JSON.parse(placeholder.getAttribute(attr) as string) as ContainerReportView_Chart;
        if (source == null || source.x == null || source.y == null || source.x.length == 0) {
            return null;
        }
        return source;
    }

    private collectChartLine(chart: ContainerReportView_Chart | null, startTime: number, endTime: number): { x: number, y: number }[] | null {
        if (chart == null || chart.x == null || chart.y == null) return null;

        const count = Math.min(chart.x.length, chart.y.length);
        const begin = new Date(chart.begin).getTime();
        if (startTime == null) {
            startTime = begin;
        }

        const data: { x: number, y: number }[] = [];

        for (let i = 0; i < count; ++i) {
            const x = begin + chart.x[i] - startTime;
            const y = chart.y[i];
            data.push({x, y});
        }
        data.sort((a, b) => a.x - b.x);
        data.forEach(d => d.x /= 1000)

        return data;
    }

    private renderChart(placeholder: Element) {
        const chartReal = this.parseChartLine(placeholder, 'data-chart');
        const chartPlan = this.parseChartLine(placeholder, 'data-plan-chart');
        if (chartReal == null && chartPlan == null) {
            return;
        }

        const startMillis = parseInt(placeholder.getAttribute('data-start') || '');
        if (!Number.isFinite(startMillis)) return;
        const endMillis = parseInt(placeholder.getAttribute('data-end') || '');
        if (!Number.isFinite(endMillis)) return;

        const chartRealValues = coalesce(this.collectChartLine(chartReal, startMillis, endMillis), []);
        const chartPlanValues = coalesce(this.collectChartLine(chartPlan, startMillis, endMillis), []);


        let stepDurationSec = (endMillis - startMillis) / 1000
        // округлим до десятых секунды
        stepDurationSec = Math.round(stepDurationSec * 10) / 10;

        const xRange = [0, Math.ceil(stepDurationSec)];

        const yDomain: number[] = [];
        chartRealValues.forEach(v => yDomain.push(v.y));
        chartPlanValues.forEach(v => yDomain.push(v.y));
        yDomain.sort(sortNumberAsc);

        const yDomainMin = Math.min(0, yDomain[0] * 1.2);
        const yDomainMax = Math.max(0, yDomain[yDomain.length - 1] * 1.2);
        const yRange = [yDomainMin, yDomainMax];

        const marks = []
        if (chartRealValues.length > 0) {
            marks.push(line(chartRealValues, {x: "x", y: "y"}));
        }
        if (chartPlanValues.length > 0) {
            marks.push(line(chartPlanValues, {x: "x", y: "y", stroke: 'red', strokeWidth: 3, strokeDasharray: [1, 10]}));
        }

        const chart = plot({
            width: placeholder.getBoundingClientRect().width,
            height: placeholder.getBoundingClientRect().height,
            x: {line: true, domain: xRange, label: placeholder.getAttribute('data-x-label')},
            y: {line: true, grid: true, domain: yRange, label: placeholder.getAttribute('data-y-label')},
            grid: true,
            marks
        });

        placeholder.replaceChildren(chart);
    }
}