import {BindingList, createHtmlElementFromHtml, toggleDisabled, toggleHidden, toggleReloadingFade, toggleVisibilityHidden} from "./lib/domFunctions";
import {Messages} from "./messages/Messages";
import {ProcessInputField} from "./modals/ProcessInputField";
import {fetchJsonForApiResponse, isAjaxResponseErrors, toastFetchError} from "./lib/fetch";
import {ProcessListItem} from "./dto/com.rico.sb2.service.process";
import {ContainerType} from "./dto/com.rico.sb2.entity.detail";
import {AppConfig} from "./AppConfig";
import {escapeHTML} from "./lib/escapeHTML";
import {ContainerForm, ContainerForm_ActionForm, ContainerForm_DetailInfo} from "./dto/com.rico.sb2.service.queue";
import {CoatingListItem, DetailListItem, DetailListItem_CoatingLabel, DetailListItem_ProcessLabel} from "./dto/com.rico.sb2.service.details";
import {coalesce, coalesceEmpty, floatTrim5Text, ifEmpty, nullWhenNaN, numbersToCommaString, numberStringOrEmpty, toEmptyString, zeroWhenNaN} from "./lib/coalesce";
import {DetailSearchBackend} from "./modals/DetailSearch";
import {ContainerTypeNamer} from "./dto/com.rico.sb2.support";
import {buttonProgress} from "./lib/buttonProgress";
import {apiPostForAjaxResponse} from "./lib/SecuredAjax";
import {AjaxResponseWithErrors, AjaxResponseWithErrors_FieldError, AjaxResponseWithErrors_ObjectError} from "./dto/com.rico.sb2.controllers.dto";
import {bootstrapModal} from "./lib/bootstrapModal";
import {lastArg} from "./lib/langExtensions";
import {showToastSuccess} from "./lib/boostrapToast";

const messages = new Messages();
const containerTypeNamer = new ContainerTypeNamer();
const H = escapeHTML;

interface DetailListItemFlat extends DetailListItem {
	process: DetailListItem_ProcessLabel | null
	coating: DetailListItem_CoatingLabel | null
}

enum DashboardFormMode {
	VIEW,
	CREATE,
	EDIT
}

const TEMPLATE = `
<div class="dash-form">
    <div class="dash-form__caption text-start p-2">
        <span data-bind="titleView">Просмотр подвески</span>    
        <span class="hidden" data-bind="titleCreate">Создание подвески</span>    
        <span class="hidden" data-bind="titleEdit">Редактирование подвески</span>    
    </div>
    <div class="dash-form__db-details">
        <div class="dash-form__db-details-filter d-flex flex-nowrap align-items-center border border-gray px-2">
            <div>
                <input type="text" class="form-control d-inline-block w-100" placeholder="фильтр по шифру и названию" name="detailFilter">
            </div>            
            <div class="flex-fill ps-3">
                <input type="hidden" name="process">
                <div class="input-group w-100">
                    <input type="text" class="form-control form-control-nofocus cursor-pointer" tabindex="-1" name="processName" readonly placeholder="Тех. процесс">
                    <button class="btn btn-secondary d-print-none shadow-none hidden" type="button" data-bind="clearProcess" title="${messages.get('ProcessInputField.clearButtonTitle')}"><i class="fa-light fa-xmark-large"></i></button>
                    <button class="btn btn-secondary d-print-none shadow-none" type="button" data-bind="searchProcess" title="${messages.get('ProcessInputField.searchButtonTitle')}"><i class="fa-light fa-magnifying-glass"></i></button>
                </div>
            </div>
            <div class="flex-fill ps-3 hidden" data-bind="coatingSelect">
                <select name="coating" class="form-select w-100"></select>
            </div>
        </div>
        <div class="dash-form__db-details-caption border-start border-end border-bottom border-gray text-start">
            <div class="text-nowrap title-highlight fw-bold p-2">Список деталей из БД</div>
        </div>
        <div class="dash-form__db-details-table border border-gray border-top-0">
            <div>
                <table class="table table-hover table-row-pointer">
                    <thead>
                    <tr class="border-dark">
                        <th class="text-nowrap border-end border-gray">Шифр</th>
                        <th class="text-nowrap border-end border-gray">Наименование</th>
                        <th class="text-nowrap border-end border-gray">Материал</th>
                        <th class="text-nowrap border-end border-gray">S, дм2</th>
                        <th class="text-nowrap border-end border-gray">Вес, кг</th>
                        <th class="text-nowrap border-end border-gray">Тех. процесс</th>
                        <th class="text-nowrap border-gray">Покрытие</th>
                    </tr>
                    </thead>
                    <tbody data-bind="dbDetails"></tbody>
                </table>
            </div>
        </div>
        <div class="dash-form__db-details-foot border border-top-0 border-gray">
            <div class="text-muted text-center" style="padding: 1.25em 0;">Показано <span data-bind="detailTableShown">0</span> деталей из <span data-bind="detailTableTotal">0</span></div>
        </div>
    </div>
    <div class="dash-form__form-details">
        <div class="dash-form__form-type d-flex flex-nowrap align-items-center justify-content-center border-bottom border-top border-gray px-2">
            <select name="type" class="form-select d-inline-block w-auto">
                <option value="SUSPENSION"></option>
                <option value="CYLINDER"></option>
            </select>
            <a class="d-inline-block ms-3 w-auto hidden" data-bind="containerNumber" target="_blank" title="Открыть в новой вкладке"></a>
        </div>
        <div class="dash-form__form-details-caption border-bottom border-gray text-start">
            <div class="text-nowrap title-highlight fw-bold p-2">Список деталей в подвеске/барабане</div>
        </div>
        <div class="dash-form__form-details-table">
            <div>
                <table class="table table-hover">
                    <thead>
                    <tr class="border-dark">
                        <th class="text-nowrap border-end border-gray" colspan="2">Деталь</th>
                        <th class="text-nowrap border-end border-gray">S, дм2</th>
                        <th class="text-nowrap border-end border-gray">Вес, кг</th>
                        <th class="text-nowrap border-end border-gray">Кол-во</th>
                        <th class="text-nowrap border-end border-gray">S, общ</th>
                        <th class="border-gray w-4em"></th>
                    </tr>
                    </thead>
                    <tbody data-bind="formDetails">
                    </tbody>
                </table>
            </div>
        </div>
        <div class="dash-form__form-details-foot">
            <table class="table">
                <tr class="align-baseline border-top border-gray">
                    <td>
                        <div class="text-nowrap">
                            <span>S оснастки:</span>
                            <input class="form-control d-inline-block w-4em text-center" type="number" step="any" min="0" data-bind="selfArea">                            
                        </div>                    
                    </td>                
                    <td class="text-end text-nowrap">S деталей:</td>
                    <td><input class="form-control d-inline-block w-5em text-center" type="number" step="any" min="0" data-bind="detailArea"></td>
                    <td class="text-end text-nowrap">Вес lim:</td>
                    <td><span class="form-control disabled d-inline-block w-4em text-center" data-bind="weightLimit" readonly>&nbsp;</span></td>
                </tr>
                <tr class="align-baseline border-top border-gray">
                    <td>Итого:</td>
                    <td class="text-end">S</td>
                    <td><span class="form-control disabled d-inline-block w-5em text-center" data-bind="totalArea">&nbsp;</span></td>
                    <td class="text-end">Вес</td>
                    <td><span class="form-control disabled d-inline-block w-4em text-center" data-bind="totalWeight">&nbsp;</span></td>
                </tr>
            </table>
        </div>
    </div>
    <div class="dash-form__form-other">
        <div class="dash-form__form-other-head d-flex flex-nowrap align-items-center justify-content-center border-gray border text-center">
            <span class="fw-bold mx-2">Проверка параметров</span>
        </div>
        <div class="dash-form__form-other-body border-gray border-start border-end p-2">
            <table>
                <tr class="align-baseline">
                    <td class="text-nowrap text-end px-2">Название ТП:</td>
                    <td class="pe-2 title-highlight" data-bind="processTitle">не выбрано</td>
                </tr>
                <tr class="align-baseline">
                    <td class="text-nowrap text-end px-2">Схема обработки:</td>
                    <td class="pe-2 title-highlight" data-bind="coatingTitle">не выбрано</td>
                </tr>
            </table>
            <hr class="my-2">
            <div data-bind="processActionsContainer" class="d-flex flex-row flex-nowrap justify-content-center"></div>
        </div>
        <div class="dash-form__form-other-foot border-gray border border-1 border-top-0">
            <div class="text-nowrap text-center py-2">
                <button class="mx-2 btn btn-secondary text-uppercase hidden" type="button" data-bind="editButton">Редактир.</button>
                <button class="mx-2 btn btn-success text-uppercase" type="button" data-bind="saveButton">Сохранить</button>
                <button class="mx-2 btn btn-secondary text-uppercase" type="button" data-bind="cancelButton">Отмена</button>
            </div>
        </div>
    </div>
</div>
`

export class DashboardForm {
	readonly element: HTMLDivElement;
	readonly bindings = new BindingList()

	readonly allowedDensities: number[]

	private readonly processInput: ProcessInputField;
	private readonly searchDetailsBackend: DetailSearchBackend;

	private formContainer: number = 0
	private formInit: ContainerForm | null = null
	private formMode: DashboardFormMode = DashboardFormMode.VIEW
	private formDetails: ContainerDetailRow[] = []
	private formActions: ContainerFormActionView[] = []

	constructor(params: { allowedDensities: number[] }) {
		const self = this;

		this.formMode = DashboardFormMode.CREATE
		this.allowedDensities = params.allowedDensities

		this.searchDetailsBackend = new DetailSearchBackend(
			this.detailSearchBackendSuccess.bind(this),
			this.detailSearchBackendError.bind(this),
			(searchText) => {
				const text = encodeURIComponent(searchText);
				const coating = self.getCoatingNumberString();
				const details = self.getSelectedDetailsCommaString()
				return fetchJsonForApiResponse(`/queue/op/searchDetails?searchText=${text}&coating=${coating}&details=${details}&withCoatings=true`);
			}
		)

		this.element = createHtmlElementFromHtml(TEMPLATE);
		this.bindings.collect(this.element)
		this.bindings.collectByName(this.element)

		this.bindings.first('type')?.querySelectorAll('option').forEach(option => option.innerText = containerTypeNamer.name(option.value as ContainerType))

		this.processInput = new ProcessInputField({
			valueField: this.bindings.first('process')!,
			textField: this.bindings.first('processName')!,
			change: (data) => this.useProcess(data),
			request: (search) => fetchJsonForApiResponse(`/queue/op/selectProcesses?search=${encodeURIComponent(search)}&details=${self.getSelectedDetailsCommaString()}&offset=0&limit=10`)
		})

		this.bindings.update('cancelButton', node => node.addEventListener('click', () => this.dispose()))
		this.bindings.update('saveButton', node => node.addEventListener('click', () => this.save()))
		this.bindings.update('editButton', node => node.addEventListener('click', () => this.setFormMode(DashboardFormMode.EDIT)))

		this.dbDetailsBody.addEventListener('click', e => {
			const target = e.target as HTMLElement
			this.addDbDetailRow(target.closest('tr'));
		})
		this.detailFilterInput.addEventListener('input', () => this.searchDetails(true))
		this.detailFilterInput.addEventListener('change', () => this.searchDetails(true))
		this.coatingSelect.addEventListener('change', () => {
			this.displayFormProcessAndCoating();
			this.searchDetails();
		});

		this.typeSelect.addEventListener('change', () => this.containerTypeChanged());
		this.containerTypeChanged();

		this.selfAreaInput.addEventListener('input', () => this.updateDetailTotals())
		this.selfAreaInput.addEventListener('change', () => this.updateDetailTotals())
		this.detailAreaInput.addEventListener('input', () => this.updateDetailTotals())
		this.detailAreaInput.addEventListener('change', () => this.updateDetailTotals())

		this.searchDetails();
		this.updateDetailTotals();
		this.updateFormButtons();
	}

	get detailFilterInput() {
		return this.bindings.first<HTMLInputElement>('detailFilter')!
	}

	get processActionsContainer() {
		return this.bindings.first<HTMLDivElement>('processActionsContainer')!
	}

	get selfAreaInput() {
		return this.bindings.first<HTMLInputElement>('selfArea')!
	}

	get detailAreaInput() {
		return this.bindings.first<HTMLInputElement>('detailArea')!
	}

	get dbDetailsBody() {
		return this.bindings.first<HTMLTableSectionElement>('dbDetails')!
	}

	get formDetailsBody() {
		return this.bindings.first<HTMLTableSectionElement>('formDetails')!
	}

	get typeSelect() {
		return this.bindings.first<HTMLSelectElement>('type')!
	}

	get coatingSelect() {
		return this.bindings.first<HTMLSelectElement>('coating')!
	}

	get coatingValue() {
		const input = this.coatingSelect
		return input && !isNaN(parseInt(input.value)) ? parseInt(input.value) : null
	}

	get detailWeight() {
		return this.formDetails
			.map(r => r.data.weight ? (r.data.weight * r.data.amount) : 0)
			.filter(r => Number.isFinite(r))
			.reduce((a, b) => a + b, 0)
	}

	dispose() {
		this.element.remove();
	}

	getCoatingNumberString() {
		return numberStringOrEmpty(this.coatingValue);
	}

	private getSelectedDetailsCommaString(): string {
		return numbersToCommaString(this.selectedDetails());
	}

	private selectedDetails(): number[] {
		return this.formDetails.map(e => e.data.id)
	}

	private detailSearchBackendSuccess(backend: DetailSearchBackend) {
		const page = backend.lastQueryResult

		function flatDetail(item: DetailListItem, process: DetailListItem_ProcessLabel | null, coating: DetailListItem_CoatingLabel | null): DetailListItemFlat {
			return {...item, processes: null, coatings: null, process, coating}
		}

		function flatDetails(item: DetailListItem): DetailListItemFlat[] {
			const coatings = item.coatings || []
			if (coatings.length == 0) return [flatDetail(item, null, null)]

			const processMap = new Map<number, DetailListItem_ProcessLabel>()
			coalesceEmpty(item.processes).forEach(process => processMap.set(process.id, process));

			function coatingProcess(id: number | null): DetailListItem_ProcessLabel | null {
				return id != null && Number.isFinite(id) ? coalesce(processMap.get(id), null) : null
			}

			return coatings
				.map(coating => flatDetail(item, coatingProcess(coating.process), coating))
				.sort(flatCompare)
		}

		function flatCompare(a: DetailListItemFlat, b: DetailListItemFlat) {
			const processCompare = coalesce(a.process?.code, '').localeCompare(coalesce(b.process?.code, ''))
			return processCompare != 0 ? processCompare : coalesce(a.coating?.code, '').localeCompare(coalesce(b.coating?.code, ''))
		}

		const onlyCoating = this.coatingValue

		const tbody = this.dbDetailsBody
		tbody.innerHTML = page.content
			.flatMap(flatDetails)
			.filter(item => onlyCoating == null || onlyCoating == item.coating?.id)
			.map(item => `
<tr data-item="${H(JSON.stringify(item))}">
                    <td>${H(item.code)}</td>
                    <td>${H(item.name)}</td>
                    <td class="text-end">${H(item.material)}</td>
                    <td class="text-end">${H(item.area)}</td>
                    <td class="text-end">${H(item.weight)}</td>
                    <td class="text-end">${H(item.process?.code)}</td>
                    <td>${H(item.coating?.code)}</td>
</tr>`)
			.join("")

		toggleReloadingFade(tbody.parentElement!, false)
		this.bindings.update('detailTableShown', node => node.innerText = page.numberOfElements.toString())
		this.bindings.update('detailTableTotal', node => node.innerText = page.totalElements.toString())
	}

	private detailSearchBackendError(backend: DetailSearchBackend) {
		toastFetchError(backend.lastQueryError)
	}

	private useProcess(process: ProcessListItem | null, selectedCoating: number | null = null) {
		return this.populateProcessActions(process)
			.then(() => this.populateProcessCoatings(selectedCoating))
			.then(() => this.displayFormProcessAndCoating())
			.then(() => {
				this.searchDetails();
				return Promise.resolve(true);
			})
			.catch(error => {
				console.error(error)
				toastFetchError(error, 'Произошла ошибка при запросе информации о процессе! Перезагрузите страницу.')
				return Promise.resolve(false)
			})
	}

	private populateProcessActions(process: ProcessListItem | null): Promise<any> {
		const processDefinesContainerType = process && process.containerType && process.containerType != ContainerType.NOT_DEFINED;
		const typeSelectEditable = !processDefinesContainerType && this.formMode != DashboardFormMode.VIEW;
		this.typeSelect.disabled = !typeSelectEditable;

		if (process == null) {
			this.resetStepTable([]);
			return Promise.resolve();
		}

		if (processDefinesContainerType && this.formMode != DashboardFormMode.VIEW) {
			this.typeSelect.value = process.containerType || ''
			this.containerTypeChanged();
		}

		// загружаем и рисуем таблицу конфигурирования шагов
		return fetchJsonForApiResponse(`${AppConfig.CP}/queue/op/queryProcessActions?process=${process.id}`)
			.then(forms => this.resetStepTable(forms))
	}

	private resetStepTable(forms: ContainerForm_ActionForm[]) {
		this.formActions = []

		const baked = forms.map((form, index) => new ContainerFormActionView(this, form, index, this.formMode))

		this.processActionsContainer.innerHTML = `
<div>
    <div class="mb-2"><span class="form-control-text text-nowrap">&nbsp;</span></div>
    <div class="text-center mb-2"><span class="form-control-text w-auto">Покрытие:</span></div>
    <div><span class="form-control-text text-nowrap mb-2">Плотность:</span></div>
    <div><span class="form-control-text text-nowrap mb-2">I расч., А:</span></div>
</div>
        `

		baked.forEach(row => {
			this.formActions.push(row)
			if (row.element != null) {
				this.processActionsContainer.append(row.element)
			}
		})

		toggleHidden(this.processActionsContainer, !baked.some(form => form.element != null))
		this.analyzeCurrentInSteps(true)
	}

	private populateProcessCoatings(selectedCoating: number | null = null): Promise<any> {
		const process = this.processInput.value
		if (process == null) {
			this.setCoatingsForSelect([])
			return Promise.resolve(true);
		}

		return fetchJsonForApiResponse(`${AppConfig.CP}/queue/op/selectCoatings?process=${process}&details=${this.getSelectedDetailsCommaString()}`)
			.then(coatings => this.setCoatingsForSelect(coatings, selectedCoating))
			.catch(error => {
				toastFetchError(error)
				this.setCoatingsForSelect([])
			})
	}

	private setCoatingsForSelect(coatings: CoatingListItem[], selectedCoating: number | null = null) {
		const select = this.bindings.first<HTMLSelectElement>('coating')!
		const selectValue = selectedCoating != null ? selectedCoating : parseInt(select.value);

		select.innerHTML = coatings
			.sort((a, b) => toEmptyString(a.code).localeCompare(toEmptyString(b.code)))
			.map(c => `<option value="${c.id}" data-item="${H(JSON.stringify(c))}">${c.code}</option>`)
			.join("")

		if (coatings.some(c => c.id === selectValue)) {
			select.value = selectValue.toString();
		}

		this.bindings.toggle('coatingSelect', select.childElementCount > 0)
	}

	private displayCoatingThickness() {
		const coatingLabel = Array.from(this.coatingSelect.selectedOptions)
			.map(e => e.getAttribute('data-item'))
			.map(item => item ? (JSON.parse(item) as CoatingListItem) : null)
			.filter(item => item != null)
			[0] || null

		this.formActions
			.filter(row => row.data.dynamic)
			.forEach((row, index) => row.setThickness(coatingLabel == null || coatingLabel.thicknessN == null ? null : coatingLabel.thicknessN[index]))
	}

	private analyzeCurrentInSteps(setCurrentValue: boolean) {
		this.formActions.forEach(row => {
			this.analyzeCurrentInStepsRow(row, setCurrentValue)
		})
	}

	analyzeCurrentInStepsRow(row: ContainerFormActionView, setCurrentValue: boolean) {
		const currentValueInput = row.currentValueInput
		if (!currentValueInput) return

		const selfArea = zeroWhenNaN(parseFloat(this.selfAreaInput.value))
		const detailArea = zeroWhenNaN(parseFloat(this.detailAreaInput.value))
		const totalArea = selfArea + detailArea

		const currentDensityInput = row.currentDensitySelect
		if (currentDensityInput && setCurrentValue) {
			const currentDensity = zeroWhenNaN(parseFloat(currentDensityInput.value))
			currentValueInput.value = Math.ceil(currentDensity * totalArea).toString();
		}

		const maxCurrentValue = coalesce(row.data.maxCurrent, Number.NaN)
		const currentValue = parseFloat(currentValueInput.value)
		currentValueInput.classList.toggle('text-bg-danger', Number.isFinite(currentValue) && Number.isFinite(maxCurrentValue) && currentValue > maxCurrentValue)
	}

	private searchDetails(skipIfSameText: boolean = false) {
		const text = this.detailFilterInput.value
		if (text == this.searchDetailsBackend.lastQueryText && skipIfSameText) {
			return
		}

		toggleReloadingFade(this.dbDetailsBody.parentElement!, true)
		this.searchDetailsBackend.search(text)
	}

	private addDbDetailRow(tr: HTMLTableRowElement | null) {
		if (!tr || this.formMode == DashboardFormMode.VIEW) return;

		const item: DetailListItemFlat = JSON.parse(tr.getAttribute('data-item') || '');
		if (!item) return;

		const detailInfo = {
			id: item.id, amount: 1,
			code: item.code || '', name: item.name, material: item.material,
			area: item.area, weight: item.weight
		}

		if (!this.addContainerDetailRow(detailInfo)) {
			return
		}

		tr.remove();
		this.detailFilterInput.value = '';
		this.assignFormProcessAndCoating(item.process, item.coating)
	}

	/**
	 * После выбора детали мы должны зафиксировать процесс и покрытие (если он пустой, то будет выбран).
	 * Если этот метод вызван после удаления последней детали, то будут переданы null.
	 */
	private assignFormProcessAndCoating(process: DetailListItem_ProcessLabel | null, coating: DetailListItem_CoatingLabel | null) {
		const selected = process != null && coating != null
		toggleDisabled(this.coatingSelect, selected)
		this.processInput.enable(!selected)

		if (process == null || coating == null) {
			this.populateProcessCoatings()
				.then(() => this.displayFormProcessAndCoating())
				.then(() => this.searchDetails())
			return;
		}

		if (this.processInput.value != process.id) {
			this.processInput.set(process.id, process.code, process.name);
			fetchJsonForApiResponse(`${AppConfig.CP}/op/processListItem?id=${process.id}`)
				.then((processListItem: ProcessListItem) => this.useProcess(processListItem, coating.id))
		}
	}

	private displayFormProcessAndCoating() {
		const processTitle = this.processInput.valueText || 'не выбрано'
		const coatingTitle = Array.from(this.coatingSelect.selectedOptions).map(option => option.innerText)[0] || 'не выбрано'
		this.bindings.update('processTitle', node => node.innerText = processTitle)
		this.bindings.update('coatingTitle', node => node.innerText = coatingTitle)
		this.displayCoatingThickness();
		this.updateFormButtons();
	}

	private addContainerDetailRow(item: ContainerForm_DetailInfo): boolean {
		if (this.formDetails.some(e => item.id == e.data.id)) return false

		const row = new ContainerDetailRow(this, item, this.formMode);
		this.formDetailsBody.append(row.element);
		this.formDetails.push(row)
		this.onDetailListChanged();
		return true
	}

	removeContainerDetailRow(row: ContainerDetailRow) {
		this.formDetails = this.formDetails.filter(e => row.data.id != e.data.id)
		row.element.remove()

		if (this.formDetails.length == 0) {
			this.assignFormProcessAndCoating(null, null)
		} else {
			this.searchDetails()
		}

		this.onDetailListChanged();
	}

	private onDetailListChanged() {
		this.updateDetailTotals(true);
		this.updateFormButtons();
	}

	containerTypeChanged() {
		this.bindings.update<HTMLInputElement>('selfArea', node => node.value = '')
		this.updateDetailTotals();
	}

	updateDetailTotals(collectDetailArea: boolean = false) {
		const type = this.typeSelect.value;

		const weightLimit = AppConfig.ContainerTypes.filter(t => t.type == type)[0]?.loadMax
		const areaLimit = AppConfig.ContainerTypes.filter(t => t.type == type)[0]?.area
		this.bindings.update('weightLimit', node => node.innerText = weightLimit ? weightLimit.toString() : '-')

		const selfArea = zeroWhenNaN(parseFloat(this.selfAreaInput.value))
		let detailArea: number;
		if (collectDetailArea) {
			detailArea = this.formDetails
				.map(r => r.data.area ? (r.data.area * r.data.amount) : 0)
				.filter(r => Number.isFinite(r))
				.reduce((a, b) => a + b, 0)
			this.bindings.update<HTMLInputElement>('detailArea', node => node.value = floatTrim5Text(detailArea))
		} else {
			detailArea = zeroWhenNaN(parseFloat(this.detailAreaInput.value))
		}
		this.bindings.update('totalArea', node => {
			node.innerText = floatTrim5Text(selfArea + detailArea);
			node.classList.toggle('text-bg-danger', areaLimit != null && Number.isFinite(areaLimit) && (selfArea + detailArea) > areaLimit)
		})

		const detailWeight = this.detailWeight
		this.bindings.update('totalWeight', node => {
			node.innerText = Math.ceil(detailWeight).toString()
			node.classList.toggle('text-bg-danger', weightLimit != null && Number.isFinite(weightLimit) && detailWeight > weightLimit)
		})

		this.analyzeCurrentInSteps(true);
	}

	private updateFormButtons() {
		const form = this.collectForm();
		const formEditMode = this.formMode == DashboardFormMode.CREATE || this.formMode == DashboardFormMode.EDIT
		const formComplete = form.process != null && form.coating != null && form.details != null && form.details.length > 0;
		this.bindings.update('saveButton', node => toggleDisabled(node, !formComplete));
		this.bindings.toggle('saveButton', formEditMode);
		this.bindings.toggle('cancelButton', formEditMode);
		this.bindings.toggle('editButton', this.formMode == DashboardFormMode.VIEW);

		this.bindings.toggle('titleView', this.formMode == DashboardFormMode.VIEW);
		this.bindings.toggle('titleEdit', this.formMode == DashboardFormMode.EDIT);
		this.bindings.toggle('titleCreate', this.formMode == DashboardFormMode.CREATE);

		this.bindings.toggle('containerNumber', this.formContainer > 0);
		this.bindings.update<HTMLAnchorElement>('containerNumber', node => {
			node.innerText = `№${this.formContainer}`
			node.href = `${AppConfig.CP}/queue/${this.formContainer}`
		});
	}

	postSubmit() {
	}

	save() {
		const progressList = this.bindings.select('saveButton').map(node => buttonProgress(node))
		this.bindings.select('cancelButton').map(node => toggleDisabled(node, true))

		const action = this.formMode == DashboardFormMode.EDIT
			? `${AppConfig.CP}/queue/${this.formContainer}/edit`
			: `${AppConfig.CP}/queue/create`

		apiPostForAjaxResponse(action, this.collectForm())
			.then(() => {
				showToastSuccess(messages.get('containerForm.submitSuccess'));
				this.postSubmit()
			})
			.catch(result => {
				if (!isAjaxResponseErrors(result)) {
					toastFetchError(result)
					return;
				}

				const errors = (result as AjaxResponseWithErrors).errors

				function errorText(e: AjaxResponseWithErrors_ObjectError) {
					const messageIndexed = `${e.code}.indexed`
					if ('field' in e && messages.has(messageIndexed)) {
						const fieldError = e as AjaxResponseWithErrors_FieldError
						const index = /\[(\d+)]/.exec(fieldError.field)
						if (index) {
							return messages.get(messageIndexed, parseInt(index[1]) + 1);
						}
					}
					return e.message;
				}

				const errorDisplayed = new Set<string>()
				const errorDisplay = errors
					.map(e => `<li>${H(errorText(e))}</li>`)
					.filter(e => !errorDisplayed.has(e))
					.map(e => lastArg(errorDisplayed.add(e), e))
					.join("")

				const {modal} = bootstrapModal({
					modalClass: 'modal-lg ',
					contentClass: 'modal-content-alert modal-content-alert-danger',
					title: messages.get('containerForm.submitFailure'),
					body: `<ul class="m-0">${errorDisplay}</ul>`,
					buttonOk: null,
					buttonCancel: messages.get('button.close')
				})
				modal.show();
			})
			.then(() => {
				progressList.forEach(p => p.stop())
				this.bindings.select('cancelButton').map(node => toggleDisabled(node, false))
			})
	}

	private collectForm(): ContainerForm {
		const init: any = {};
		if (this.formInit != null) {
			init.programId = this.formInit.programId;
			init.programType = this.formInit.programType;
			init.programState = this.formInit.programState;
		}

		const local = {
			type: this.typeSelect.value as ContainerType,
			detailArea: nullWhenNaN(parseFloat(this.detailAreaInput.value)),
			detailWeight: nullWhenNaN(this.detailWeight),
			selfArea: nullWhenNaN(parseFloat(this.selfAreaInput.value)),
			process: this.processInput.value,
			coating: this.coatingValue,
			details: this.formDetails.map(d => d.collect()),
			actions: this.formActions.map(a => a.collect())
		}

		return Object.assign(init, local);
	}

	initWithForm(id: number, form: ContainerForm): Promise<DashboardForm> {
		this.formInit = form
		this.formContainer = id
		this.setFormMode(DashboardFormMode.VIEW);

		this.typeSelect.value = form.type || ''
		this.selfAreaInput.value = (form.selfArea || 0).toString()

		if (form.processValue) {
			this.processInput.set(form.processValue.id, form.processValue.code, form.processValue.name);
			toggleDisabled(this.coatingSelect, true)
			this.processInput.enable(false)
		}

		return this
			.useProcess(form.processValue || null, form.coating)
			.then(() => {
				form.details.forEach(d => this.addContainerDetailRow(d))

				this.detailAreaInput.value = (form.detailArea || 0).toString()
				this.updateDetailTotals();

				this.updateFormButtons();
				this.searchDetails(false);
				return this;
			});
	}

	private setFormMode(formMode: DashboardFormMode) {
		this.formMode = formMode
		this.updateFormButtons();

		const disable = formMode == DashboardFormMode.VIEW

		this.bindings.update('selfArea', node => toggleDisabled(node, disable));
		this.bindings.update('detailArea', node => toggleDisabled(node, disable));
		this.formDetails.forEach(row => row.inMode(this.formMode));
		this.formActions.forEach(row => row.inMode(this.formMode));

		let typeSelectDisable = disable
		if (this.formInit && this.formInit.processValue && !(this.formInit.processValue.containerType == null || this.formInit.processValue.containerType == ContainerType.NOT_DEFINED)) {
			typeSelectDisable = true;
		}
		this.bindings.update('type', node => toggleDisabled(node, typeSelectDisable));
	}
}

class ContainerDetailRow {
	private readonly form: DashboardForm;
	readonly data: ContainerForm_DetailInfo;
	readonly element: HTMLTableRowElement;
	private readonly binding: BindingList;

	constructor(form: DashboardForm, data: ContainerForm_DetailInfo, formMode?: DashboardFormMode) {
		this.form = form
		this.data = data

		this.element = createHtmlElementFromHtml(`
<tr class="align-baseline">
    <td class="">${H(data.code)}</td>
    <td class="">${H(data.name)}</td>
    <td class="text-nowrap text-center" data-bind="area">${H(data.area)}</td>
    <td class="text-nowrap text-center" data-bind="weight">${H(data.weight)}</td>
    <td class="text-nowrap text-center">
        <button type="button" class="btn btn-secondary" data-bind="amountDec">-</button>
        <input type="number" class="form-control w-4em d-inline-block mx-1 text-center" step="1" min="1" name="amount" value="1">
        <button type="button" class="btn btn-secondary" data-bind="amountInc">+</button>
    </td>
    <td class="text-nowrap text-center"><span data-bind="totalArea">${H(data.area)}</span></td>
    <td class="w-4em text-center"><button type="button" class="btn btn-secondary text-danger" data-bind="removeDetail">x</button></td>
</tr>
      `, 'tbody');

		this.binding = new BindingList()
		this.binding.collect(this.element);
		this.binding.collectByName(this.element);

		this.binding.update('amountInc', node => node.addEventListener('click', this.amountInc.bind(this)))
		this.binding.update('amountDec', node => node.addEventListener('click', this.amountDec.bind(this)))
		this.binding.update('amount', node => node.addEventListener('change', this.amountSet.bind(this)))
		this.binding.update('amount', node => node.addEventListener('input', this.amountSet.bind(this)))
		this.binding.update('removeDetail', node => node.addEventListener('click', this.removeItem.bind(this)))

		if (formMode !== undefined) {
			this.inMode(formMode)
		}
	}

	private get amountField() {
		return this.binding.first<HTMLInputElement>('amount')!
	}

	amountInc() {
		this.data.amount = parseInt(this.amountField.value || '0') + 1
		this.amountField.value = this.data.amount.toString()
		this.calculateDetailTotals();
	}

	amountDec() {
		this.data.amount = Math.max(1, parseInt(this.amountField.value || '0') - 1)
		this.amountField.value = this.data.amount.toString()
		this.calculateDetailTotals();
	}

	amountSet() {
		this.data.amount = parseInt(this.amountField.value || '0')
		this.calculateDetailTotals();
	}

	calculateDetailTotals() {
		const totalArea = floatTrim5Text((this.data.area || 0) * this.data.amount)
		this.binding.update('totalArea', node => node.innerText = totalArea)
		this.form.updateDetailTotals(true)
	}

	removeItem() {
		this.form.removeContainerDetailRow(this)
	}

	collect() {
		this.data.amount = parseInt(this.amountField.value || '0');
		return this.data;
	}

	inMode(mode: DashboardFormMode) {
		const disable = mode == DashboardFormMode.VIEW;
		['amountInc', 'amountDec', 'removeDetail'].forEach(field => this.binding.update(field, node => toggleVisibilityHidden(node, disable)));
		['amount'].forEach(field => this.binding.update(field, node => toggleDisabled(node, disable)));
	}
}

class ContainerFormActionView {
	readonly index: number
	readonly data: ContainerForm_ActionForm

	readonly element: HTMLElement | null;
	private readonly bindings = new BindingList();

	private readonly form: DashboardForm

	constructor(form: DashboardForm, data: ContainerForm_ActionForm, index: number, formMode?: DashboardFormMode) {
		this.form = form
		this.index = index
		this.data = data

		if (!data.needCurrentDensity) {
			this.element = null;
			return;
		}

		let coatingLabel = ifEmpty(this.data.positionCoating, toEmptyString(this.data.positionShortLabel).substring(0, 5).trim())
		coatingLabel = coatingLabel.length ? H(coatingLabel) : '&nbsp;'

		const {currentDensityMin, currentDensityMax} = data

		const densityDefault: number | null = currentDensityMin != null && currentDensityMax != null && currentDensityMin == currentDensityMax
			? currentDensityMin
			: null;

		const densityDefaultList: number[] | string = form.allowedDensities
			.filter(n => n != null && (currentDensityMin == null || currentDensityMin <= n) && (currentDensityMax == null || n <= currentDensityMax))
			.map(n => `<option value="${n}" ${densityDefault == n ? 'selected' : ''}>${n}</option>`)
			.join("")
		const densityInput = `<select class="form-select w-auto d-inline-block text-end" name="actions[${index}].currentDensity" data-bind="currentDensity">${densityDefaultList}</select>`

		const currentMaxTooltip = data.maxCurrent ? `Максимальный ток - ${data.maxCurrent}А` : ''
		const currentInput = `<input type="number" step="any" class="form-control w-4em d-inline-block text-center" name="actions[${index}].currentValue" data-bind="currentValue" required title="${H(currentMaxTooltip)}">`

		this.element = createHtmlElementFromHtml(`
<div class="ms-1" data-index="${index}">
    <div class="text-center mb-2"><span class="form-control-text w-auto">${coatingLabel}</span></div>
    <div class="text-center mb-2"><span class="form-control w-3em mx-auto disabled" data-bind="coatingThickness">&nbsp;</span></div>
    <div class="text-center mb-2">${densityInput}</div>
    <div class="text-center mb-2">${currentInput}</div>
</div>`)

		this.bindings.collect(this.element);

		this.currentDensitySelect?.addEventListener('change', () => this.form.analyzeCurrentInStepsRow(this, true))
		this.currentValueInput?.addEventListener('change', () => this.form.analyzeCurrentInStepsRow(this, false))
		this.currentValueInput?.addEventListener('input', () => this.form.analyzeCurrentInStepsRow(this, false))

		if (formMode !== undefined) {
			this.inMode(formMode)
		}
	}

	collect() {
		if (this.currentDensitySelect) {
			this.data.currentDensity = nullWhenNaN(parseFloat(this.currentDensitySelect.value))
		}
		if (this.currentValueInput) {
			this.data.currentValue = nullWhenNaN(parseFloat(this.currentValueInput.value))
		}
		return this.data
	}

	get currentValueInput(): HTMLInputElement | null {
		return this.bindings.first('currentValue')
	}

	get currentDensitySelect(): HTMLSelectElement | null {
		return this.bindings.first('currentDensity')
	}

	setThickness(n: number | null) {
		this.bindings.update('coatingThickness', node => node.innerHTML = n != null ? n.toString() : `&nbsp;`)
	}

	inMode(mode: DashboardFormMode) {
		const disable = mode == DashboardFormMode.VIEW;
		['currentDensity', 'currentValue'].forEach(field => this.bindings.update(field, node => toggleDisabled(node, disable)));
	}
}