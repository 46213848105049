export enum TransportType {
    UNKNOWN = "UNKNOWN",
    AO = "AO",
    TRANSFER = "TRANSFER"
}


export enum TransportState {
    DISABLED = "DISABLED",
    STOPPED = "STOPPED",
    TAKING = "TAKING",
    PUTTING = "PUTTING",
    MOVING = "MOVING"
}
