export function coalesce<T>(item: T | null | undefined, nullValue: T): T {
    return item === undefined || item == null ? nullValue : item;
}

export function coalesceEmpty<T>(array: T[] | null | undefined): T[] {
    return coalesce(array, [])
}

export function toEmptyString(item: string | null | undefined): string {
    return coalesce(item, '');
}

export function ifEmpty(item: string | null | undefined, other: string): string {
    item = toEmptyString(item);
    return item.length == 0 ? other : item;
}

export function numberStringOrEmpty(n?: number | null): string {
    return typeof n == 'number' && Number.isFinite(n) ? n.toString() : ''
}

export function numbersToCommaString(list: number[]) {
    return list.map(n => n.toString()).join(",")
}

export function zeroWhenNaN(n: number): number {
    return Number.isFinite(n) ? n : 0
}

export function nullWhenNaN(n?: number): number {
    return n != null && Number.isFinite(n) ? n : 0
}

/**
 * Иногда нам надо так делать, потому что в некоторых случаях мы можем получить сумму вроде "101.46000000000001" (результат суммы 100.06+1.4),
 * а хочется показать только "101.46"
 * */
export function floatTrim5Text(n: number): string {
    if (!Number.isFinite(n)) return '';
    return parseFloat(n.toFixed(5)).toString();
}