import {SvgView} from "./SvgView";
import {ContainerView} from "./Containers";
import {PositionView} from "./Positions";
import {SchemaPlayer, SchemaPlayerTrack} from "./SchemaPlayer";
import {TransportState} from "../dto/com.rico.sb2.entity.device";
import {PlayerData_Transport} from "../dto/com.rico.sb2.service.positions";
import {zeroWhenNaN} from "../lib/coalesce";

const AO_CONTAINER_GAP = 2;
const AO_LINE_GAP = 15;

function numStringOrBlank(n: number | null) {
    return n == null ? '' : n.toString()
}

export interface TransportViewState {
    readonly data: PlayerData_Transport

    state: TransportState | null
    position: number | null
    targetPosition: number | null

    container: ContainerView | null
}

export interface TransportView extends TransportViewState {
    moveToSelf(svg: SvgView): void

    setAtPosition(position: PositionView | null): void

    update(player: SchemaPlayer): void
}

interface AoTrack {
    from: number
    to: number
    line: SchemaPlayerTrack
}

export class AoView extends SvgView implements TransportView {
    readonly data: PlayerData_Transport

    state: TransportState | null = null
    position: number | null = null
    targetPosition: number | null = null

    container_: ContainerView | null = null

    track: AoTrack | null = null

    private lineY: number

    constructor(data: PlayerData_Transport, view: SVGElement | null) {
        super(view)
        this.data = data
        this.state = data.enabled ? null : TransportState.DISABLED
        this.position = data.position
        this.lineY = this.y
        this.bind('label', data.number.toString());
        this.updateLocation();
    }

    get lineYOffset() {
        return this.height + AO_LINE_GAP + (this.container == null ? 0 : this.container.height);
    }

    get trackPointY(): number {
        const trackPoint = this.view?.querySelector('[data-bind="trackPoint"]');
        if (!trackPoint) return 0;
        return zeroWhenNaN(parseFloat(trackPoint?.getAttribute("y") || '0'));
    }

    updateLocation() {
        if (Number.isFinite(this.lineY)) {
            this.y = this.lineY - this.lineYOffset
        }
        super.updateLocation();
    }

    get container() {
        return this.container_
    }

    set container(v: ContainerView | null) {
        this.container_ = v
        this.updateLocation()
    }

    update(player: SchemaPlayer) {
        this.view?.classList.toggle('enabled', this.state != TransportState.DISABLED)
        this.view?.classList.toggle('moving', this.state == TransportState.MOVING)
        this.view?.classList.toggle('loading', this.state == TransportState.TAKING)
        this.view?.classList.toggle('unloading', this.state == TransportState.PUTTING)
        this.bind('moveFrom', numStringOrBlank(this.position))
        this.bind('moveTo', numStringOrBlank(this.targetPosition))
        this.updateLocation()

        if (this.position != null && this.targetPosition != null) {
            this.updateTrack(player, this.position, this.targetPosition)
        } else {
            this.clearTrack();
        }
    }

    private updateTrack(player: SchemaPlayer, from: number, to: number) {
        if (this.track != null && this.track.from === from && this.track.to === to) return;

        this.clearTrack();

        const line = player.createAoTrack(this, from, to)
        if (line) {
            this.track = {from, to, line}
        }
    }

    private clearTrack() {
        if (this.track == null) return;
        this.track.line.remove()
        this.track = null
    }

    moveToSelf(svg: SvgView) {
        svg.toCenterX(this.centerX)
        svg.toY(this.y + this.height + AO_CONTAINER_GAP)
    }

    setAtPosition(position: PositionView | null) {
        if (position != null) {
            this.lineY = position.lineY
            this.toCenterX(position.centerX)
        }
        if (this.container != null) {
            this.moveToSelf(this.container)
        }
    }
}

export class TransferView extends SvgView implements TransportView {
    readonly data: PlayerData_Transport

    state: TransportState | null = null
    position: number | null = null
    targetPosition: number | null = null

    container: ContainerView | null = null

    positionView: PositionView | null = null

    constructor(data: PlayerData_Transport, view: SVGElement | null) {
        super(view)
        this.data = data
        this.state = data.enabled ? null : TransportState.DISABLED
        this.position = data.position
        this.bind('label', data.number.toString());
    }

    update(player: SchemaPlayer) {
        this.view?.classList.toggle('enabled', this.state != TransportState.DISABLED)
        this.view?.classList.toggle('active', this.state == TransportState.MOVING)

        const lineFrom = this.position == null ? null : player.getPositionState(this.position)?.data.line
        const lineTo = this.targetPosition == null ? null : player.getPositionState(this.targetPosition)?.data.line
        this.view?.classList.toggle('moving-up', lineFrom != null && lineTo != null && lineTo < lineFrom);
        this.view?.classList.toggle('moving-down', lineFrom != null && lineTo != null && lineTo > lineFrom);
    }

    moveToSelf(svg: SvgView) {
        if (this.positionView != null) {
            svg.toCenterX(this.centerX)
            svg.toY(this.y)
        }
    }

    setAtPosition(positionView: PositionView | null) {
        this.positionView = positionView

        if (positionView != null) {
            this.bind('label', positionView.data.id.toString());
            this.toCenterX(positionView.centerX)
            this.toY(positionView.y)
        }

        if (this.container != null) {
            this.moveToSelf(this.container)
        }
    }
}