import {RectifierProfileOption} from "../dto/com.rico.sb2.service.process.dto";
import {apiGetForAjaxResponse, apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {toastFetchError} from "../lib/fetch";
import {bootstrapModal} from "../lib/bootstrapModal";
import {Messages} from "../messages/Messages";
import {buttonProgress} from "../lib/buttonProgress";
import {showToastSuccess} from "../lib/boostrapToast";
import {escapeHTML} from "../lib/escapeHTML";
import {toggleHidden} from "../lib/domFunctions";

const messages = new Messages();

export class StartRectifier {
	private readonly positionId: number;
	private readonly rectifierProfiles: RectifierProfileOption[];

	constructor(positionId: number, rectifierNumber: number, rectifierProfiles: RectifierProfileOption[]) {
		this.positionId = positionId;
		this.rectifierProfiles = rectifierProfiles;
	}

	static async showAsync(position: number): Promise<void> {
		return apiGetForAjaxResponse(`${AppConfig.CP}/op/getStartRectifierForm?position=${position}`)
			.then(form => new StartRectifier(form.position, form.rectifierNumber, form.rectifierProfiles).show())
			.catch(toastFetchError)
	}

	show() {
		const {modal, content, buttonOk} = bootstrapModal({
			title: messages.get('StartRectifier.title', this.positionId),
			body: this.renderBody(),
			buttonOk: messages.get('button.turnOn')
		});

		const startTypeSelect = content.querySelector<HTMLSelectElement>('[name="startType"]')!!
		const startProfileSelect = content.querySelector<HTMLSelectElement>('[name="startProfile"]')!!
		const startValueInput = content.querySelector<HTMLInputElement>('[name="startValue"]')!!

		function updateUi() {
			const tags = [startTypeSelect.value];

			if (startTypeSelect.value == "PROFILE") {
				Array.from(startProfileSelect.selectedOptions).map(e => e.getAttribute('data-profile-type'))
					.filter(e => e)
					.map(e => e?.trim()).forEach(e => {
					tags.push(`${startTypeSelect.value}_${e}`)
				})
			}

			content.querySelectorAll('[data-ui-when]').forEach(e => {
				const visible = (e.getAttribute('data-ui-when') || '').split(',')
					.map(e => e.trim())
					.some(e => tags.includes(e))
				toggleHidden(e, !visible)
			})
		}

		startTypeSelect.addEventListener('change', () => {
			updateUi();
			startValueInput.value = '';
		});
		startProfileSelect.addEventListener('change', () => {
			updateUi();
			startValueInput.value = '';
		});

		updateUi();
		modal.show();

		const ok = buttonOk!!;
		ok.addEventListener('click', () => {
			const progress = buttonProgress(ok);

			let startType: string | null = startTypeSelect.value;
			let startProfile = null;
			let startValue = startValueInput.value;

			if (startType === 'PROFILE') {
				startType = null
				startProfile = startProfileSelect.value
			}

			apiPostForAjaxResponse(`${AppConfig.CP}/op/startRectifier`, {position: this.positionId, startProfile, startType, startValue})
				.then(() => {
					showToastSuccess(messages.get('StartRectifier.completeNotice'))
					modal.hide()
				})
				.catch(toastFetchError)
				.finally(() => progress.stop());
		})
	}

	private renderBody(): string {
		const profileOptions = this.rectifierProfiles
			.filter(e=> e.type !== 'BUILT_IN')
			.sort((a, b) => a.name.localeCompare(b.name))
			.map(e => `<option value="${e.id}" data-profile-type="${e.type}">${escapeHTML(e.name)}</option>`)
			.join('');
		return `
<div>
	<label class="col-form-label">Режим запуска</label>
	<select name="startType" class="form-select mb-3">
		<option value="PROFILE">${messages.get('StartRectifier.form.type.PROFILE')}</option>
		<option value="BUILT_IN">${messages.get('StartRectifier.form.type.BUILT_IN')}</option>
		<option value="CURRENT">${messages.get('StartRectifier.form.type.CURRENT')}</option>
		<option value="VOLTAGE">${messages.get('StartRectifier.form.type.VOLTAGE')}</option>
	</select>
</div>
<div data-ui-when="PROFILE">
	<label class="col-form-label">Профиль выпрямителя</label>
	<select name="startProfile" class="form-select mb-3">${profileOptions}</select>
</div>
<div>
	<label class="col-form-label">
		<span data-ui-when="CURRENT,PROFILE_CURRENT">Ток, А</span>
		<span data-ui-when="VOLTAGE,PROFILE_VOLTAGE">Напряжение, В</span>
		<span data-ui-when="BUILT_IN,PROFILE_BUILT_IN">Номер программы</span>
	</label>
	<input type="number" class="form-control" name="startValue" required>
</div>
`;
	}
}
