export function currentToString(n: number, suffix: string = '') {
    if (n >= 0 && n <= 10) {
        n = Math.round(n * 10) / 10
    } else {
        n = Math.round(n)
    }
    return n.toString() + suffix;
}

export function voltageToString(n: number, suffix: string = '') {
    n = Math.round(n * 10) / 10
    return n.toString() + suffix;
}

export function temperatureToString(n: number, suffix: string = '') {
    n = Math.round(n * 10) / 10
    return n.toString() + suffix;
}

export function addSuffixToText(text: string, suffix: string) {
    if (text.length == 0) return text
    return text + suffix
}