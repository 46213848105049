export enum ContainerState {
    EMPTY = "EMPTY",
    PLANNED = "PLANNED",
    LOADING = "LOADING",
    PROCESSING = "PROCESSING",
    WAITING = "WAITING",
    UNLOADING = "UNLOADING",
    FINISHED = "FINISHED",
    BLOCKED = "BLOCKED"
}

export const ContainerStateValues = Object.keys(ContainerState)

export enum ContainerType {
    NOT_DEFINED = "NOT_DEFINED",
    SUSPENSION = "SUSPENSION",
    CYLINDER = "CYLINDER"
}

export const ContainerTypeValues = Object.keys(ContainerType)