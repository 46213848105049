export const SVG_NAMESPACE = "http://www.w3.org/2000/svg"

export function byId<T extends HTMLElement>(id: string): T {
    return document.getElementById(id) as T
}

export function toggleById<T extends HTMLElement>(id: string, visible: boolean) {
    byId(id)?.classList.toggle('hidden', !visible)
}

export function toggleHidden(el: Element | null, hidden: boolean) {
    el?.classList.toggle('hidden', hidden)
    return hidden;
}

export function hasHidden(el: Element | null) {
    if (!el) return false;
    return el.classList.contains('hidden')
}

export function hasNoHidden(el: Element | null) {
    if (!el) return true;
    return !el.classList.contains('hidden')
}

export function toggleDisabled(el: HTMLElement, disabled: boolean) {
    el.classList.toggle('disabled', disabled);
    if (disabled) {
        el.setAttribute('disabled', 'disabled')
    } else {
        el.removeAttribute('disabled')
    }
}

export function toggleVisibilityHidden(el: Element | null, hidden: boolean) {
    el?.classList.toggle('visibility-hidden', hidden)
}

export function toggleReloadingFade(el: Element, on: boolean) {
    el.classList.toggle('reloading-fade', on)
}

export function querySelectorWithName<T extends Element>(parent: Element, querySelector: string, nameTest: RegExp): T | null {
    const found = querySelectorAllWithName<T>(parent, querySelector, nameTest);
    return found.length == 1 ? found[0] : null
}

export function querySelectorAllWithName<T extends Element>(parent: Element, querySelector: string, nameTest: RegExp): T[] {
    return Array
        .from(parent.querySelectorAll<T>(querySelector))
        .filter(node => {
            const name = node.getAttribute("name");
            return name != null && nameTest.test(name)
        });
}

export function reloadPage() {
    document.location.reload()
}

function setElementAttributes(element: Element, attributes: { [key: string]: any }) {
    Object.keys(attributes).forEach(key => {
        const value = attributes[key];
        if (typeof value === 'string') {
            element.setAttribute(key, value)
        } else if (typeof value === 'function') {
            (element as any)[key] = value
        } else if (value !== undefined && value != null) {
            element.setAttribute(key, value.toString())
        }
    });
}

export function createHtmlElement<K extends keyof HTMLElementTagNameMap>(name: K, attributes: { [key: string]: any }, html: string = ''): HTMLElementTagNameMap[K] {
    const element = document.createElement(name)
    setElementAttributes(element, attributes)
    element.innerHTML = html
    return element;
}

export function createHtmlElementFromHtml<T extends HTMLElement>(html: string, parentElement: string = 'div'): T {
    const element = document.createElement(parentElement)
    element.innerHTML = html
    return element.firstElementChild as T;
}

export function createSvgElement(name: string, attributes: { [key: string]: any } = {}): SVGElement {
    const element = document.createElementNS(SVG_NAMESPACE, name)
    setElementAttributes(element, attributes)
    return element;
}

export function createAnchorAndClick(href: string, _blank: boolean = false) {
    const attrs = _blank ? `target="_blank"` : ''
    createHtmlElementFromHtml(`<a ${attrs} href="${href}"></a>`).click()
}

export function hiddenClassIf(condition: boolean) {
    return condition ? ' hidden ' : '';
}

export class BindingList {
    private readonly references = new Map<string, HTMLElement[]>()

    collect(parentElement: HTMLElement, clearCurrent: boolean = false) {
        if (clearCurrent) {
            this.references.clear()
        }

        this.collectOne(parentElement);
        Array
            .from(parentElement.querySelectorAll<HTMLElement>(`[data-bind]`))
            .forEach(node => this.collectOne(node));
    }

    collectOne(element: HTMLElement) {
        const bind = element.getAttribute('data-bind');
        if (!bind) return;

        if (!this.references.has(bind)) this.references.set(bind, [])
        this.references.get(bind)!.push(element);
    }

    collectByName(parentElement: Element, clearCurrent: boolean = false) {
        if (clearCurrent) {
            this.references.clear()
        }

        Array
            .from(parentElement.querySelectorAll<HTMLElement>(`[name]`))
            .forEach(node => {
                const bind = node.getAttribute('name');
                if (!bind) return;

                if (!this.references.has(bind)) this.references.set(bind, [])
                this.references.get(bind)!.push(node)
            })
    }

    private getBind(bindAttr: string): HTMLElement[] | [] {
        return this.references.get(bindAttr) || [];
    }

    select<T extends HTMLElement>(bindAttr: string): T[] {
        return (this.getBind(bindAttr) as T[]) || [];
    }

    update<T extends HTMLElement>(bindAttr: string, callback: (element: T) => void) {
        const list = this.getBind(bindAttr)
        if (!list) return
        list.forEach(node => callback(node as T))
    }

    toggle<T extends HTMLElement>(bindAttr: string, visible: boolean) {
        this.update(bindAttr, node => toggleHidden(node, !visible))
    }

    none<T extends HTMLElement>(bindAttr: string, test: (element: T) => boolean) {
        const list = this.getBind(bindAttr)
        if (!list) return false
        return !list.some(el => test(el as T))
    }

    noneEvery<T extends HTMLElement>(bindAttrList: string[], test: (element: T) => boolean) {
        return bindAttrList.every(bindAttr => this.none(bindAttr, test))
    }

    all<T extends HTMLElement>(bindAttr: string, test: (element: T) => boolean) {
        const list = this.getBind(bindAttr)
        if (!list) return false
        return list.every(el => test(el as T))
    }

    allEvery<T extends HTMLElement>(bindAttrList: string[], test: (element: T) => boolean) {
        return bindAttrList.every(bindAttr => this.all(bindAttr, test))
    }

    some<T extends HTMLElement>(bindAttr: string, test: (element: T) => boolean) {
        const list = this.getBind(bindAttr)
        if (!list) return false
        return list.some(el => test(el as T))
    }

    someAny<T extends HTMLElement>(bindAttrList: string[], test: (element: T) => boolean) {
        return bindAttrList.some(bindAttr => this.some(bindAttr, test))
    }

    first<T extends HTMLElement>(bindAttr: string): T | null {
        const list = this.getBind(bindAttr)
        if (!list) return null
        return list[0] as T
    }
}


export function addOneShotListener(el: Element, event: string, listener: EventListenerOrEventListenerObject) {
    const oneShot = (e: Event) => {
        el.removeEventListener(event, oneShot);
        if ('handleEvent' in listener) {
            return (listener as EventListenerObject).handleEvent(e)
        } else {
            return listener(e)
        }
    };
    el.addEventListener(event, oneShot);
}

export function setInnerTextAndTitle(node: HTMLElement, text: string) {
    node.innerText = text
    node.setAttribute('title', text)
}
