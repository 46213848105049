import {bootstrapModal} from "../lib/bootstrapModal";
import {Messages} from "../messages/Messages";
import {apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {toastFetchError} from "../lib/fetch";
import {showToastInfo} from "../lib/boostrapToast";

const messages = new Messages();

export class SendTransportPut {
	private readonly transportId: number;

	constructor(transportId: number) {
		this.transportId = transportId;
	}

	execute(): Promise<void> {
		return new Promise((resolve, reject) => {
			apiPostForAjaxResponse(`${AppConfig.CP}/op/transportPut`, {transport: this.transportId})
				.then(success => {
					if (!success) return Promise.reject(500)

					showToastInfo(messages.get('SendTransportPut.completeNotice'))
					resolve()
				})
				.catch(toastFetchError)
				.then(() => reject());
		})
	}

	showModal(completer?: Function) {
		const {modal, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
			title: messages.get('SendTransportPut.confirmNotice', this.transportId),
			body: '',
			buttonOk: messages.get('boolean.yes')
		});
		modal.show();

		const ok = buttonOk!!;

		ok.addEventListener('click', () => {
			buttonOkClickStart();

			this.execute()
				.then(() => {
					modal.hide()
					if (completer) completer()
				})
				.catch(buttonOkClickFinish)
		})
	}
}