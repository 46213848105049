export enum ProcessControlService_Mode {
    UNKNOWN = "UNKNOWN",
    AUTOMATIC = "AUTOMATIC",
    SEMIAUTOMATIC = "SEMIAUTOMATIC",
    MANUAL = "MANUAL",
    PAUSED = "PAUSED",
    PLC = "PLC",
    STOPPED = "STOPPED"
}

export interface ProcessControlService_Status {
    mode: ProcessControlService_Mode,
    programs: number
    commands: number
    lastFinishTime: string | null
}

export interface TelemetryMessageProcessor_BathTelemetry {
    id: number
    temp: number | null
    voltage: number | null
    current: number | null
}