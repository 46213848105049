import {escapeHTML} from "../lib/escapeHTML";
import {Messages} from "../messages/Messages";
import {Modal} from "bootstrap";
import {createHtmlElement, toggleHidden, toggleReloadingFade} from "../lib/domFunctions";
import {fetchJson} from "../lib/fetch";
import {AppConfig} from "../AppConfig";
import {CoatingListItem} from "../dto/com.rico.sb2.service.details";

const messages = new Messages();

export type CoatingInputFieldChange = (data: CoatingListItem | null) => void

export class CoatingInputField {
    private readonly valueField: HTMLInputElement;
    private readonly textField: HTMLInputElement;
    private readonly fireChange: CoatingInputFieldChange;

    constructor(valueField: HTMLInputElement, textField: HTMLInputElement, change: CoatingInputFieldChange) {
        this.valueField = valueField
        this.textField = textField
        this.fireChange = (data) => {
            this.toggleButtons(data)
            change(data);
        }

        const group = this.uiGroup
        if (group) {
            group.querySelectorAll('[data-bind="clearCoating"]').forEach(node => node.addEventListener('click', this.clear.bind(this)))
            group.querySelectorAll('[data-bind="searchCoating"]').forEach(node => node.addEventListener('click', this.search.bind(this)))
        }
    }

    private get uiGroup() {
        return this.textField.closest('.input-group');
    }

    private toggleButtons(data: CoatingListItem | null) {
        const selected = data != null && data.id > 0
        const group = this.textField.closest('.input-group');
        if (!group) return;

        group.querySelectorAll('[data-bind="clearCoating"]').forEach(node => node.classList.toggle('hidden', !selected))
        group.querySelectorAll('a[data-bind="openCoating"]').forEach(node => {
            node.classList.toggle('hidden', !selected)
            node.setAttribute('href', selected ? `${AppConfig.CP}/coatings/${data.id}/edit` : '')
        })
    }

    clear() {
        this.valueField.value = '';
        this.textField.value = '';
        this.fireChange(null)
    }

    search() {
        new CoatingInputModalSearch((data: CoatingListItem | null) => {
            if (data == null) return
            if (data.id.toString() === this.valueField.value) return

            this.valueField.value = data.id.toString()
            this.textField.value = `${data.code} (${data.process} ${data.processName})`
            this.fireChange(data)
        });
    }
}

class CoatingInputModalSearch {
    private readonly receiver: CoatingInputFieldChange
    private readonly modal: Modal

    private readonly searchField: HTMLInputElement;
    private readonly searchEmpty: Element;
    private readonly searchTable: Element;
    private readonly searchNotice: HTMLElement;

    private readonly queryTimeout = 300
    private readonly queryMinLetters = 1

    private lastQueryNumber = 0
    private lastQueryText = ''
    private lastQueryResult: CoatingListItem[] = []

    constructor(receiver: CoatingInputFieldChange) {
        this.receiver = receiver

        const content = createHtmlElement('div', {'tab-index': '-1', class: 'modal modal-lg'}, `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <h5 class="modal-title" id="exampleModalLabel">${escapeHTML(messages.get('CoatingInputField.searchTitle'))}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body pt-0">
        <input type="text" class="form-control" data-bind="searchField" placeholder="${escapeHTML(messages.get('CoatingInputField.searchPlaceholder'))}">
        
        <div class="text-muted py-5 text-center hidden" data-bind="searchEmpty">
            ${escapeHTML(messages.get('CoatingInputField.searchEmpty'))}
        </div>
        
        <table class="table table-borderless table-hover mt-3 mb-0" data-bind="searchTable">
            <thead>
                <tr>
                    <th class="text-nowrap">${escapeHTML(messages.get('CoatingInputField.table.code'))}</th>
                    <th class="text-nowrap w-100">${escapeHTML(messages.get('CoatingInputField.table.process'))}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody></tbody>        
        </table>
        <div class="hidden mt-3 text-muted text-center" data-bind="searchNotice"></div>
      </div>
    </div>
  </div>
`)

        this.searchField = content.querySelector('input[data-bind=searchField]')!!
        this.searchEmpty = content.querySelector('div[data-bind=searchEmpty]')!!
        this.searchTable = content.querySelector('table[data-bind=searchTable]')!!
        this.searchNotice = content.querySelector('div[data-bind=searchNotice]')!!

        this.searchField.addEventListener('input', () => this.scheduleQuery());
        this.searchTable.addEventListener('click', (e) => this.onTableClick(e));

        this.modal = new Modal(content)
        this.modal.show();

        this.scheduleQuery(true)
    }

    private scheduleQuery(force: boolean = false) {
        const searchText = this.searchField.value.trim();
        if (!force && (searchText.length < this.queryMinLetters || searchText === this.lastQueryText)) {
            return;
        }

        toggleReloadingFade(this.searchTable, true)
        toggleReloadingFade(this.searchEmpty, true)

        this.lastQueryText = searchText
        const queryNumber = ++this.lastQueryNumber
        setTimeout(() => this.executeQuery(queryNumber, searchText), this.queryTimeout);
    }

    private executeQuery(queryNumber: number, searchText: string) {
        if (queryNumber != this.lastQueryNumber) return;

        fetchJson(`/coatings/listPage?search=${encodeURIComponent(searchText)}&offset=0&limit=10`)
            .then(json => this.setContent(json))
            .catch(() => this.setContent({content: [], totalElements: 0, numberOfElements: 0}));
    }

    private setContent(page: { content: CoatingListItem[], totalElements: number, numberOfElements: number }) {
        toggleReloadingFade(this.searchTable, false)
        toggleReloadingFade(this.searchEmpty, false)

        this.lastQueryResult = page.content;

        const body = this.searchTable.querySelector('tbody')!!
        body.innerHTML = ``;
        toggleHidden(this.searchNotice, true);

        if (page.totalElements == 0) {
            toggleHidden(this.searchTable, true);
            toggleHidden(this.searchEmpty, false);
            return
        }

        const e = escapeHTML;

        let bodyHtml = ''
        page.content.forEach(item => {
            const buttons = `<button class="btn btn-sm btn-secondary" data-bind="select">${messages.get('button.select')}</button>`;
            bodyHtml += `
            <tr class="align-baseline" data-item="${e(JSON.stringify(item))}">
                <td class="text-nowrap">${e(item.code)}</td>            
                <td><span class="title-highlight">${e(item.process)}</span> ${e(item.processName)}</td>            
                <td class="text-nowrap">${buttons}</td>            
            </tr>`
        })

        body.innerHTML = bodyHtml

        toggleHidden(this.searchTable, false);
        toggleHidden(this.searchEmpty, true);

        if (page.totalElements > page.numberOfElements) {
            this.searchNotice.innerText = messages.get('CoatingInputField.countNotice', page.numberOfElements, page.totalElements)
            toggleHidden(this.searchNotice, false);
        }
    }

    private onTableClick(e: Event) {
        const target = e.target as HTMLElement
        if (target.tagName === 'BUTTON' && target.getAttribute('data-bind') === 'select') {
            const row = target.closest('tr')!

            const data = JSON.parse(row.getAttribute('data-item') as string) as CoatingListItem

            const detailItem = this.lastQueryResult.filter(item => item.id === data.id)[0]
            if (detailItem) {
                this.receiver(detailItem)
                this.modal.hide()
            }
        }
    }
}