import {plot, line} from "@observablehq/plot";
import {Messages} from './messages/Messages'

const messages = new Messages();

export class PositionTypeListPage {
    renderCharts() {
        document.querySelectorAll('div[data-density-chart]').forEach(function (div) {
            const rest = JSON.parse(div.getAttribute("data-density-chart") || '');
            if (!rest || rest.length == 0) return;

            const data = [{value: 0, speed: 0}].concat(rest);
            div.append(plot({
                width: 400,
                height: 200,
                x: {label: messages.get('type.form.currentDensity.current')},
                y: {label: messages.get('type.form.currentDensity.layerGrowthSpeed')},
                grid: true,
                marks: [line(data, {x: "value", y: "speed"})]
            }));
        })
    }
}
