import {Popover} from "bootstrap";

export {Popover};

export interface PopoverInstance {
    createPopover(target: Element, rootContainer: HTMLElement): Popover

    hidePopover?(): void
}

function hasParent(element: Element | null, parent: Element): boolean {
    if (!element) return false;
    if (element == parent) return true;
    return hasParent(element.parentElement, parent);
}

const ShowOptions = {
    dispose: false
}

class SinglePopoverManager {
    private currentPopover: Popover | null = null
    private currentElement: Element | null = null

    constructor() {
        document.addEventListener('click', e => {
            const target = e.target as Element
            if (this.currentElement != null && hasParent(target, this.currentElement)) {
                return;
            }
            if (target.closest('.popover') == null) {
                this.hideCurrent();
            }
        })
    }

    hideForElement(element: Element) {
        if (this.currentElement == element) {
            this.hideCurrent();
        }
    }


    show(element: Element, popover: Popover, options: { dispose?: boolean, hidden?: Function } = ShowOptions) {
        const options_ = Object.assign({}, ShowOptions, options);

        this.hideCurrent();

        const showListener = () => {
            this.currentElement = element
            this.currentPopover = popover
        }
        const hideListener = () => {
            if (this.currentPopover == popover) {
                this.currentPopover = null
                this.currentElement = null
            }
        }
        const hiddenListener = () => {
            element.removeEventListener('show.bs.popover', showListener);
            element.removeEventListener('hide.bs.popover', hideListener);
            element.removeEventListener('hidden.bs.popover', hiddenListener);

            if (options_.dispose) {
                popover.dispose()
            }
            options_.hidden?.()
        }

        element.addEventListener('show.bs.popover', showListener)
        element.addEventListener('hide.bs.popover', hideListener)
        element.addEventListener('hidden.bs.popover', hiddenListener);
        popover.show();
    }

    hideCurrent() {
        this.currentPopover?.hide()
    }

    updateForElement(view: Element | null) {
        if (view == this.currentElement) {
            this.currentPopover?.update()
        }
    }

    removeForElement(view: SVGElement | null) {
        if (view == this.currentElement) {
            this.currentPopover?.hide()
        }
    }
}

export const SinglePopover = new SinglePopoverManager();
