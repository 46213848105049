import {SchemaState} from "../SchemaState";
import {PlayerDataAdapter, PlayerDataListener} from "../PlayerDataAdapter";
import {createHtmlElement} from "../lib/domFunctions";
import {Messages} from "../messages/Messages";
import {ContainerListItem} from "../dto/com.rico.sb2.service.queue";
import {fetchJson, fetchJsonForApiResponse} from "../lib/fetch";
import {AppConfig} from "../AppConfig";
import {Page} from "../dto/org.springframework.data.domain";
import {ContainerState} from "../dto/com.rico.sb2.entity.detail";
import {FinishedListItem} from "./FinishedListItem";
import {PlayerData_ContainerInfo} from "../dto/com.rico.sb2.service.positions";

const messages = new Messages();

const emptyMessage = messages.get('FinishedList.emptyListMessage');
const forbiddenMessage = messages.get('FinishedList.forbiddenMessage');

export class FinishedList implements PlayerDataListener {
    element: HTMLElement;

    private readonly tableElement: HTMLElement;
    private readonly tableEmpty: HTMLDivElement;

    private items: FinishedListItem[] = []
    private query = {pageSize: 50, pageNumber: 0}

    constructor(schemaState: SchemaState, playerData: PlayerDataAdapter) {
        this.element = document.getElementById('listFinished')!

        this.tableElement = this.element.querySelector('[data-bind="listFinished"]')!
        this.tableEmpty = createHtmlElement('div', {class: 'text-muted fst-italic text-center p-3'}, ``)
        this.changeNotice(emptyMessage);
        this.queryNext();

        playerData.playerDataListeners.add(this)
    }

    changeNotice(message: string) {
        this.tableEmpty.remove()

        if (this.items.length == 0) {
            this.tableEmpty.innerText = message
            this.tableElement.append(this.tableEmpty)
        }
    }

    private queryNext() {
        fetchJson(`${AppConfig.CP}/queue/finished/slice?pageSize=${this.query.pageSize}&pageNumber=${this.query.pageNumber}`)
            .then(page => this.receivePage(page))
            .catch(error => {
                this.items = []
                this.changeNotice(error == 403 ? forbiddenMessage : emptyMessage)
            });
    }

    private receivePage(page: Page<ContainerListItem>) {
        const knownItems = new Set()
        this.items.forEach(i => knownItems.add(i.data.id));

        page.content
            .filter(i => !knownItems.has(i.id))
            .forEach(i => this.display(i))
        this.changeNotice(emptyMessage);

        this.query.pageNumber = page.number + 1
    }

    onContainerRemoved(m: PlayerData_ContainerInfo) {
        if (m.state == ContainerState.FINISHED) {
            fetchJsonForApiResponse(`${AppConfig.CP}/op/containerListItem?id=${m.id}`)
                .then(item => this.display(item, 0))
        }
    }

    private display(i: ContainerListItem, insertPosition?: number) {
        const widget = new FinishedListItem(i);

        let insertAfter = null
        if (insertPosition !== undefined && Number.isFinite(insertPosition)) {
            insertAfter = this.items[insertPosition]?.element || null
        }

        this.tableElement.insertBefore(widget.element, insertAfter)
        this.items.push(widget);
    }
}