import {isAjaxResponseOk} from "./fetch";

function getHeaderMetaByName(name: string) {
    return document.querySelector(`head > meta[name="${name}"]`) as HTMLMetaElement;
}

export function getCsrfHeader(): any {
    const header = getHeaderMetaByName('_csrf_header').getAttribute("content")
    const token = getHeaderMetaByName('_csrf').getAttribute("content")
    if (!header || !token) {
        return null;
    }
    const headers: any = {};
    headers[header] = token;
    return headers;
}

export function getCsrfParameter(): { parameter: string, token: string } | null {
    const parameter = getHeaderMetaByName('_csrf_parameter').getAttribute("content")
    const token = getHeaderMetaByName('_csrf').getAttribute("content")
    if (!parameter || !token) {
        return null;
    }
    return {parameter, token};
}

export function apiPost(url: string, body?: any): Promise<any> {
    return apiCall(url, body, {method: 'POST'});
}

export function apiPostForAjaxResponse(url: string, body?: any): Promise<any> {
    return apiPost(url, body)
        .then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res));
}

export function apiGet(url: string, body?: any): Promise<any> {
    return apiCall(url, body, {method: 'GET'});
}

export function apiGetForAjaxResponse(url: string, body?: any): Promise<any> {
    return apiCall(url, body, {method: 'GET'})
        .then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res));
}

function apiCall(url: string, body: any, requestInit: RequestInit): Promise<any> {
    const headers = Object.assign(
        {
            "Accept": "application/json"
        },
        getCsrfHeader(),
        requestInit.headers)

    const bodyAsIs = body instanceof FormData && requestInit.method == 'POST';
    if (!headers['Content-Type'] && !bodyAsIs) {
        headers['Content-Type'] = 'application/json';
    }
    requestInit.headers = headers;

    switch (typeof body) {
        case 'undefined': {
            break;
        }
        case 'string': {
            requestInit.body = body;
            break;
        }
        default: {
            requestInit.body = bodyAsIs ? body : JSON.stringify(body);
            break;
        }
    }

    return fetch(url, requestInit)
        .then(res => {
            if (res.status === 200)
                return res.json();
            return Promise.reject(res.status);
        })
}

export function postForFile(url: string, body?: any) {
    const requestInit: RequestInit = {
        method: 'POST'
    };

    requestInit.headers = Object.assign(
        {
            'Content-Type': 'application/json'
        },
        getCsrfHeader(),
        requestInit.headers);

    switch (typeof body) {
        case 'undefined': {
            break;
        }
        case 'string': {
            requestInit.body = body;
            break;
        }
        default: {
            requestInit.body = JSON.stringify(body);
            break;
        }
    }

    return fetch(url, requestInit).then(res => {
        if (res.status === 200) {
            return res;
        }
        return Promise.reject(res.status);
    })
}

export function getContentDispositionName(headers: Headers): string {
    const disposition = headers.get('content-disposition')
    if (disposition == null) return '';

    const match = /;filename=(.*)$/.exec(disposition);
    if (!match) return '';

    return match[1];
}