import {SvgView} from "./SvgView";
import {ContainerView, ContainerViewState} from "./Containers";
import {PositionView, PositionViewState} from "./Positions";
import {AoView, TransferView, TransportView, TransportViewState} from "./Transports";
import {PeriodicTask} from "../lib/PeriodicTask";
import {orderedUpdateSchemeMessages, UpdateContainerMessage, UpdatePositionMessage, UpdateSchemeMessage, UpdateTransportMessage, visitUpdateSchemeMessage} from "../dto/com.rico.sb2.message";
import {PlayerData_ContainerInfo, PlayerData_Transport} from "../dto/com.rico.sb2.service.positions";
import {createHtmlElement, createSvgElement} from "../lib/domFunctions";
import {PlayerDataAdapter, PlayerDataListener, UpdateSchemeMessageConsumer} from "../PlayerDataAdapter";
import {PopoverInstance, SinglePopover} from "../lib/bootstrapPopover";
import {PositionDataAdapter} from "../dashboard/PositionDataSupplier";
import {TelemetryMessageProcessor_BathTelemetry} from "../dto/com.rico.sb2.service";
import {sortNumberAsc, sortNumberDesc} from "../lib/langExtensions";
import {ContainerPathTrackCustomDefs, ContainerPathTrackCustomStyles, SchemaContainerTracks} from "./SchemaContainerTracks";
import {TransportType} from "../dto/com.rico.sb2.entity.device";


const EMPTY_CONTAINER_X_GAP = 10;
const EMPTY_CONTAINER_Y_GAP = 20;

const CustomStyles: { [key: string]: string } = Object.assign({
    'svg-dotl-circle': 'fill:#616161;stroke:#616161',
    'svg-dotl-bracket': 'fill:none;stroke:#616161',
    'svg-transfer-vline': 'fill:none;stroke:#616161;stroke-width: 1;stroke-linecap: round;stroke-miterlimit: 4;stroke-dasharray: 6, 8;stroke-opacity: 1;',
    'svg-ao-track': 'fill:none;stroke:#9d9d9d;stroke-width: 1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;',
    'svg-ao-track-marker-r': 'marker-end:url(#BracketR)',
    'svg-ao-track-marker-l': 'marker-end:url(#BracketL)',
    'svg-ao-track-marker-sw': 'marker-start:url(#Wave)',
    'svg-ao-track-marker-ew': 'marker-end:url(#Wave)',
}, ContainerPathTrackCustomStyles);

function addCustomDefs(root: SVGElement) {
    const BracketR = createSvgElement('marker', {id: 'BracketR', markerWidth: "10", markerHeight: "10", viewBox: "0,0,10,10", refY: "5", refX: "5"});
    BracketR.append(createSvgElement('path', {d: "M 0,0 l 5,5 -5,5", class: 'svg-dotl-bracket'}))
    const BracketL = createSvgElement('marker', {id: 'BracketL', markerWidth: "10", markerHeight: "10", viewBox: "0,0,10,10", refY: "5", refX: "5"});
    BracketL.append(createSvgElement('path', {d: "M 10,0 l -5,5 5,5", class: 'svg-dotl-bracket'}))
    const Wave = createSvgElement('marker', {id: 'Wave', markerWidth: "10", markerHeight: "20", viewBox: "0,0,10,20", refY: "10", refX: "5"});
    Wave.append(createSvgElement('path', {d: "M 5,0 C 0,10 10,10 5,20", class: 'svg-dotl-bracket'}))

    const defs = createSvgElement('defs');
    defs.append(BracketR, BracketL, Wave)
    root.insertBefore(defs, root.firstElementChild)

    const schemaContainerTracks = createSvgElement('defs');
    ContainerPathTrackCustomDefs().forEach(n => schemaContainerTracks.append(n));
    root.insertBefore(schemaContainerTracks, root.firstElementChild)

}

function addCustomStyles(root: SVGElement) {
    const style = createSvgElement('style');
    style.textContent = Object.keys(CustomStyles)
        .map(k => `.${k} { ${CustomStyles[k]} }`)
        .join("\n");
    root.insertBefore(style, root.firstElementChild)
}

function addUserDefinedStyles(root: SVGElement, content: string) {
    if (!content) return

    const style = createSvgElement('style');
    style.textContent = content;
    root.insertBefore(style, root.firstElementChild)
}

type PopoverSupplier = (player: SchemaPlayer, id: number) => PopoverInstance

export interface SchemaPlayerTrack {
    remove(): void;
}

export interface SchemaPlayerEvents {
    getContainerPopover?(player: SchemaPlayer, id: number): PopoverInstance

    getContainerContextMenu?(player: SchemaPlayer, id: number): PopoverInstance

    getPositionPopover?(player: SchemaPlayer, id: number): PopoverInstance

    getTransportPopover?(player: SchemaPlayer, id: number): PopoverInstance
}

export class SchemaPlayer implements UpdateSchemeMessageConsumer, PlayerDataListener {
    private readonly tick: PeriodicTask
    private readonly containerSvgTemplate: SVGElement
    private readonly containerSvg: string

    readonly data: PlayerDataAdapter
    private readonly rootContainer: HTMLElement
    readonly root: SVGElement
    private readonly rootWidth: number = 0
    private readonly rootHeight: number = 0

    private readonly transports = new Map<number, SvgView & TransportView>()
    private readonly positions = new Map<number, PositionView>()
    private readonly containers = new Map<number, ContainerView>()
    private readonly events: SchemaPlayerEvents

    private readonly positionLineY: number[] = []
    private positionLastZ: SVGElement | null = null
    private aoFirstZ: SVGElement | null = null

    private readonly containerTracks: SchemaContainerTracks

    constructor(element: HTMLElement, svg: string, containerSvg: string, containerCss: string, data: PlayerDataAdapter, updates: UpdateSchemeMessage[], events: SchemaPlayerEvents = {}) {
        this.containerTracks = new SchemaContainerTracks(this);

        this.rootContainer = element
        this.rootContainer.innerHTML = ''
        this.rootContainer.innerHTML = svg

        this.events = events
        this.data = data;

        this.containerSvg = containerSvg
        this.root = this.rootContainer.querySelector('svg') as SVGElement
        addCustomDefs(this.root)
        addCustomStyles(this.root)
        addUserDefinedStyles(this.root, containerCss);

        // на position labels надо убрать пробелы между tspan
        // иначе в случаях многострочных label-ов будет некрасивое выравнивание по центру
        this.root.querySelectorAll('text').forEach(node => {
            const hasSpan = node.querySelectorAll('tspan').length > 0
            if (hasSpan) {
                node.childNodes.forEach(child => {
                    if (child.nodeType === Node.TEXT_NODE) {
                        node.removeChild(child);
                    }
                })
            }
        })

        // сохраним шаблон для новых контейнеров
        {
            const template = createHtmlElement('div', {}, this.containerSvg);
            const svg = template.querySelector('svg') as SVGElement

            // set coordinates
            const viewBox = svg.getAttribute('viewBox') as string;
            const viewBoxValues = viewBox.split(/[\s,]+/);
            const [, , w, h] = viewBoxValues
            svg.removeAttribute('viewBox')
            svg.setAttribute('x', '0')
            svg.setAttribute('y', '0')
            svg.setAttribute('width', w)
            svg.setAttribute('height', h)

            // save styles
            svg.querySelectorAll('style').forEach(style => {
                this.root.insertBefore(style, this.root.firstChild)
            })

            const g = createSvgElement("g")
            for (let i = 0; i < svg.attributes.length; ++i) {
                const attr = svg.attributes.item(i)
                if (attr) {
                    g.setAttributeNS(attr.namespaceURI, attr.name, attr.value)
                }
            }
            g.classList.add('container-theme')
            g.innerHTML = svg.innerHTML

            this.containerSvgTemplate = g
        }

        // и наполним данными

        data.positions
            .forEach(p => this.registerPosition(data.getPositionData(p.id)))
        this.collectPositionLineY();

        data.transports
            .filter(t => t.type === 'AO')
            .forEach(t => this.registerAo(t))
        data.transports
            .filter(t => t.type === 'TRANSFER')
            .forEach(t => this.registerTransfer(t))

        // тут надо поместить трансферы под АО, чтобы подвески было видно
        Array.from(this.transports.values())
            .filter(t => t.data.type == 'TRANSFER')
            .forEach(t => this.aoFirstZ?.before(t.view!!))

        data.containers
            .forEach(c => this.newContainerView(c))


        const viewBox = (this.root.getAttribute("viewBox") as string).split(/[\s,]+/).map(t => t.trim());
        this.rootWidth = parseFloat(viewBox[2]);
        this.rootHeight = parseFloat(viewBox[3]);
        this.root.setAttribute("width", this.rootWidth.toString());
        this.root.setAttribute("height", this.rootHeight.toString());
        this.root.style.overflow = 'visible';

        this.alignEmptyContainers();

        orderedUpdateSchemeMessages(updates)
            .forEach(m => this.onSchemeUpdate(m));

        this.tick = new PeriodicTask(() => this.step(), {periodMs: 1000, enabled: true});

        this.data.updateListeners.add(this)
        this.data.playerDataListeners.add(this)

        Array.from(this.containers.values()).forEach(cv => this.updateContainerPath(cv));
    }

    clear() {
        this.data.updateListeners.remove(this)
        this.data.playerDataListeners.remove(this)

        this.tick.enabled = false
        this.hidePopover();
        this.rootContainer.innerHTML = ''
    }

    hidePopover() {
        SinglePopover.hideCurrent();
    }

    private collectPositionLineY() {
        const positions = Array.from(this.positions.values());
        const groups: Array<PositionView[]> = [];
        const sameLineThreshold = 10;

        function findGroupIndex(v: PositionView) {
            for (let i = 0; i < groups.length; ++i) {
                const group = groups[i];
                if (group.some(groupV => Math.abs(groupV.y - v.y) < sameLineThreshold))
                    return i;
            }
            return -1;
        }

        for (const position of positions) {
            const group = findGroupIndex(position);
            if (group == -1) {
                groups.push([position]);
            } else {
                groups[group].push(position);
            }
        }

        const groupLineYSet = new Set<number>();

        for (const group of groups) {
            const groupLineY = Math.floor(group.map(pv => pv.y).reduce((a, b) => Math.min(a, b)));
            group.forEach(pv => pv.lineY = groupLineY);
            groupLineYSet.add(groupLineY);
        }

        groupLineYSet.forEach(n => this.positionLineY.push(n));
        this.positionLineY.sort(sortNumberAsc);
    }

    private alignEmptyContainers() {
        let lastX = 0

        Array.from(this.containers.values())
            .filter(view => view.data.position == null && view.data.transport == null && !view.hidden)
            .forEach(view => {
                view.toX(lastX)
                view.toY(this.rootHeight + EMPTY_CONTAINER_Y_GAP)
                lastX += view.width + EMPTY_CONTAINER_X_GAP
            })

        const containerHeight = parseFloat(this.containerSvgTemplate.getAttribute('height')!!);
        const totalHeight = this.rootHeight + EMPTY_CONTAINER_Y_GAP + containerHeight;
        this.root.setAttribute('height', `${totalHeight}`)
        this.root.setAttribute('viewBox', `0,0,${this.rootWidth},${totalHeight}`)
    }

    private registerPosition(p: PositionDataAdapter) {
        const view = new PositionView(p, this.root.querySelector(`#position${p.id}`) as SVGElement);

        this.positions.set(p.id, view)
        this.positionLastZ = latestChild(this.positionLastZ, view.view)
        this.registerPopover(p.id, view, this.events.getPositionPopover)
    }

    private registerTransportView<T extends TransportView & SvgView>(t: PlayerData_Transport, view: T): T {
        this.transports.set(t.id, view)
        this.registerPopover(t.id, view, this.events.getTransportPopover)

        if (t.position != null) {
            view.setAtPosition(this.positions.get(t.position) || null)
        } else if (t.min != null) {
            view.setAtPosition(this.positions.get(t.min) || null)
        }

        view.update(this)
        return view;
    }

    private registerAo(t: PlayerData_Transport) {
        const view = this.registerTransportView(t, new AoView(t, this.root.querySelector(`#transport${t.id}`) as SVGElement))

        this.aoFirstZ = premiereChild(this.aoFirstZ, view.view);
    }

    private registerTransfer(t: PlayerData_Transport) {
        this.registerTransportView(t, new TransferView(t, this.root.querySelector(`#transport${t.id}`) as SVGElement))

        const min = this.positions.get(t.min || -1)
        const max = this.positions.get(t.max || -1)
        if (min && max) {
            const bottom = Math.max(min.y, max.y)
            const top = Math.min(min.y + max.height, max.y + max.height)
            const left = Math.min(min.x, max.x)
            const right = Math.max(min.x + min.width, max.x + max.width)

            const leftLine = createSvgElement('path', {class: 'svg-transfer-vline', d: `M ${left + .5},${top} V ${bottom}`})
            const rightLine = createSvgElement('path', {class: 'svg-transfer-vline', d: `M ${right - .5},${top} V ${bottom}`})

            this.root.prepend(leftLine, rightLine);

            const topPosition = min.y < max.y ? min.data.id : max.data.id
            const topLabel = this.root.querySelector(`#positionLabel${topPosition}`)
            if (topLabel) {
                topLabel.remove()
            }
        }
    }

    private newContainerView(id: number) {
        const node = this.createContainerSvg()

        const containerData = this.data.getContainerData(id)
        const view = new ContainerView(containerData, node)
        this.containers.set(containerData.id, view)

        if (containerData.position != null && containerData.position > 0) {
            this.attachContainerToPosition(view, containerData.position, false)
        }
        if (view.view != null) {
            this.aoFirstZ?.before(view.view)
        }

        this.registerPopover(containerData.id, view, this.events.getContainerPopover, this.events.getContainerContextMenu)
        return view;
    }

    private registerPopover(id: number, svgView: SvgView, leftClickPopover?: PopoverSupplier, rightClickPopover?: PopoverSupplier) {
        const view = svgView.view
        if (view && leftClickPopover) {
            svgView.view?.addEventListener('click', e => {
                const popoverInstance = leftClickPopover?.(this, id)
                if (!popoverInstance) return

                const popover = popoverInstance.createPopover(view, this.rootContainer)
                if (!popover) return;

                const hidden = popoverInstance.hidePopover ? popoverInstance.hidePopover.bind(popoverInstance) : undefined
                SinglePopover.show(view, popover, {dispose: true, hidden});
                e.preventDefault();
            })
        }

        if (view && rightClickPopover) {
            view.addEventListener('contextmenu', e => {
                const popoverInstance = rightClickPopover?.(this, id)
                if (!popoverInstance) return

                const popover = popoverInstance.createPopover(view, this.rootContainer)
                if (!popover) return;

                const hidden = popoverInstance.hidePopover ? popoverInstance.hidePopover.bind(popoverInstance) : undefined
                SinglePopover.show(view, popover, {dispose: true, hidden});
                e.preventDefault();
            })
        }
    }

    private createContainerSvg(): SVGElement {
        return this.containerSvgTemplate.cloneNode(true) as SVGElement;
    }

    private step() {
        Array.from(this.positions.values()).forEach(p => p.tick())
        Array.from(this.containers.values()).forEach(p => p.tick())
    }

    private moveToPosition(svgView: SvgView | null, posId: number | null, onlyX = true) {
        if (svgView == null || posId == null) return
        const pos = this.positions.get(posId)
        if (pos) {
            svgView.toCenterX(pos.centerX)

            if (!onlyX) {
                svgView.toY(pos.y)
            }
        }
    }

    private moveToTransport(svgView: SvgView | null, aoId: number | null) {
        if (svgView == null || aoId == null) return

        const transport = this.transports.get(aoId)
        if (transport) {
            transport.moveToSelf(svgView)
        }
    }

    getPositionState(id: number): PositionViewState | null {
        return this.positions.get(id) || null
    }

    getTransportState(id: number): TransportViewState | null {
        return this.transports.get(id) || null
    }

    getContainerState(id: number): ContainerViewState | null {
        return this.getContainerView(id)
    }

    getContainerView(id: number): ContainerView | null {
        return this.containers.get(id) || null
    }

    onSchemeUpdate(m: UpdateSchemeMessage): void {
        visitUpdateSchemeMessage(m, {
            transportMessage: m => this.onSchemeTransportUpdate(m),
            positionMessage: m => this.onSchemePositionUpdate(m),
            containerMessage: m => this.onSchemeContainerUpdate(m)
        })
    }

    private onSchemePositionUpdate(m: UpdatePositionMessage) {
        const view = this.positions.get(m.id)
        if (view == null) return
        view.enabled = m.enabled
        view.active = m.active
        view.timeLeft = m.timeLeft
        view.update()
    }

    onSchemePositionTelemetry(m: TelemetryMessageProcessor_BathTelemetry): void {
        const view = this.positions.get(m.id)
        if (view == null) return
        view.update()
    }

    private onSchemeTransportUpdate(m: UpdateTransportMessage) {
        const view = this.transports.get(m.id)
        if (view == null) return
        view.state = m.state
        view.position = m.position
        view.targetPosition = m.targetPosition
        view.update(this)

        if (m.position != null) {
            view.setAtPosition(this.positions.get(m.position) || null)
        }

        if (view.container != null) {
            this.updateContainerPath(view.container);
        }
    }

    private onSchemeContainerUpdate(m: UpdateContainerMessage) {
        this.onContainerUpdate(m.id)
    }

    private onContainerUpdate(id: number) {
        const view = this.containers.get(id)
        if (view == null) return

        this.attachContainerToPosition(view, view.data.position)
        this.moveToPosition(view, view.data.position, false)
        this.attachContainerToTransport(view, view.data.transport)
        this.moveToTransport(view, view.data.transport)
        view.update()
        this.updateContainerPath(view);

        this.alignEmptyContainers()
    }

    private attachContainerToPosition(view: ContainerView, position: number | null, onlyX = true) {
        if (position === view.onPosition) return;

        if (view.onPosition != null) {
            const positionView = this.positions.get(view.onPosition)!!
            positionView.container = null
            view.onPosition = null
        }

        if (position != null) {
            const positionView = this.positions.get(position)!!
            positionView.container = view
            view.onPosition = position
        }

        this.moveToPosition(view, view.onPosition, onlyX)
    }

    private attachContainerToTransport(view: ContainerView, transport: number | null) {
        if (transport === view.onTransport) return;

        if (view.onTransport != null) {
            const owner = this.transports.get(view.onTransport)!!
            owner.container = null
            view.onTransport = null
        }

        if (transport != null) {
            const owner = this.transports.get(transport)!!
            owner.container = view
            view.onTransport = transport
        }

        this.moveToTransport(view, view.onTransport)
    }

    onContainerAdded(c: PlayerData_ContainerInfo) {
        this.newContainerView(c.id)
        this.alignEmptyContainers();
    }

    onContainerModify(c: PlayerData_ContainerInfo) {
        this.onContainerUpdate(c.id)
    }

    onContainerRemoved(c: PlayerData_ContainerInfo) {
        const view = this.containers.get(c.id)
        if (!view) return

        this.attachContainerToPosition(view, null)
        this.attachContainerToTransport(view, null)
        view.view?.remove()
        this.containers.delete(c.id)
        this.containerTracks.removeContainerTrack(c.id);
        SinglePopover.removeForElement(view.view)
    }

    getAllPositions() {
        return Array.from(this.positions.values());
    }

    getAllPositionLineY() {
        return this.positionLineY;
    }

    createAoTrack(ao: AoView, from: number, to: number): SchemaPlayerTrack | null {
        if (!this.positions.has(from) || !this.positions.has(to)) return null;

        let start = this.positions.get(from)!;
        const finish = this.positions.get(to)!;

        // разные линии - не рисуем
        if (start.data.line !== finish.data.line) return null

        const positions = Array.from(this.positions.values());

        const positionsOnLineY = (lineY: number) => {
            return positions.filter(p => p.lineY == lineY).sort((a, b) => a.x - b.x);
        }

        const nextLineY = (lineY: number): number => {
            return this.positionLineY.filter(y => y > lineY).sort(sortNumberAsc)[0]
        }

        const prevLineY = (lineY: number): number => {
            return this.positionLineY.filter(y => y < lineY).sort(sortNumberDesc)[0]
        }

        const addTrackSegment = (y: number, x1: number, x2: number, x1Marker: string, x2Marker: string) => {
            if (x2Marker.length == 0) x2Marker = x2 > x1 ? 'r' : 'l';
            if (x1Marker.length > 0) x1Marker = `svg-ao-track-marker-${x1Marker}`;
            if (x2Marker.length > 0) x2Marker = `svg-ao-track-marker-${x2Marker}`;

            const line = createSvgElement('path', {class: `svg-ao-track ${x1Marker} ${x2Marker}`, d: `M ${x1 + .5},${y + .5} H ${x2 - .5}`})
            this.root.insertBefore(line, this.root.firstElementChild)
            segments.push(line);
        }

        // В случае переноса линии - Y координаты будут разные. 
        // Надо тогда вычислить сегменты, и сделать трек для каждого сегмента
        const segments: SVGElement[] = [];
        const positionMarginX = 9;

        let startX = Math.floor(start.x + start.width / 2);
        let startY;
        while (start.lineY != finish.lineY) {
            const startLinePositions = positionsOnLineY(start.lineY);
            startY = Math.floor(start.y - ao.lineYOffset + ao.trackPointY);

            // если для перехода на другую линию надо ехать влево ...
            if (start.lineY > finish.lineY) {
                const segmentEnd = startLinePositions[0];
                const segmentEndX = Math.round(segmentEnd.x - positionMarginX);
                addTrackSegment(startY, startX, segmentEndX, 'sw', 'ew')

                const nextLine = prevLineY(start.lineY);
                if (!nextLine) break;
                start = positionsOnLineY(nextLine).reverse()[0];
                startX = start.x + start.width + positionMarginX;
            } else {
                const segmentEnd = startLinePositions[startLinePositions.length - 1];
                const segmentEndX = Math.round(segmentEnd.x + segmentEnd.width + positionMarginX);
                addTrackSegment(startY, startX, segmentEndX, 'sw', 'ew')

                const nextLine = nextLineY(start.lineY);
                if (!nextLine) break;
                start = positionsOnLineY(nextLine)[0];
                startX = start.x - positionMarginX;
            }
        }

        // добавляем последний сегмент
        startY = Math.floor(start.y - ao.lineYOffset + ao.trackPointY);
        addTrackSegment(startY, startX, Math.floor(finish.centerX), 'sw', '');

        return {
            remove() {
                segments.forEach(s => s.remove());
            }
        };
    }

    private updateContainerPath(view: ContainerView) {
        this.containerTracks.setContainerTrack(view.data.id, view.data.positionNextPath);
    }

    public getPositionView(position: number): PositionView {
        const view = this.positions.get(position);
        if (view == null) throw new Error(`no view for position ${position}`);
        return view;
    }

    public getTransportView(transport: number): TransportView {
        const view = this.transports.get(transport);
        if (view == null) throw new Error(`no view for transport ${transport}`);
        return view;
    }

    public findTransferByMove(startPosition: number, endPosition: number): TransferView | null {
        return Array.from(this.transports.values())
            .filter(t => t.data.type == TransportType.TRANSFER)
            .filter(t => (t.data.min == startPosition && t.data.max == endPosition) || (t.data.min == endPosition && t.data.max == startPosition))
            .map(t => t as TransferView)
            [0] || null;
    }

    public findTransferByPosition(startOrEndPosition: number) {
        return Array.from(this.transports.values())
            .filter(t => (t.data.min == startOrEndPosition || t.data.max == startOrEndPosition))
            .map(t => t as TransferView)
            [0] || null;
    }
}

function premiereChild(a: SVGElement | null, b: SVGElement | null): SVGElement | null {
    if (a == null) return b;
    if (b == null) return a;
    const aIndex = a.parentNode == null ? 0 : Array.from(a.parentNode.children).indexOf(a)
    const bIndex = b.parentNode == null ? 0 : Array.from(b.parentNode.children).indexOf(b)
    return aIndex < bIndex ? a : b;
}

function latestChild(a: SVGElement | null, b: SVGElement | null): SVGElement | null {
    if (a == null) return b;
    if (b == null) return a;
    const aIndex = a.parentNode == null ? 0 : Array.from(a.parentNode.children).indexOf(a)
    const bIndex = b.parentNode == null ? 0 : Array.from(b.parentNode.children).indexOf(b)
    return aIndex < bIndex ? b : a;
}