/* eslint-disable @typescript-eslint/no-explicit-any */
export function memoize1(compute) {
    let cacheValue, cacheKeys;
    return (...keys) => {
        if (cacheKeys?.length !== keys.length || cacheKeys.some((k, i) => k !== keys[i])) {
            cacheKeys = keys;
            cacheValue = compute(...keys);
        }
        return cacheValue;
    };
}
