import {jQuery} from './boot'
import './lib/bootstrap-autocomplete/main'
import {escapeHTML} from "./lib/escapeHTML"
import {Messages} from "./messages/Messages";
import {apiPostForAjaxResponse} from "./lib/SecuredAjax";
import {buttonProgress} from "./lib/buttonProgress";
import {AppConfig} from './AppConfig';
import {DetailSearch} from "./modals/DetailSearch";
import {toastFetchError} from "./lib/fetch";
import {DetailListItem} from "./dto/com.rico.sb2.service.details";
import {createHtmlElement, createHtmlElementFromHtml} from "./lib/domFunctions";

const messages = new Messages();

export class CoatingDetailsList {
	private readonly id: number;
	private readonly editable: boolean;

	private get tableJq() {
		return jQuery('#table')
	}

	constructor(id: number, editable: boolean) {
		this.id = id;
		this.editable = editable;

		this.tableJq.bootstrapTable({
			url: `${AppConfig.CP}/coatings/${this.id}/detailsPage`,
			sidePagination: 'server',
			responseHandler: function (res) {
				return {
					total: res.totalElements,
					rows: res.content
				}
			},
			columns: [
				{
					formatter: (value: any, row: any) => {
						const detailLink = this.editable
							? `${AppConfig.CP}/details/${row.id}/edit`
							: `${AppConfig.CP}/details/${row.id}`;
						return `<a href="${detailLink}">${escapeHTML(row.code)}</a>`;
					}
				},
				{},
				{},
				{},
				{},
				{
					class: 'w-10em text-center',
					formatter: (value: any, row: any) => {
						if (!this.editable) return ``;
						return `<button type="button" class="btn btn-danger btn-sm w-6em" data-detail="${row.id}" onclick="controller.removeDetailRow(this)">${messages.get('button.remove')}</button>`;
					}
				}
			]
		})
	}

	showAddDetails() {
		new DetailSearch({
			selectHandler: item => this.addDetailItem(item)
		});
	}

	private addDetailItem(item: DetailListItem) {
		return apiPostForAjaxResponse(`/coatings/${this.id}/addDetail?detail=${item.id}`)
			.then(success => {
				if (success) {
					this.tableJq.bootstrapTable('append', item)
				}
				return success
			})
			.catch(error => {
				toastFetchError(error)
				return false
			})
	}

	removeDetailRow(button: HTMLButtonElement) {
		const detail = button.getAttribute('data-detail');
		buttonProgress(button, messages.get('remove.progress'));

		apiPostForAjaxResponse(`/coatings/${this.id}/removeDetail?detail=${detail}`)
			.then(success => {
				if (!!success) {
					const revertButton = createHtmlElementFromHtml(`<button type="button" class="btn btn-secondary btn-sm w-6em" data-detail="${detail}" onclick="controller.removeRevert(this)">${messages.get('button.removeRevert')}</button>`)
					button.before(revertButton);
					button.remove();
				}
			})
			.catch(toastFetchError)
	}

	removeRevert(button: HTMLButtonElement) {
		const detail = button.getAttribute('data-detail');
		buttonProgress(button, messages.get('remove.progress'));

		apiPostForAjaxResponse(`/coatings/${this.id}/addDetail?detail=${detail}`)
			.then(success => {
				if (!!success) {
					const removeButton = createHtmlElementFromHtml(`<button type="button" class="btn btn-danger btn-sm w-6em" data-detail="${detail}" onclick="controller.removeDetailRow(this)">${messages.get('button.remove')}</button>`)
					button.before(removeButton);
					button.remove();
				}
			})
			.catch(toastFetchError)
	}
}