import ICUPlurals, {ICUPluralRule} from "./ICUPlurals";
import {ICUMessageFormat} from "./ICUMessageFormat";

export interface MessageSource {
    get(code: string): string

    has(code: string): boolean
}

export interface MessageProvider {
    get(key: string, ...args: any[]): string

    has(code: string): boolean
}

export class MessageProviderWithCache implements MessageProvider {
    private readonly locale: string;
    private readonly source: MessageSource;

    private readonly cache: { [key: string]: ICUMessageFormat };
    private readonly plurals: ICUPluralRule;

    constructor(locale: string, source: MessageSource) {
        this.locale = locale;
        this.source = source;

        this.cache = {};
        this.plurals = ICUPlurals[locale] || ICUPlurals.defaults;
    }

    private getText(key: string): string {
        return this.source.get(key);
    }

    /**
     * @param key
     * @returns {ICUMessageFormat}
     * @private
     */
    private getOrCompileFormatter(key: string): ICUMessageFormat {
        if (!(key in this.cache)) {
            const pattern = this.getText(key);
            this.cache[key] = new ICUMessageFormat(pattern);
        }
        return this.cache[key];
    }

    /**
     * @param key
     * @param args
     * @returns {String}
     */
    get(key: string, ...args: any[]): string {
        if (args.length === 0) {
            return this.getText(key);
        }
        const formatter = this.getOrCompileFormatter(key);
        return formatter.format(this.plurals, args);
    }

    has(code: string): boolean {
        return this.source.has(code);
    }
}