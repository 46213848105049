import {createHtmlElementFromHtml} from './lib/domFunctions'
import {apiPostForAjaxResponse, getContentDispositionName, postForFile} from "./lib/SecuredAjax";
import {AppConfig} from "./AppConfig";
import {toastFetchError} from "./lib/fetch";
import {bootstrapModal} from "./lib/bootstrapModal";
import {Messages} from "./messages/Messages";

const messages = new Messages();

interface CSVSetup {
    encoding: string
    fieldDelimiter: string
    numberDelimiter: string
}

function configureCsv(okText: string, autoEncoding: boolean): Promise<CSVSetup> {
    return new Promise((resolve) => {
        const encodingOptions: string[] = [];
        if (autoEncoding) {
            encodingOptions.push('<option value="">определить автоматически</option>');
        }
        encodingOptions.push(`
            <option value="UTF-8">UTF-8</option>
            <option value="windows-1251">windows-1251</option>
            <option value="KOI8-R">KOI8-R</option>
        `);

        const {content, modal, buttonOk} = bootstrapModal({
            title: 'Конфигурация файла',
            body: `
<div class="mb-3 row">
    <label class="col-sm-5 col-form-label">Кодировка файла</label>
    <div class="col-sm-7">
        <select type="text" class="form-select w-auto" name="encoding">${encodingOptions.join("")}</select>
    </div>
</div>
<div class="mb-3 row align-baseline">
    <label class="col-sm-5 col-form-label pt-0">Разделитель полей</label>
    <div class="col-sm-7">
        <div class="form-check form-check-inline me-3 mb-0">
            <input class="form-check-input" type="radio" name="fieldDelimiter" id="fieldDelimiter1" value="," checked>
            <label class="form-check-label" for="fieldDelimiter1">,</label>
        </div>
        <div class="form-check form-check-inline me-3 mb-0">
            <input class="form-check-input" type="radio" name="fieldDelimiter" id="fieldDelimiter2" value=";">
            <label class="form-check-label" for="fieldDelimiter2">;</label>
        </div>
        <div class="form-check form-check-inline me-3 mb-0">
            <input class="form-check-input" type="radio" name="fieldDelimiter" id="fieldDelimiter3" value="&#9;">
            <label class="form-check-label" for="fieldDelimiter3">TAB</label>
        </div>
    </div>
</div>
<div class="row">
    <label class="col-sm-5 col-form-label pt-0">Разделитель чисел</label>
    <div class="col-sm-7">
        <div class="form-check form-check-inline me-3 mb-0">
            <input class="form-check-input" type="radio" name="numberDelimiter" id="numberDelimiter1" value="." checked>
            <label class="form-check-label" for="numberDelimiter1">.</label>
        </div>
        <div class="form-check form-check-inline me-3 mb-0">
            <input class="form-check-input" type="radio" name="numberDelimiter" id="numberDelimiter2" value=",">
            <label class="form-check-label" for="numberDelimiter2">,</label>
        </div>
    </div>
</div>
                  `,
            buttonOk: okText
        })

        buttonOk?.addEventListener('click', () => {
            modal.hide();

            resolve({
                encoding: content.querySelector<HTMLSelectElement>('select[name="encoding"]')?.value || '',
                fieldDelimiter: content.querySelector<HTMLInputElement>('input[name="fieldDelimiter"]:checked')?.value || '',
                numberDelimiter: content.querySelector<HTMLInputElement>('input[name="numberDelimiter"]:checked')?.value || ''
            })
        })

        modal.show();
    });
}

export class ImportPage {
    uploadDetailsFile() {
        const input = createHtmlElementFromHtml<HTMLInputElement>('<input type="file" accept="text/csv" />');
        input.addEventListener('change', () => {
            if (!input.files || input.files.length != 1) return false;

            let formData = new FormData();
            formData.append("file", input.files[0]);

            configureCsv(messages.get('button.send'), true)
                .then(setup => {
                    formData.append("encoding", setup.encoding)
                    formData.append("fieldDelimiter", setup.fieldDelimiter)
                    formData.append("numberDelimiter", setup.numberDelimiter)

                    const progress = bootstrapModal({
                        title: 'Загружаем файл...', body: `Если файл большой, то его загрузка может занять некоторое время`,
                        closeable: false,
                        buttonOk: null, buttonCancel: null
                    });

                    progress.modal.show();

                    function progressModalHide() {
                        progress.modal.hide();
                    }

                    apiPostForAjaxResponse(`${AppConfig.CP}/import/addDetailsCsv`, formData)
                        .then(success => {
                            if (!success) {
                                return Promise.reject();
                            }
                            document.location.reload()
                        })
                        .catch(toastFetchError)
                        .then(progressModalHide);

                });
        })
        input.click();
    }

    downloadDetailsFile() {
        configureCsv(messages.get('button.download'), false)
            .then(setup => {
                const progress = bootstrapModal({
                    title: 'Файл создается...', body: `Если деталей много, то генерация файла может занять некоторое время`,
                    closeable: false,
                    buttonOk: null, buttonCancel: null
                });

                progress.modal.show();

                function progressModalHide() {
                    progress.modal.hide();
                }

                const generateForm = {"encoding": setup.encoding, "fieldDelimiter": setup.fieldDelimiter, "numberDelimiter": setup.numberDelimiter};
                let fileName = "";
                postForFile(`${AppConfig.CP}/import/downloadDetailsCsv`, generateForm)
                    .then(res => {
                        fileName = getContentDispositionName(res.headers);
                        return res.blob();
                    })
                    .then(blob => {
                        const fileLink = document.createElement('a');
                        fileLink.href = window.URL.createObjectURL(blob);
                        fileLink.download = fileName;
                        fileLink.click();
                    })
                    .catch(toastFetchError)
                    .then(progressModalHide);
            });
    }
}