import {line, plot, ruleX} from "@observablehq/plot";
import {Messages} from "./messages/Messages";
import {escapeHTML} from "./lib/escapeHTML";
import {createHtmlElement} from "./lib/domFunctions";

const messages = new Messages();

export class RectifierProfileEditPage {
    private readonly timePoints: HTMLTableElement;
    private readonly typeSelect: HTMLSelectElement;

    constructor() {
        this.timePoints = document.getElementById('timePoints') as HTMLTableElement;

        this.typeSelect = document.querySelector('select#type')!!
        this.typeSelect.addEventListener('change', () => {
            this.displayTypeFields()
            this.updateTimePointChart()
        });
        this.displayTypeFields();

        this.timePoints.addEventListener('change', e => this.checkOnlyOneCycling(e));
        this.timePoints.addEventListener('change', () => this.updateTimePointChart());
        this.timePoints.addEventListener('input', () => this.updateTimePointChart());
        this.updateTimePointChart();
    }

    displayTypeFields() {
        const selectedType = this.typeSelect.value || '';
        document.querySelectorAll('div[data-type-filter]').forEach(div => {
            const divFilter = div.getAttribute('data-type-filter')
            if (divFilter == null) return

            const divTypes = divFilter.split(',');
            div.classList.toggle('hidden', divTypes.indexOf(selectedType) == -1);
        });

        document.querySelectorAll('span[data-bind="rectifierProfile.plot.y"]').forEach(span => {
            span.textContent = messages.get(`rectifierProfile.plot.y.${this.typeSelect.value}`);
        });
    }

    addTimePointRow() {
        const body = this.timePoints.getElementsByTagName('tbody')[0];
        body.appendChild(createHtmlElement('tr', {}, `
            <td class="align-middle"><input lang="en" type="number" step="1" class="form-control form-control-sm" required name="timePoints[-1].interval" ></td>
            <td class="align-middle"><input lang="en" type="number" step="any" class="form-control form-control-sm" required name="timePoints[-1].value"></td>
            <td class="align-middle text-center"><div class="form-check form-check-inline m-0 align-middle"><input type="checkbox" class="form-check-input" name="timePoints[-1].cycle"></div></td>
            <td class="align-middle text-nowrap text-end">
                <button type="button" class="btn btn-sm btn-secondary text-capitalize muted-in-last-row" onclick="controller.moveTimePointDown(this)" title="${escapeHTML(messages.get('setupActions.stepOrderPlus'))}">▼</button>
                <button type="button" class="btn btn-sm btn-secondary text-capitalize muted-in-first-row" onclick="controller.moveTimePointUp(this)" title="${escapeHTML(messages.get('setupActions.stepOrderMinus'))}">▲</button>
                <button class="btn btn-sm btn-secondary" type="button" onclick="controller.removeTimePointRow(this)"><i class="fal fa-minus fa-fw"></i>${messages.get('button.delete')}</button>
            </td>
        `))

        this.onTimePointTableChange();
    }

    removeTimePointRow(button: HTMLButtonElement) {
        button.closest('tr')?.remove()
        this.onTimePointTableChange();
    }

    private onTimePointTableChange() {
        this.enumerateTimePoints()
        this.updateTimePointChart()
    }

    moveTimePointUp(button: HTMLButtonElement) {
        const tr = button.closest('tr')
        if (!tr) return
        const previous = tr.previousElementSibling
        if (!previous) return
        swapWithNext(previous, true).then(() => this.onTimePointTableChange());
    }

    moveTimePointDown(button: HTMLButtonElement) {
        const tr = button.closest('tr')
        if (!tr) return
        const next = tr.nextElementSibling
        if (!next) return
        swapWithNext(tr).then(() => this.onTimePointTableChange());
    }

    private enumerateTimePoints() {
        let seed = 0;
        const namePattern = /^timePoints\[[-\d]+]./
        this.timePoints.querySelectorAll('tbody > tr').forEach(tr => {
            tr.querySelectorAll('[name]').forEach(named => {
                const oldName = named.getAttribute('name') || ''
                const newName = oldName.replace(namePattern, `timePoints[${seed}].`);
                if (oldName != newName) {
                    named.setAttribute('name', newName);
                }
            });
            ++seed;
        })
    }

    private checkOnlyOneCycling(e: Event) {
        const target = e.target as HTMLInputElement;
        const targetName = target.getAttribute('name')!!
        if (target.tagName === 'INPUT' && target.getAttribute('type') === 'checkbox' && targetName.startsWith('timePoints[')) {
            if (!target.checked) {
                return;
            }

            // если checked - идем снимаем галочку со всех остальных
            this.timePoints
                .querySelectorAll<HTMLInputElement>('input[type=checkbox][name^="timePoints["]')
                .forEach(node => {
                    if (node !== target && node.checked) {
                        node.checked = false;
                    }
                });
        }
    }

    private updateTimePointChart() {
        const data = [];

        let offset = 0;
        for (const tr of Array.from(this.timePoints.querySelectorAll('tbody > tr'))) {
            const inputs = tr.querySelectorAll<HTMLInputElement>('input');
            let interval = Number.parseFloat(inputs[0].value);
            let value = Number.parseFloat(inputs[1].value);
            const cycle = inputs[2].checked;

            if (!Number.isFinite(interval)) interval = 0;
            if (!Number.isFinite(value)) value = 0;

            data.push({time: offset + interval, value, cycle});
            offset += interval;
        }

        // если мы начали не с нуля, то надо его добавить
        if (data.length > 0 && data[0].time > 0) {
            data.splice(0, 0, {time: 0, value: 0, cycle: false});
        }

        const marks = [
            line(data, {x: "time", y: "value"})
        ];

        const cycleIndex = data.findIndex(t => t.cycle);
        if (cycleIndex >= 0) {
            const cycleItem = data[cycleIndex];
            const cycleStroke = {stroke: "#333", strokeWidth: 1, strokeDasharray: [5, 5]};

            /**
             * Особо стоит выделить профиль тока для набора толщины. У этого профиля последняя точка - 100% от номинала и она зациклена.
             * Для такого профиля тока время нахождения в ванне программа будет рассчитывать автоматически.
             */
            if (cycleIndex == data.length - 1) {
                const horizontal = [
                    {time: cycleItem.time, value: cycleItem.value},
                    {time: cycleItem.time * 1.3, value: cycleItem.value}
                ]
                marks.push(line(horizontal, Object.assign({x: "time", y: "value"}, cycleStroke)));
            } else {
                marks.push(ruleX([cycleItem.time], cycleStroke))

                let base = data[data.length - 1].time;
                const cycle1 = data
                    .slice(cycleIndex)
                    .map(item => Object.assign({}, item, {time: base + (item.time - cycleItem.time)}));

                base = cycle1[cycle1.length - 1].time;
                const cycle2 = data
                    .slice(cycleIndex)
                    .map(item => Object.assign({}, item, {time: base + (item.time - cycleItem.time)}));

                marks.push(line(cycle1, Object.assign({x: "time", y: "value"}, cycleStroke)));
                marks.push(line(cycle2, Object.assign({x: "time", y: "value"}, cycleStroke)));
            }
        }

        const placeholder = document.getElementById('timePointsChart')
        if (!placeholder) return

        const chart = plot({
            classes: 'mb-5',
            width: placeholder.clientWidth,
            height: 250,
            x: {label: messages.get('rectifierProfile.plot.x')},
            y: {label: messages.get(`rectifierProfile.plot.y.${this.typeSelect.value}`)},
            grid: true,
            marks
        });

        placeholder.replaceChildren(chart);
    }
}

function swapWithNext(el: Element, reverseColors: boolean = false): Promise<void> {
    const next = el.nextElementSibling;
    if (!next) return Promise.reject();

    let rowHeight = el.clientHeight;
    let animationLength = 300;

    const class1 = !reverseColors ? 'swap-above' : 'swap-below';
    const class2 = !reverseColors ? 'swap-below' : 'swap-above';

    el.classList.add(class1);
    el.setAttribute('style', `transform: translate(0px, ${rowHeight}px); transition: transform ${animationLength}ms ease`);
    next.classList.add(class2);
    next.setAttribute('style', `transform: translate(0px, -${rowHeight}px); transition: transform ${animationLength}ms ease`);

    return new Promise(resolve => {
        setTimeout(function () {
            next.after(el);

            el.classList.remove(class1);
            el.removeAttribute('style');
            next.classList.remove(class2);
            next.removeAttribute('style');

            resolve();
        }, animationLength);
    })
}