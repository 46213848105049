import {ContainerType} from "./com.rico.sb2.entity.detail";
import {AppConfig} from "../AppConfig";

const NUMBER_PLACEHOLDER = /\{(\d+)}/g

function resolveIndex(v: string) {
    if (v.startsWith('{')) v = v.substring(1)
    if (v.endsWith('}')) v = v.substring(0, v.length - 1)

    const n = parseInt(v)
    return Number.isFinite(n) ? n : null;
}

export class CoatingCodePattern {
    private readonly pattern: string

    constructor(pattern: string | null) {
        this.pattern = pattern == null ? "" : pattern
    }

    format(thickness: (number | null)[]): string {
        if (this.pattern.length == 0 || thickness == null || thickness.length == 0) {
            return this.pattern;
        }

        if (!NUMBER_PLACEHOLDER.test(this.pattern)) {
            return this.pattern;
        }

        return this.pattern.replace(NUMBER_PLACEHOLDER, v => {
            const thicknessIndex = resolveIndex(v)
            if (thicknessIndex == null || thicknessIndex >= thickness.length) {
                return v
            }
            const value = thickness[thicknessIndex]
            return value != null && Number.isFinite(value) ? value.toString() : ''
        })
    }
}

export class ContainerTypeNamer {
    private defaultName: string | null = null
    private names: { [key: string]: string } | null = null

    name(type: ContainerType | null) {
        /* Мы не можем загрузиться сразу, потому что возможно AppConfig пока не имеет нужной информации */
        if (this.defaultName == null) {
            this.defaultName = AppConfig.ContainerTypeNamerConfig.defaultName;
        }
        if (this.names == null) {
            this.names = Object.assign({}, AppConfig.ContainerTypeNamerConfig.names);
        }

        if (type && type in this.names) return this.names[type];
        return this.defaultName;
    }
}