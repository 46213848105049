import {SvgView} from "./SvgView";
import {padLeft} from "../lib/stringFunctions";
import {ContainerView} from "./Containers";
import {PositionDataAdapter} from "../dashboard/PositionDataSupplier";
import {addSuffixToText} from "../lib/formatTool";
import {ContainerState} from "../dto/com.rico.sb2.entity.detail";
import {msToDurationMS} from "../lib/timeFunctions";

function secToMilli(sec: number) {
    return sec * 1000
}

function milliToSec(milli: number) {
    return Math.round(milli / 1000);
}

export interface PositionViewState {
    readonly data: PositionDataAdapter
    active: boolean
    enabled: boolean
    locked: boolean
    timeLeft: number | null
}

export class PositionView extends SvgView implements PositionViewState {
    readonly data: PositionDataAdapter
    active = false
    timeTill: number | null = null
    private _container: ContainerView | null = null

    private clockBoundValue: string | null = null
    private currentString: string | null = null
    private voltageString: string | null = null

    lineY: number = 0

    constructor(data: PositionDataAdapter, view: SVGElement | null) {
        super(view)
        this.data = data
        this.bind('id', data.id.toString());
        this.update()
    }

    get container(): ContainerView | null {
        return this._container;
    }

    set container(containerView: ContainerView | null) {
        this._container = containerView;
        this.update();
    }

    get enabled(): boolean {
        return this.data.enabled
    }

    get locked(): boolean {
        return this.data.locked;
    }

    set enabled(v: boolean) {
        this.data.enabled = v
    }

    get timeLeft(): number | null {
        if (this.timeTill == null) return null
        return milliToSec(this.timeTill - new Date().getTime())
    }

    set timeLeft(n: number | null) {
        if (n == null) {
            this.timeTill = null
            return
        }
        this.timeTill = new Date(new Date().getTime() + secToMilli(n)).getTime()
    }

    get timeWait(): number | null {
        if (this.container == null) return null

        const containerData = this.container.data;
        if (containerData.state == null) return null;
        switch (containerData.state) {
            case ContainerState.UNLOADING:
            case ContainerState.WAITING:
            case ContainerState.FINISHED:
                if (containerData.programFinishTime == null) return null;
                return Math.round((new Date().getTime() - containerData.programFinishTime) / 1000);
            default:
                return null;
        }
    }

    update() {
        this.view?.classList.toggle('enabled', this.enabled)
        this.view?.classList.toggle('active', this.active)
        this.view?.classList.toggle('locked', this.locked)
        this.view?.classList.toggle('OCCUPIED', this._container != null);
        this.updateText()
    }

    updateText() {
        let clockBoundValue = ''
        if (this.timeLeft != null) {
            const {minutes, seconds, forward} = msToDurationMS(this.timeLeft * 1000);
            clockBoundValue = (forward ? '' : '-') + padLeft(minutes.toString(), 2, '0') + ':' + padLeft(seconds.toString(), 2, '0')
        } else if (this.timeWait != null) {
            let {minutes, seconds, forward} = msToDurationMS(this.timeWait * 1000);
            if (minutes > 999) {
                minutes = 999;
                seconds = 99;
            }
            clockBoundValue = (forward ? '' : '-') + padLeft(minutes.toString(), 2, '0') + ':' + padLeft(seconds.toString(), 2, '0')
        }

        if (clockBoundValue != this.clockBoundValue) {
            this.clockBoundValue = clockBoundValue
            this.bind('clock', clockBoundValue)
        }

        let currentString = addSuffixToText(zeroIfEmpty(this.data.currentString), 'А')
        if (currentString != this.currentString) {
            this.currentString = currentString
            this.bind('current', currentString)
        }

        let voltageString = addSuffixToText(zeroIfEmpty(this.data.voltageString), 'V')
        if (voltageString != this.voltageString) {
            this.voltageString = voltageString
            this.bind('voltage', voltageString)
        }
    }

    tick() {
        if (this.timeTill != null || this.timeWait != null) {
            this.updateText();
        }
    }
}

function zeroIfEmpty(text: string) {
    return text.length == 0 ? '0' : text;
}