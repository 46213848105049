import {escapeHTML} from "../lib/escapeHTML";
import {bootstrapModal} from "../lib/bootstrapModal";
import {Messages} from "../messages/Messages";
import {toggleDisabled, toggleHidden} from "../lib/domFunctions";
import {fetchJsonForApiResponse, isAjaxResponseOk, toastFetchError} from "../lib/fetch";
import {AppConfig} from "../AppConfig";
import {apiPost} from "../lib/SecuredAjax";
import {showToastInfo} from "../lib/boostrapToast";
import {PositionSelectOption} from "../dto/com.rico.sb2.service.positions";
import {springSelect} from "../lib/springForms";
import {ContainerState} from "../dto/com.rico.sb2.entity.detail";
import {SchemaState} from "../SchemaState";
import {ProcessControlService_Mode} from "../dto/com.rico.sb2.service";

const messages = new Messages();

export class SendContainerToPosition {
	private readonly currentPosition: number | null
	private readonly schemaState: SchemaState | null
	private readonly container: number

	constructor(container: number, currentPosition: number | null, schemaState: SchemaState | null) {
		this.currentPosition = currentPosition
		this.container = container
		this.schemaState = schemaState
	}

	execute(position: number, runoffTime: number = 0): Promise<void> {
		return new Promise((resolve, reject) => {
			apiPost(`${AppConfig.CP}/op/moveContainer`, {container: this.container, position, runoffTime})
				.then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res.message))
				.then(success => {
					if (!success) return Promise.reject(500)

					showToastInfo(messages.get('SendContainerToPosition.completeNotice'))
					resolve()
				})
				.catch(error => {
					toastFetchError(error);
					reject();
				})
		})
	}

	showModal(success?: Function) {
		const {modal, content, buttonOk, buttonOkClickStart, buttonOkClickFinish} = bootstrapModal({
			title: messages.get('SendContainerToPosition.title', this.container),
			body: this.renderForm(),
			buttonOk: messages.get('button.send')
		});
		modal.show();

		const ok = buttonOk!!;
		const positionSelect = content.querySelector<HTMLSelectElement>('select[name="position"]')!!;
		const runoffTimeSelect = content.querySelector<HTMLSelectElement>('select[name="runoffTime"]');

		function okToggle() {
			const positionId = parseInt(positionSelect.value);
			const positionSelected = isFinite(positionId);
			toggleDisabled(ok, !positionSelected)
		}

		positionSelect.addEventListener('change', okToggle);
		okToggle()

		ok.addEventListener('click', () => {
			const position = parseInt(positionSelect.value);
			if (!position) return;

			const runoffTime = runoffTimeSelect ? parseInt(runoffTimeSelect.value) : 0;

			buttonOkClickStart();

			this.execute(position, runoffTime)
				.then(() => {
					modal.hide()
					if (success) success()
				})
				.catch(buttonOkClickFinish)
		})

		function loadingStop() {
			content.querySelector('[data-bind="loading"]')?.remove()
			okToggle()
		}

		fetchJsonForApiResponse(`${AppConfig.CP}/op/listMoveContainerTargets?container=${this.container}`)
			.then((positions: PositionSelectOption[]) => {
				positions = positions.filter((p: any) => p.id != this.currentPosition)

				if (positions.length > 0) {
					positionSelect.innerHTML = positions
						.map((p: any) => `<option value="${p.id}">${p.id}: ${escapeHTML(p.label)}</option>`)
						.join("")
					toggleHidden(content.querySelector('[data-bind="select"]')!!, false)
				} else {
					toggleHidden(content.querySelector('[data-bind="emptyMessage"]')!!, false)
				}
			})
			.catch(toastFetchError)
			.then(loadingStop)
	}

	private renderForm(): string {
		const positionForRunOff = this.currentPosition && this.schemaState != null
			&& !this.schemaState.isLoadingPosition(this.currentPosition)
			&& !this.schemaState.isUnloadingPosition(this.currentPosition)

		const runoffTimeOptions = Array.from(new Array(10).keys())
			.map(i => i * AppConfig.suspensionTakeDelayFactor)
			.map(n => `<option value="${n}">${n}</option>`)
			.join('');
		const runoffTimeSelect = springSelect({name: `runoffTime`, labelCode: 'processAction.form.runoffTime', optionContent: runoffTimeOptions});
		const runoffTimeSection = positionForRunOff && this.schemaState?.mode == ProcessControlService_Mode.SEMIAUTOMATIC ? `<div>${runoffTimeSelect}</div>` : '';

		return `
            <div class="text-center" data-bind="loading"><div class="lds-dual-ring"></div></div>        
            <div class="hidden text-center" data-bind="emptyMessage">${escapeHTML(messages.get('SendContainerToPosition.noAvailablePositions'))}</div>
            <div class="hidden" data-bind="select">
            	<div class="mb-3">
						<select name="position" class="form-select" required></select>
				</div>
            	${runoffTimeSection}
            </div>
        `
	}
}