import jQuery from 'jquery';
import moment from "moment";

import 'moment/locale/ru'

moment.locale(document.querySelector('html')?.lang)

declare var global: any;
global.jQuery = jQuery;

export {
    jQuery,
    moment
}