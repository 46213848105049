function makeString(object: any): string {
    if (object == null) return '';
    return '' + object;
}

const escapeChars: { [key: string]: string } = {
    '¢': 'cent',
    '£': 'pound',
    '¥': 'yen',
    '€': 'euro',
    '©': 'copy',
    '®': 'reg',
    '<': 'lt',
    '>': 'gt',
    '"': 'quot',
    '&': 'amp',
    '\'': '#39'
};

let regexString = '[';
for (const key in escapeChars) {
    regexString += key;
}
regexString += ']';

const regex = new RegExp(regexString, 'g');

export function escapeHTML(str: any) {
    return makeString(str).replace(regex, function (m) {
        return '&' + escapeChars[m] + ';';
    });
}