import {apiPostForAjaxResponse} from "../lib/SecuredAjax";
import {AppConfig} from "../AppConfig";
import {toastFetchError} from "../lib/fetch";
import {bootstrapModal} from "../lib/bootstrapModal";
import {showToastError, showToastSuccess} from "../lib/boostrapToast";
import {Messages} from "../messages/Messages";

const messages = new Messages();

export class CloneContainer {
	private readonly containerId: number

	constructor(containerId: number) {
		this.containerId = containerId
	}

	showModal() {
		const {modal} = bootstrapModal({
			title: messages.get('CloneContainer.title', this.containerId),
			body: messages.get('CloneContainer.body'),
			closeable: false,
			buttonOk: null, buttonCancel: null
		});

		modal.show();
		const progressModalHide = modal.hide.bind(modal)

		apiPostForAjaxResponse(`${AppConfig.CP}/op/cloneContainer`, {container: this.containerId})
			.then(id => {
				if (!(id > 0)) {
					showToastError('Клонирование контейнера не удалось');
				} else {
					showToastSuccess('Создана копия контейнера. Вы можете найти её в списке подвесок "В ожидании".');
				}
			})
			.catch(toastFetchError)
			.then(progressModalHide);
	}
}